import Lottie from 'lottie-react';
import { Box, Center, Text } from 'native-base';
import React from 'react';

import chefError from '../assets/lottie/20644-connection-lost-with-restaurantcheck-your-internet.json';

const WaffleErrorComponent = () => {
  return (
    <Center height={'100%'} width={'100%'} padding={4}>
      <Box maxWidth={600}>
        <Lottie animationData={chefError} loop={true} autoplay={true} />
        <Text variant={'subHeader'} textAlign={'center'}>
          Something went wrong!
        </Text>
        <Text textAlign={'center'}>
          Something broke on our end and we're super sorry about that.
        </Text>
        <Text textAlign={'center'}>
          In the mean time, please alert a staff member to continue ordering.
        </Text>
      </Box>
    </Center>
  );
};
export default WaffleErrorComponent;
