import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as Listings from '../listings';
import * as Sellers from '../sellers';

export type OnlineStore = z.infer<typeof OnlineStore.schema> & {
  // Expansions
  location?: Sellers.Location;
};
export namespace OnlineStore {
  export const _type = 'online_ordering.online_store' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    locationId: z.string().uuid(),
    isEnabled: z.boolean(),
    // Note: This can contain listings that have potentially been deleted / removed from this specific location
    listings: z.object({
      isAllCategoriesIncluded: z.boolean(),
      categories: z.array(
        z.object({
          categoryId: z.string().uuid(),
          isAllItemsIncluded: z.boolean(),
          items: z.array(
            z.object({
              itemId: z.string().uuid(),
              isAllItemVariationsIncluded: z.boolean(),
              itemVariations: z.array(
                z.object({
                  itemVariationId: z.string().uuid(),
                }),
              ),
            }),
          ),
        }),
      ),
      isAllAddOnSetsIncluded: z.boolean(),
      addOnSets: z.array(
        z.object({
          addOnSetId: z.string().uuid(),
          isAllAddOnsIncluded: z.boolean(),
          addOns: z.array(
            z.object({
              addOnId: z.string().uuid(),
            }),
          ),
        }),
      ),
    }),
  });

  export const create = (args: Partial<OnlineStore>): OnlineStore => {
    return schema.parse({
      ...args,
      type: _type,
    });
  };
}

export type OnlineStoreCreateParams = z.infer<
  typeof OnlineStoreCreateParams.schema
>;
export namespace OnlineStoreCreateParams {
  export const schema = z.object({
    locationId: z.string().uuid(),
    isEnabled: z.boolean().default(true),
    // Note: This can contain listings that have potentially been deleted / removed from this specific location
    listings: z
      .object({
        isAllCategoriesIncluded: z.boolean(),
        categories: z.array(
          z.object({
            categoryId: z.string().uuid(),
            isAllItemsIncluded: z.boolean(),
            items: z.array(
              z.object({
                itemId: z.string().uuid(),
                isAllItemVariationsIncluded: z.boolean(),
                itemVariations: z.array(
                  z.object({
                    itemVariationId: z.string().uuid(),
                  }),
                ),
              }),
            ),
          }),
        ),
        isAllAddOnSetsIncluded: z.boolean(),
        addOnSets: z.array(
          z.object({
            addOnSetId: z.string().uuid(),
            isAllAddOnsIncluded: z.boolean(),
            addOns: z.array(
              z.object({
                addOnId: z.string().uuid(),
              }),
            ),
          }),
        ),
      })
      .default({
        isAllCategoriesIncluded: false,
        categories: [],
        isAllAddOnSetsIncluded: false,
        addOnSets: [],
      }),
  });
}

export type OnlineStoreUpdateParams = z.infer<
  typeof OnlineStoreUpdateParams.schema
>;
export namespace OnlineStoreUpdateParams {
  export const schema = z.object({
    isEnabled: z.boolean().nullish(),
    // Note: This can contain listings that have potentially been deleted / removed from this specific location
    listings: z
      .object({
        isAllCategoriesIncluded: z.boolean(),
        categories: z.array(
          z.object({
            categoryId: z.string().uuid(),
            isAllItemsIncluded: z.boolean(),
            items: z.array(
              z.object({
                itemId: z.string().uuid(),
                isAllItemVariationsIncluded: z.boolean(),
                itemVariations: z.array(
                  z.object({
                    itemVariationId: z.string().uuid(),
                  }),
                ),
              }),
            ),
          }),
        ),
        isAllAddOnSetsIncluded: z.boolean(),
        addOnSets: z.array(
          z.object({
            addOnSetId: z.string().uuid(),
            isAllAddOnsIncluded: z.boolean(),
            addOns: z.array(
              z.object({
                addOnId: z.string().uuid(),
              }),
            ),
          }),
        ),
      })
      .nullish(),
  });
}

export type SelfOrderingLink = z.infer<typeof SelfOrderingLink.schema> & {
  // Expansions
  onlineStore?: OnlineStore;
  seller?: Sellers.Seller;
  location?: Sellers.Location;
  orderType?: Listings.OrderType;
};
export namespace SelfOrderingLink {
  export const _type = 'online_ordering.self_ordering_link' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    locationId: z.string().uuid(), // TODO: Deprecate
    onlineStoreId: z.string().uuid(),
    orderTypeId: z.string().uuid().nullable().default(null),
    ticketValue: z.string().nullable().default(null),

    // Readonly
    url: z.string(),
  });

  export const create = (
    linkArgs: Partial<SelfOrderingLink> &
      Pick<SelfOrderingLink, 'sellerId' | 'locationId'>,
    scheme: 'https' | 'http',
    sellerSlug: string,
    baseUrl: string,
  ): SelfOrderingLink => {
    const id = linkArgs.id ?? uuidv4();
    return schema.parse({
      ...linkArgs,
      _type: _type,
      id: id,
      url: `${scheme}://${sellerSlug}.${baseUrl}/?l=${id}`,
    });
  };
}

export type SelfOrderingLinkCreateParams = z.infer<
  typeof SelfOrderingLinkCreateParams.schema
>;
export namespace SelfOrderingLinkCreateParams {
  export const schema = z.object({
    locationId: z.string().uuid(), // TODO: Deprecate
    onlineStoreId: z.string().uuid(),
    orderTypeId: z.string().uuid().nullable().default(null),
    ticketValue: z.string().nullable().default(null),
  });
}

export type SelfOrderingLinkUpdateParams = z.infer<
  typeof SelfOrderingLinkUpdateParams.schema
>;
export namespace SelfOrderingLinkUpdateParams {
  export const schema = z.object({
    orderTypeId: z.string().uuid().nullish(),
    ticketValue: z.string().nullish(),
  });
}
