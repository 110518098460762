import { z } from 'zod';

import * as Common from '../common';
import * as Sellers from '../sellers';

export enum RoleType {
  TEMPLATE = 'TEMPLATE',
  CUSTOM = 'CUSTOM',
}

export enum TemplateRoleType {
  SUPER_USER = 'SUPER_USER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  EMPLOYEE = 'EMPLOYEE',
  NONE = 'NONE',
}

export type TemplateRole = z.infer<typeof TemplateRole.schema>;

export namespace TemplateRole {
  export const _type = 'teams.role' as const;

  export const schema =
    // z.discriminatedUnion('id', [SellerSuperUserRole.schema, SellerAdminRole.schema, SellerManagerRole.schema, SellerEmployeeRole.schema])
    z.object({
      id: z.string(),
      name: z.string(),
      type: z.literal(RoleType.TEMPLATE),
      historicalDataLimitDays: z.number().nullable(),
      isDisabled: z.boolean().default(false),
      description: z.string(),
      permissions: z.array(z.nativeEnum(Common.Permission)).default([]),
    });

  export const create = (args: Partial<TemplateRole>): TemplateRole => {
    switch (args.id) {
      case TemplateRoleType.SUPER_USER: {
        return schema.parse(
          TemplateRoleArray.find(
            (templateRole) => templateRole.id === TemplateRoleType.SUPER_USER,
          ),
        );
      }
      case TemplateRoleType.ADMIN: {
        return schema.parse(
          TemplateRoleArray.find(
            (templateRole) => templateRole.id === TemplateRoleType.ADMIN,
          ),
        );
      }
      case TemplateRoleType.MANAGER: {
        return schema.parse(
          TemplateRoleArray.find(
            (templateRole) => templateRole.id === TemplateRoleType.MANAGER,
          ),
        );
      }
      case TemplateRoleType.EMPLOYEE: {
        return schema.parse(
          TemplateRoleArray.find(
            (templateRole) => templateRole.id === TemplateRoleType.EMPLOYEE,
          ),
        );
      }
      case TemplateRoleType.NONE: {
        return schema.parse(
          TemplateRoleArray.find(
            (templateRole) => templateRole.id === TemplateRoleType.NONE,
          ),
        );
      }
      default: {
        throw new Error('No `type` field supplied to Teams.Role.create()');
      }
    }
  };
}

export type CustomRoleCreateParams = z.infer<
  typeof CustomRoleCreateParams.schema
>;

export namespace CustomRoleCreateParams {
  export const schema = z.object({
    name: z.string(),
    type: z.literal(RoleType.CUSTOM).default(RoleType.CUSTOM),
    description: z.string(),
    historicalDataLimitDays: z.number().nullable(),
    permissions: z.array(z.nativeEnum(Common.Permission)).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
  });

  export const create = (
    args: Partial<CustomRoleCreateParams>,
  ): CustomRoleCreateParams => {
    return schema.parse({
      ...args,
      type: RoleType.CUSTOM,
    });
  };
}

export type CustomRoleUpdateParams = z.infer<
  typeof CustomRoleUpdateParams.schema
>;

export namespace CustomRoleUpdateParams {
  export const schema = z.object({
    name: z.string(),
    description: z.string(),
    historicalDataLimitDays: z.number().nullable(),
    permissions: z.array(z.nativeEnum(Common.Permission)).default([]),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
  });

  export const create = (
    args: Partial<CustomRoleUpdateParams>,
  ): CustomRoleUpdateParams => {
    return schema.parse({
      ...args,
    });
  };
}

export type CustomRole = z.infer<typeof CustomRole.schema>;

export namespace CustomRole {
  export const _type = 'teams.role' as const;

  export const schema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal(RoleType.CUSTOM),
    description: z.string(),
    permissions: z.array(z.nativeEnum(Common.Permission)).default([]),
    historicalDataLimitDays: z.number().nullable(),
    isDisabled: z.boolean().default(false),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
  });

  export const create = (args: Partial<CustomRole>): CustomRole => {
    return schema.parse({
      ...args,
      type: RoleType.CUSTOM,
      permissions: args.isDisabled ? [] : args.permissions,
    });
  };
}

export type Role = z.infer<typeof Role.schema>;
export namespace Role {
  export const _type = 'teams.role' as const;

  export const schema = z.discriminatedUnion('type', [
    TemplateRole.schema,
    CustomRole.schema,
  ]);

  export const create = (args: Partial<Role> & Pick<Role, 'type'>): Role => {
    switch (args.type) {
      case RoleType.TEMPLATE: {
        return TemplateRole.create({
          ...args,
          isDisabled: false,
        });
      }
      case RoleType.CUSTOM: {
        return CustomRole.create(args);
      }
      default: {
        throw new Error('No `type` field supplied to Teams.Role.create()');
      }
    }
  };
}

export type TeamMemberCreateParams = z.infer<
  typeof TeamMemberCreateParams.schema
>;

export namespace TeamMemberCreateParams {
  export const schema = z.object({
    alias: z.string(),
    email: z.string().email(),
    pin: z.string().min(4).max(4).nullable().default(null),
    roleId: z.union([z.nativeEnum(TemplateRoleType), z.string().uuid()]),
    isAuthorisedForAllLocations: z.boolean().default(true),
    authorisedForLocationIds: z.array(z.string().uuid()).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });
}

export type TeamMemberUpdateParams = z.infer<
  typeof TeamMemberUpdateParams.schema
>;

export namespace TeamMemberUpdateParams {
  export const schema = z.object({
    alias: z.string().nullish(),
    roleId: z
      .union([z.nativeEnum(TemplateRoleType), z.string().uuid()])
      .nullish(),
    pin: z.string().min(4).max(4).nullable().default(null).nullish(),
    isAuthorisedForAllLocations: z.boolean().nullish(),
    authorisedForLocationIds: z.array(z.string().uuid()).nullish(),
  });
}

export type TeamMember = z.infer<typeof TeamMember.schema> & {
  seller?: Sellers.Seller;
  authorisedForLocations?: Sellers.Location[];
};

export namespace TeamMember {
  export const _type = 'teams.team_member' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    sellerUserId: z.string().uuid(),
    alias: z.string().nullable().default(null),
    name: z.string(), // Readonly, copied from seller_user as COALASCE(team_member.alias, seller_user.name)
    email: z.string().email().nullable(), // Readonly, copied from seller_user
    pin: z.string().min(4).max(4).nullable().default(null),
    roleId: z.union([z.nativeEnum(TemplateRoleType), z.string().uuid()]),
    role: Role.schema,
    isAuthorisedForAllLocations: z.boolean(),
    authorisedForLocationIds: z.array(z.string().uuid()),
    hasAcceptedInvitation: z.boolean(),
    acceptedInvitationAt: z.string().datetime({ offset: true }).nullable(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (
    args: (Partial<TeamMember> & Pick<TeamMember, 'role'>) | any,
  ): TeamMember => {
    // If roleId one of the TemplateRole enums, create a dummy role for parsing
    if (Object.values(TemplateRoleType).includes(args.roleId)) {
      return schema.parse({
        ...args,
        role: Role.create({
          id: args.roleId,
          type: RoleType.TEMPLATE,
        }),
      });
    } else {
      return schema.parse({
        ...args,
        role: Role.create({
          ...args.role,
          type: RoleType.CUSTOM,
        }),
      });
    }
  };
}

export const TemplateRoleArray: TemplateRole[] = [
  {
    id: TemplateRoleType.SUPER_USER,
    isDisabled: false,
    name: 'Super User',
    type: RoleType.TEMPLATE,
    historicalDataLimitDays: null,
    description: 'Full access to the POS and Dashboard. Used by Team owners.',
    permissions: [
      // AUTH
      Common.Permission['AUTH.SELLER_DASHBOARD.LOGIN'],
      Common.Permission['AUTH.POS.LOGIN'],

      // ORDERS
      Common.Permission['ORDERS.ORDER.APPLY_DISCOUNT'],
      Common.Permission['ORDERS.ORDER.REFUND'],
      Common.Permission['ORDERS.ORDER.VOID'],
      Common.Permission['ORDERS.ORDER.ADD_CUSTOMER_TO_COMPLETED_ORDER'],

      // POS
      Common.Permission['POS.REGISTER_SHIFT_REPORT.READ_EXPECTED_AMOUNTS'],
      Common.Permission['POS.CONFIGURATION.READ'],
      Common.Permission['POS.CONFIGURATION.WRITE'],

      // LISTINGS
      Common.Permission['LISTINGS.WRITE'],
      Common.Permission['LISTINGS.READ'],

      // REPORTS
      Common.Permission['REPORTS.PAYMENT_METHODS_SUMMARY.READ'],
      Common.Permission['REPORTS.SALES_SUMMARY.READ'],
      Common.Permission['REPORTS.ITEMS_SUMMARY.READ'],
      Common.Permission['REPORTS.CATEGORIES_SUMMARY.READ'],
      Common.Permission['REPORTS.ADD_ONS_SUMMARY.READ'],
      Common.Permission['REPORTS.DISCOUNTS_SUMMARY.READ'],
      Common.Permission['REPORTS.VOUCHERS_SUMMARY.READ'],
      Common.Permission['REPORTS.VOUCHERS_HISTORY.READ'],
      Common.Permission['REPORTS.REGISTER_SHIFTS_HISTORY.READ'],
      Common.Permission['REPORTS.CUSTOM_REPORT.READ'],
      Common.Permission['REPORTS.CUSTOM_REPORT.WRITE'],

      // EXPORTS
      Common.Permission['SALES.SALES_HISTORY.EXPORT'],
      Common.Permission['REPORTS.SALES_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.PAYMENT_METHODS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.ITEMS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.CATEGORIES_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.ADD_ONS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.DISCOUNTS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.VOUCHERS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.VOUCHERS_HISTORY.EXPORT'],
      Common.Permission['REPORTS.REGISTER_SHIFTS_HISTORY.EXPORT'],

      // HISTORY
      Common.Permission['SALES.SALES_HISTORY.READ'],
      Common.Permission['INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.READ'],
      Common.Permission['INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.EXPORT'],

      // ONLINE ORDERING
      Common.Permission['ONLINE_ORDERING.ONLINE_STORE.READ'],
      Common.Permission['ONLINE_ORDERING.ONLINE_STORE.WRITE'],

      // REWARDS
      Common.Permission['REWARDS.REWARDS_PROGRAMME.READ'],
      Common.Permission['REWARDS.REWARDS_PROGRAMME.WRITE'],
      Common.Permission['REWARDS.CAMPAIGN.WRITE'],
      Common.Permission['REWARDS.CAMPAIGN.READ'],
      Common.Permission['REWARDS.REWARDS_LISTING.READ'],
      Common.Permission['REWARDS.REWARDS_LISTING.WRITE'],
      Common.Permission['REWARDS.REWARDS_MEMBER.ADD_POINTS'],
      Common.Permission['REWARDS.REWARDS_MEMBER.REMOVE_POINTS'],

      // CUSTOMERS
      Common.Permission['CUSTOMERS.CUSTOMER.READ'],
      Common.Permission['CUSTOMERS.CUSTOMER_SET.READ'],
      Common.Permission['CUSTOMERS.CUSTOMER_SET.WRITE'],

      // BUSINESS SETTINGS
      Common.Permission['BUSINESS.SETTINGS.READ'],
      Common.Permission['BUSINESS.SETTINGS.WRITE'],

      // TEAMS
      Common.Permission['TEAMS.ROLE.READ'],
      Common.Permission['TEAMS.ROLE.WRITE'],
      Common.Permission['TEAMS.TEAM_MEMBER.READ'],
      Common.Permission['TEAMS.TEAM_MEMBER.WRITE'],
    ],
  },
  {
    id: TemplateRoleType.ADMIN,
    isDisabled: false,
    name: 'Admin',
    type: RoleType.TEMPLATE,
    historicalDataLimitDays: null,
    description:
      'Full access to the POS and Dashboard. Privileges can be revoked by Super Users.',
    permissions: [
      // AUTH
      Common.Permission['AUTH.SELLER_DASHBOARD.LOGIN'],
      Common.Permission['AUTH.POS.LOGIN'],

      // ORDERS
      Common.Permission['ORDERS.ORDER.APPLY_DISCOUNT'],
      Common.Permission['ORDERS.ORDER.REFUND'],
      Common.Permission['ORDERS.ORDER.VOID'],
      Common.Permission['ORDERS.ORDER.ADD_CUSTOMER_TO_COMPLETED_ORDER'],

      // POS
      Common.Permission['POS.REGISTER_SHIFT_REPORT.READ_EXPECTED_AMOUNTS'],
      Common.Permission['POS.CONFIGURATION.READ'],
      Common.Permission['POS.CONFIGURATION.WRITE'],

      // LISTINGS
      Common.Permission['LISTINGS.WRITE'],
      Common.Permission['LISTINGS.READ'],

      // REPORTS
      Common.Permission['REPORTS.PAYMENT_METHODS_SUMMARY.READ'],
      Common.Permission['REPORTS.SALES_SUMMARY.READ'],
      Common.Permission['REPORTS.ITEMS_SUMMARY.READ'],
      Common.Permission['REPORTS.CATEGORIES_SUMMARY.READ'],
      Common.Permission['REPORTS.ADD_ONS_SUMMARY.READ'],
      Common.Permission['REPORTS.DISCOUNTS_SUMMARY.READ'],
      Common.Permission['REPORTS.VOUCHERS_SUMMARY.READ'],
      Common.Permission['REPORTS.VOUCHERS_HISTORY.READ'],
      Common.Permission['REPORTS.REGISTER_SHIFTS_HISTORY.READ'],
      Common.Permission['REPORTS.CUSTOM_REPORT.READ'],
      Common.Permission['REPORTS.CUSTOM_REPORT.WRITE'],
      Common.Permission['REPORTS.REGISTER_SHIFTS_HISTORY.EXPORT'],

      // EXPORTS
      Common.Permission['SALES.SALES_HISTORY.EXPORT'],
      Common.Permission['REPORTS.SALES_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.PAYMENT_METHODS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.ITEMS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.CATEGORIES_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.ADD_ONS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.DISCOUNTS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.VOUCHERS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.VOUCHERS_HISTORY.EXPORT'],

      // HISTORY
      Common.Permission['SALES.SALES_HISTORY.READ'],
      Common.Permission['INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.READ'],
      Common.Permission['INTEGRATED_PAYMENTS.PAYMENTS_HISTORY.EXPORT'],

      // ONLINE ORDERING
      Common.Permission['ONLINE_ORDERING.ONLINE_STORE.READ'],
      Common.Permission['ONLINE_ORDERING.ONLINE_STORE.WRITE'],

      // REWARDS
      Common.Permission['REWARDS.REWARDS_PROGRAMME.READ'],
      Common.Permission['REWARDS.REWARDS_PROGRAMME.WRITE'],
      Common.Permission['REWARDS.CAMPAIGN.WRITE'],
      Common.Permission['REWARDS.CAMPAIGN.READ'],
      Common.Permission['REWARDS.REWARDS_LISTING.READ'],
      Common.Permission['REWARDS.REWARDS_LISTING.WRITE'],
      Common.Permission['REWARDS.REWARDS_MEMBER.ADD_POINTS'],
      Common.Permission['REWARDS.REWARDS_MEMBER.REMOVE_POINTS'],

      // CUSTOMERS
      Common.Permission['CUSTOMERS.CUSTOMER.READ'],
      Common.Permission['CUSTOMERS.CUSTOMER_SET.READ'],
      Common.Permission['CUSTOMERS.CUSTOMER_SET.WRITE'],

      // BUSINESS SETTINGS
      Common.Permission['BUSINESS.SETTINGS.READ'],
      Common.Permission['BUSINESS.SETTINGS.WRITE'],

      // TEAMS
      Common.Permission['TEAMS.ROLE.READ'],
      Common.Permission['TEAMS.ROLE.WRITE'],
      Common.Permission['TEAMS.TEAM_MEMBER.READ'],
      Common.Permission['TEAMS.TEAM_MEMBER.WRITE'],
    ],
  },
  {
    id: TemplateRoleType.MANAGER,
    isDisabled: false,
    name: 'Manager',
    type: RoleType.TEMPLATE,
    historicalDataLimitDays: null,
    description:
      'Full access to the POS with limited access to the dashboard. Used by Operations Managers.',
    permissions: [
      // AUTH
      Common.Permission['AUTH.SELLER_DASHBOARD.LOGIN'],
      Common.Permission['AUTH.POS.LOGIN'],

      // ORDERS
      Common.Permission['ORDERS.ORDER.APPLY_DISCOUNT'],
      Common.Permission['ORDERS.ORDER.REFUND'],
      Common.Permission['ORDERS.ORDER.VOID'],

      // POS
      Common.Permission['POS.REGISTER_SHIFT_REPORT.READ_EXPECTED_AMOUNTS'],
      Common.Permission['POS.CONFIGURATION.READ'],
      Common.Permission['POS.CONFIGURATION.WRITE'],

      // LISTINGS
      Common.Permission['LISTINGS.WRITE'],
      Common.Permission['LISTINGS.READ'],

      // REPORTS
      Common.Permission['REPORTS.PAYMENT_METHODS_SUMMARY.READ'],
      Common.Permission['REPORTS.SALES_SUMMARY.READ'],
      Common.Permission['REPORTS.ITEMS_SUMMARY.READ'],
      Common.Permission['REPORTS.CATEGORIES_SUMMARY.READ'],
      Common.Permission['REPORTS.ADD_ONS_SUMMARY.READ'],
      Common.Permission['REPORTS.DISCOUNTS_SUMMARY.READ'],
      Common.Permission['REPORTS.VOUCHERS_SUMMARY.READ'],
      Common.Permission['REPORTS.VOUCHERS_HISTORY.READ'],
      Common.Permission['REPORTS.REGISTER_SHIFTS_HISTORY.READ'],
      Common.Permission['REPORTS.CUSTOM_REPORT.READ'],
      Common.Permission['REPORTS.CUSTOM_REPORT.WRITE'],

      // EXPORTS
      Common.Permission['SALES.SALES_HISTORY.EXPORT'],
      Common.Permission['REPORTS.SALES_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.PAYMENT_METHODS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.ITEMS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.CATEGORIES_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.ADD_ONS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.DISCOUNTS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.VOUCHERS_SUMMARY.EXPORT'],
      Common.Permission['REPORTS.VOUCHERS_HISTORY.EXPORT'],
      Common.Permission['REPORTS.REGISTER_SHIFTS_HISTORY.EXPORT'],

      // HISTORY
      Common.Permission['SALES.SALES_HISTORY.READ'],

      // ONLINE ORDERING
      Common.Permission['ONLINE_ORDERING.ONLINE_STORE.READ'],
      Common.Permission['ONLINE_ORDERING.ONLINE_STORE.WRITE'],

      // REWARDS
      Common.Permission['REWARDS.CAMPAIGN.READ'],
      Common.Permission['REWARDS.REWARDS_LISTING.READ'],

      // CUSTOMERS
      Common.Permission['CUSTOMERS.CUSTOMER.READ'],
      Common.Permission['CUSTOMERS.CUSTOMER_SET.READ'],
    ],
  },
  {
    id: TemplateRoleType.EMPLOYEE,
    isDisabled: false,
    name: 'Employee',
    type: RoleType.TEMPLATE,
    historicalDataLimitDays: null,
    description: 'Limited access on the POS. Used by Cashiers',
    permissions: [
      // AUTH
      Common.Permission['AUTH.POS.LOGIN'],

      // ORDERS
      Common.Permission['ORDERS.ORDER.APPLY_DISCOUNT'],
      Common.Permission['ORDERS.ORDER.VOID'],

      // POS
      Common.Permission['POS.REGISTER_SHIFT_REPORT.READ_EXPECTED_AMOUNTS'],

      // LISTINGS
      Common.Permission['LISTINGS.READ'],
    ],
  },
  {
    id: TemplateRoleType.NONE,
    isDisabled: true,
    name: 'None',
    type: RoleType.TEMPLATE,
    historicalDataLimitDays: null,
    description: 'For deactivated team members',
    permissions: [],
  },
] as const;

export type TeamInvitation = z.infer<typeof TeamInvitation.schema>;

export namespace TeamInvitation {
  export const _type = 'Teams.TeamInvitation';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    link: z.string().url(),
    teamMemberId: z.string(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()), //For backwards compatibility
  });

  export const createInvitationLink = (
    id: string,
    dashboardUrl: string,
  ): string => {
    // /public/team-invitations/:teamInvitationId/accept
    return `${dashboardUrl}/public/team-invitations/${id}/accept`;
  };

  export const create = (
    args: Partial<TeamInvitation>,
    dashboardUrl: string,
  ): TeamInvitation => {
    if (!args.id) {
      throw new Error(
        `[Teams.TeamInvitation.create] Missing id for invitation`,
      );
    }

    return schema.parse({
      ...args,
      _type: _type,
      link: createInvitationLink(args.id, dashboardUrl),
    });
  };
}
