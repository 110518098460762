import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

export enum CommunicationsChannel {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type BuyerUser = z.infer<typeof BuyerUser.schema>;
export namespace BuyerUser {
  export const _type = 'buyers.buyer_user';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    givenName: z.string().nullable().default(null),
    familyName: z.string().nullable().default(null),
    emailAddress: z.string().nullable().default(null),
    mobileNumber: z.string().nullable().default(null),
    dateOfBirth: z.string().nullable().default(null), // TODO: Rename to birthDate, add z.datetime()
    // If this is set to CommunicationMethod.EMAIL, emailAddress has to be set
    // Else if this is set to CommunicationMethod.SMS, mobileNumber has to be set
    preferredCommunicationsChannel: z
      .nativeEnum(CommunicationsChannel)
      .nullable(),
    acceptedWafflePrivacyPolicyAt: z
      .string()
      .datetime({ offset: true })
      .nullable(),
    isMobileNumberVerified: z.boolean().default(false),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  // For retrieving from DB
  export const create = (args: Partial<BuyerUser> | any): BuyerUser => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.buyerUserId,
    });
  };

  export const sanitize = (args: BuyerUser): BuyerUser => {
    return {
      ...args,
      givenName: null,
      familyName: null,
      emailAddress: null,
      mobileNumber: null,
      dateOfBirth: null,
    };
  };
}

export type BuyerUserUpdateParams = z.infer<
  typeof BuyerUserUpdateParams.schema
>;
export namespace BuyerUserUpdateParams {
  export const schema = z
    .object({
      givenName: z.string().nullish(),
      familyName: z.string().nullish(),
      emailAddress: z.string().nullish(),
      mobileNumber: z.string().nullish(),
      dateOfBirth: z.string().nullish(), // TODO: Rename to birthDate, add z.datetime()
      // If this is set to CommunicationMethod.EMAIL, emailAddress has to be set
      // Else if this is set to CommunicationMethod.SMS, mobileNumber has to be set
      preferredCommunicationsChannel: z
        .nativeEnum(CommunicationsChannel)
        .nullish(),
      acceptedWafflePrivacyPolicyAt: z
        .string()
        .datetime({ offset: true })
        .nullable(),
      isMobileNumberVerified: z.boolean().default(false),
    })
    .refine((val) => {
      if (
        !!val &&
        val.preferredCommunicationsChannel === CommunicationsChannel.EMAIL &&
        !val.emailAddress
      ) {
        return {
          message:
            'emailAddress has to be set if preferredCommunicationsChannel is EMAIL',
        };
      }

      if (
        !!val &&
        val.preferredCommunicationsChannel === CommunicationsChannel.SMS &&
        !val.mobileNumber
      ) {
        return {
          message:
            'mobileNumber has to be set if preferredCommunicationsChannel is SMS',
        };
      }
    });
}

// BUYER AUTH
export interface UserAuthOTP {
  id: string;
  mobileNumber: string;
  otpHash: string;
  createdAt: string;
  expiresAt: string;
  verificationAttemptCount: number;
}

export enum OTPAuthStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  ATTEMPT_EXCEED = 'ATTEMPT_EXCEED',
  EXPIRED = 'EXPIRED',
  NOT_FOUND = 'NOT_FOUND',
}
export type OTPAuthSuccesStatus = OTPAuthStatus.SUCCESS;
export type OTPAuthErrorStatus = Exclude<OTPAuthStatus, OTPAuthStatus.SUCCESS>;

export type BuyerUserAuthLoginResponse = z.infer<
  typeof BuyerUserAuthLoginResponse.schema
>;
export namespace BuyerUserAuthLoginResponse {
  export const schema = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
    expiresIn: z.number(),
    tokenType: z.string(),
  });
}
export interface BuyerUserAuthOTPLoginSuccessResponse {
  status: OTPAuthSuccesStatus;
  response: BuyerUserAuthLoginResponse;
}

export interface BuyerUserAuthOTPLoginErrorResponse {
  status: OTPAuthErrorStatus;
}

export type BuyerUserAuthOTPLoginResponse =
  | BuyerUserAuthOTPLoginSuccessResponse
  | BuyerUserAuthOTPLoginErrorResponse;

// export type BuyerUserAuthSession = z.infer<typeof BuyerUserAuthSession.schema>;
// export namespace BuyerUserAuthSession {
//   export const schema = z.object({
//     refreshToken: z.string(),
//     refreshTokenFamily: z.string(),
//     buyerUserId: z.string().uuid(),
//     isRevoked: z.boolean().default(false),
//     createdAt: z
//       .string()
//       .datetime({ offset: true })
//       .default(() => new Date().toISOString()),
//   });
// }

export type BuyerUserBirthdaySetEvent = z.infer<
  typeof BuyerUserBirthdaySetEvent.schema
>;
export namespace BuyerUserBirthdaySetEvent {
  export const _type = 'buyers.user.birthday_set';
  export const CURRENT_VERSION = '2023-08-21';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    payload: z.object({
      buyerUserId: z.string().uuid(),
    }),
  });

  export const create = (args: {
    buyerUserId: string;
  }): BuyerUserBirthdaySetEvent => {
    return schema.parse({
      payload: { ...args },
      type: _type,
    });
  };
}

export type BuyerUserUpdatedEvent = z.infer<
  typeof BuyerUserUpdatedEvent.schema
>;

export namespace BuyerUserUpdatedEvent {
  export const _type = 'buyers.user.buyer_user_updated';
  export const CURRENT_VERSION = '2023-08-21';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(_type).default(_type),
    version: z.string().default(CURRENT_VERSION),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    payload: z.object({
      buyerUser: BuyerUser.schema,
    }),
  });

  export const create = (args: {
    buyerUser: BuyerUser;
  }): BuyerUserUpdatedEvent => {
    return schema.parse({
      payload: { ...args },
      type: _type,
    });
  };
}
