import _ from 'lodash';
import {
  ChevronDownIcon,
  IPressableProps,
  Input,
  Modal,
  Pressable,
  Text,
} from 'native-base';
import React, { useState } from 'react';

import { CountryUtil } from '@waffle/common/src/util/country/CountryUtil';
import { PhoneUtil } from '@waffle/common/src/util/phone/PhoneUtil';

const WaffleCountryCallingCodePicker = ({
  countryCode,
  onCountryCodeChange,
  ...restProps
}: {
  countryCode: CountryUtil.CountryCode;
  onCountryCodeChange: (countryCode: CountryUtil.CountryCode) => void;
} & IPressableProps) => {
  const [isCountryCodeSelectOpen, setIsCountryCodeSelectOpen] = useState(false);

  const countryFlagIcon = CountryUtil.getCountryConfig(countryCode).flagIcon;
  const callingCode: PhoneUtil.CallingCode =
    PhoneUtil.getCallingCode(countryCode);

  const [searchInput, setSearchInput] = useState('');

  const filteredCountryMap = CountryUtil.getCountryConfigMap().filter(
    (countryConfig) =>
      countryConfig.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      countryConfig.callingCode.includes(searchInput),
  );

  return (
    <>
      <Pressable
        flexDirection="row"
        alignItems="center"
        onPress={() => setIsCountryCodeSelectOpen(true)}
        {...restProps}>
        <Text>{countryFlagIcon}</Text>
        <Text marginLeft={2}>{callingCode}</Text>
        <ChevronDownIcon size={6} color={'text.400'} marginLeft={1} />
      </Pressable>

      <Modal
        isOpen={isCountryCodeSelectOpen}
        onClose={() => setIsCountryCodeSelectOpen(false)}>
        <Modal.Content>
          <Modal.Header>
            <Input
              flex={1}
              value={searchInput}
              onChangeText={setSearchInput}
              placeholder={'Search country or calling code'}
              autoFocus={true}
            />
          </Modal.Header>
          <Modal.Body maxHeight={'300px'}>
            {filteredCountryMap.map((countryConfig) => (
              <Pressable
                key={countryConfig.code}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                onPress={() => {
                  onCountryCodeChange(countryConfig.code);
                  setIsCountryCodeSelectOpen(false);
                }}
                _hover={{ backgroundColor: 'background.100' }}
                _pressed={{ backgroundColor: 'background.200' }}
                borderRadius={'md'}
                padding={2}>
                <Text>
                  {countryConfig.flagIcon} {countryConfig.name}
                </Text>
                <Text marginLeft={2} variant="subText">
                  {countryConfig.callingCode}
                </Text>
              </Pressable>
            ))}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};
export default WaffleCountryCallingCodePicker;
