import Ably from 'ably';
import { useEffect } from 'react';

import EnvConfig from '../EnvConfig';

const ablyClient = new Ably.Realtime({
  key: EnvConfig.ABLY_API_KEY,
});

export const useAblySubscribe = (channelConfig: {
  channelName: string;
  eventName: string;
  onEvent: (data) => void;
}) => {
  useEffect(() => {
    const channel = ablyClient.channels.get(channelConfig.channelName);
    channel.subscribe(async (message: Ably.Types.Message): Promise<void> => {
      channelConfig.onEvent(message.data);
    });
  }, []);
};
