import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import EnvConfig from './EnvConfig';

export default class ApiService {
  private static readonly API_VERSION = '2022-10-17';
  private static readonly BASE_URL = EnvConfig.SERVER_BASE_URL;

  public static async request(requestConfig: AxiosRequestConfig): Promise<any> {
    try {
      const config: AxiosRequestConfig = {
        baseURL: `${ApiService.BASE_URL}/storefront/${ApiService.API_VERSION}`,
        headers: {
          ...requestConfig.headers,
          'Content-Type': 'application/json',
        },
        ...requestConfig,
      };

      const response: AxiosResponse = await axios(config);
      return response.data;
    } catch (error) {
      // If error is due to some other reason, might be due to malformed request or server down
      throw error;
    }
  }
}
