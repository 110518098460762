import { useAtom } from 'jotai';
import _ from 'lodash';
import {
  Box,
  Button,
  Center,
  CheckIcon,
  CircleIcon,
  Divider,
  HStack,
  Image,
  Input,
  Modal,
  Pressable,
  Text,
  VStack,
  useBreakpointValue,
} from 'native-base';
import React, { useCallback, useMemo, useState } from 'react';

import { Listings, Orders } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';

import WaffleErrorComponent from '../../components/WaffleErrorComponent';
import WaffleLoaderComponent from '../../components/WaffleLoaderComponent';
import {
  orderAtom,
  useItemsMap,
  useSelectedLocation,
  useSubdomainSellerQuery,
} from '../../utils/store';

const OrderLineItemEditModal = ({
  orderLineItem,
  onSave,
  onRemove,
  onClose,
}: {
  orderLineItem: Orders.OrderLineItem;
  onSave: (orderLineItem: Orders.OrderLineItem) => void;
  onClose: () => void;
  onRemove?: (orderLineItemId: string) => void;
}) => {
  const isLandscape: boolean = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();
  const selectedLocation = useSelectedLocation();
  const [order] = useAtom(orderAtom);
  const [modalOrderLineItem, setModalOrderLineItem] =
    useState<Orders.OrderLineItem>(_.cloneDeep(orderLineItem));
  const [modalOrderAddOns, setModalOrderAddOns] = useState<Orders.OrderAddOn[]>(
    modalOrderLineItem.addOns,
  );
  const [modalNote, setModalNote] = useState<string>(
    modalOrderLineItem.note ?? '',
  );
  const debouncedSetModalNote = useCallback(
    _.debounce(setModalNote, 200, {
      trailing: true,
    }),
    [setModalNote],
  );

  const itemsMap: Record<string, Listings.Item> = useItemsMap();
  const selectedItem: Listings.Item | undefined = useMemo(
    () => itemsMap[orderLineItem.itemId],
    [orderLineItem, itemsMap],
  );
  const isLineItemInCart: boolean = useMemo(
    () =>
      !!order.lineItems.find((lineItem) => lineItem.id === orderLineItem.id),
    [orderLineItem, order],
  );

  const canSaveLineItem: boolean = useMemo(() => {
    if (modalOrderLineItem.quantity <= 0) {
      return false;
    }

    for (const addOnSet of selectedItem?.addOnSets ?? []) {
      const qtyOrderAddOnsForThisAddOnSet: number = _(modalOrderAddOns)
        .filter((orderAddOn) => orderAddOn.addOnSetId === addOnSet.id)
        .sumBy((orderAddOn) => orderAddOn.quantity);

      if (
        qtyOrderAddOnsForThisAddOnSet < addOnSet.minQuantity ||
        qtyOrderAddOnsForThisAddOnSet > (addOnSet.maxQuantity ?? Infinity)
      ) {
        return false;
      }
    }
    return true;
  }, [modalOrderAddOns, selectedItem]);

  if (isSellerError) {
    return <WaffleErrorComponent />;
  }

  if (!seller) {
    return <WaffleLoaderComponent />;
  }

  if (!selectedItem) {
    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        size={isLandscape ? 'xl' : 'full'}
        maxHeight={'100vh'}
        safeArea={true}
        avoidKeyboard={true}>
        <Modal.Content
          minHeight={isLandscape ? 0 : '100%'}
          borderRadius={isLandscape ? 8 : 0}>
          <Modal.CloseButton />
          {/* Nativebase modal has default padding of 12, so we need to remove */}
          <Modal.Header>
            <Text variant={'header'}>{orderLineItem.name}</Text>
          </Modal.Header>
          <Modal.Body>
            <Box marginBottom={8}>
              <Text variant={'subHeader'} marginBottom={2}>
                Special Request (Optional)
              </Text>
              <Divider marginBottom={2} />
              <Input
                defaultValue={modalNote}
                onChangeText={debouncedSetModalNote}
              />
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <HStack flex={1}>
              <Button.Group
                isAttached
                marginRight={2}
                colorScheme={'onSurface'}
                variant={'outline'}
                backgroundColor={'background.0'}>
                <Button
                  isDisabled={modalOrderLineItem.quantity === 0}
                  onPress={() => {
                    if (
                      modalOrderLineItem.quantity === 0 ||
                      (!isLineItemInCart && modalOrderLineItem.quantity === 1)
                    ) {
                      return;
                    }
                    setModalOrderLineItem(
                      Orders.OrderLineItem.create({
                        ...modalOrderLineItem,
                        quantity: modalOrderLineItem.quantity - 1,
                      }),
                    );
                  }}>
                  -
                </Button>
                <Button isDisabled={true}>
                  <Text>{modalOrderLineItem.quantity}</Text>
                </Button>
                <Button
                  isDisabled={modalOrderLineItem.quantity >= 1}
                  onPress={() =>
                    setModalOrderLineItem(
                      Orders.OrderLineItem.create({
                        ...modalOrderLineItem,
                        quantity: modalOrderLineItem.quantity + 1,
                      }),
                    )
                  }>
                  +
                </Button>
              </Button.Group>
              {isLineItemInCart &&
              modalOrderLineItem.quantity === 0 &&
              !!onRemove ? (
                <Button
                  flex={1}
                  onPress={() => {
                    onRemove(modalOrderLineItem.id);
                  }}>
                  Remove from cart
                </Button>
              ) : (
                <Button
                  flex={1}
                  isDisabled={!canSaveLineItem}
                  onPress={() => {
                    onSave(
                      Orders.OrderLineItem.create({
                        ...modalOrderLineItem,
                        addOns: modalOrderAddOns,
                        note: modalNote || null,
                      }),
                    );
                  }}>
                  {`${
                    isLineItemInCart ? 'Update' : 'Add to'
                  } Cart ${MoneyUtil.formatCurrency({
                    amount:
                      modalOrderLineItem.quantity *
                      (modalOrderLineItem.price +
                        _.sumBy(
                          modalOrderAddOns,
                          (orderAddOn) =>
                            orderAddOn.quantity * orderAddOn.price,
                        )),
                    currencyCode: seller.defaultCurrencyCode,
                  })}`}
                </Button>
              )}
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size={isLandscape ? 'xl' : 'full'}
      maxHeight={'100vh'}
      safeArea={true}
      avoidKeyboard={true}>
      <Modal.Content
        minHeight={isLandscape ? 0 : '100%'}
        borderRadius={isLandscape ? 8 : 0}>
        <Modal.CloseButton />
        {/* Nativebase modal has default padding of 12, so we need to remove */}
        <Modal.Body padding={0}>
          {!!selectedItem.image && (
            <Box alignItems={'center'} marginBottom={4}>
              {/* TODO: if put cache will unlimited rerender */}
              <Image
                alt={selectedItem.name + ' image'}
                width={'100vw'}
                height={isLandscape ? 400 : 300}
                // source={{
                //   uri: selectedItem.image.url,
                //   cache: 'force-cache',
                // }}
                src={selectedItem.image.url}
              />
            </Box>
          )}
          {/* Item Details */}

          <Box padding={4}>
            <Text variant={'header'}>{selectedItem.name}</Text>
            {!!selectedItem.description && (
              <Text>{selectedItem.description}</Text>
            )}
          </Box>

          <Divider marginBottom={8} />

          <Box paddingX={4} borderTopRadius={15}>
            {selectedItem.variations.length > 1 && (
              <Box marginBottom={8}>
                <HStack alignItems={'baseline'}>
                  <Text variant={'subHeader'} marginBottom={2}>
                    Variant
                  </Text>
                  <Text variant={'subText'} marginLeft={2}>
                    (Select one)
                  </Text>
                </HStack>

                <Divider marginBottom={2} />
                {selectedItem.variations.map((itemVariation) => {
                  const isSelected: boolean =
                    modalOrderLineItem.itemVariationId === itemVariation.id;
                  const isSellable: boolean =
                    !itemVariation.soldOutAtLocationIds.includes(
                      selectedLocation.id,
                    );
                  return (
                    <Pressable
                      flexDirection={'row'}
                      backgroundColor={
                        isSelected ? 'background.100' : undefined
                      }
                      opacity={isSellable ? 1 : 0.25}
                      onPress={() => {
                        if (!isSellable) {
                          return;
                        }
                        setModalOrderLineItem(() =>
                          Orders.OrderLineItem.create({
                            ...modalOrderLineItem,
                            itemVariationId: itemVariation.id,
                            selectedItemVariationName: itemVariation.name,
                            price: itemVariation.price,
                          }),
                        );
                      }}
                      key={itemVariation.id}
                      alignItems={'center'}
                      _hover={{ backgroundColor: 'background.100' }}
                      padding={2}
                      marginY={1}
                      borderRadius={4}>
                      <HStack
                        flex={1}
                        alignItems={'center'}
                        justifyContent={'space-between'}>
                        <Text flex={1}>{itemVariation.name}</Text>
                        <HStack alignItems={'center'}>
                          <Text variant={'subText'}>
                            {MoneyUtil.formatCurrency({
                              amount: itemVariation.price,
                              currencyCode: seller.defaultCurrencyCode,
                            })}
                          </Text>
                          {isSelected ? (
                            <Center
                              size={5}
                              borderRadius={'full'}
                              backgroundColor={'primary.600'}
                              borderWidth={1}
                              borderColor={'background.300'}
                              marginLeft={4}>
                              <CircleIcon size={3} color={'onSurface.0'} />
                            </Center>
                          ) : (
                            <Center
                              size={5}
                              borderRadius={'full'}
                              borderWidth={1}
                              borderColor={'background.300'}
                              marginLeft={4}
                            />
                          )}
                        </HStack>
                      </HStack>
                    </Pressable>
                  );
                })}
              </Box>
            )}

            {selectedItem.addOnSetIds.length > 0 && (
              <Box marginBottom={8}>
                <Text variant={'subHeader'} marginBottom={2}>
                  Add Ons
                </Text>
                <Divider marginBottom={2} />
                {selectedItem.addOnSets.map((addOnSet) => {
                  const addOnSetSelectedQuantity = _(modalOrderAddOns)
                    .filter(
                      (orderAddOn: Orders.OrderAddOn) =>
                        orderAddOn.addOnSetId === addOnSet.id,
                    )
                    .sumBy(
                      (orderAddOn: Orders.OrderAddOn) => orderAddOn.quantity,
                    );
                  const isAddOnSetSelectedQuantityValid: boolean =
                    addOnSetSelectedQuantity >= addOnSet.minQuantity &&
                    addOnSetSelectedQuantity <=
                      (addOnSet.maxQuantity ?? Infinity);

                  return (
                    <VStack key={addOnSet.id} marginBottom={4}>
                      <HStack alignItems={'baseline'}>
                        <Box flex={1}>
                          <Text
                            fontWeight={'medium'}
                            color={'text.600'}
                            fontSize={'16'}>
                            {addOnSet.name}
                          </Text>
                          <Text variant={'subText'}>
                            {[
                              `(${
                                addOnSet.allowMultiple
                                  ? 'Select multiple'
                                  : 'Select one'
                              }`,
                              addOnSet.minQuantity > 0
                                ? `min ${addOnSet.minQuantity}`
                                : undefined,
                              !!addOnSet.maxQuantity && addOnSet.maxQuantity > 0
                                ? `max ${addOnSet.maxQuantity}`
                                : undefined,
                            ]
                              .filter(
                                (text: string | undefined) =>
                                  text !== undefined,
                              )
                              .join(', ')
                              // Add the last bracket
                              .concat(')')}
                          </Text>
                        </Box>
                        <HStack alignItems={'center'}>
                          <Text
                            variant={'subText'}
                            color={
                              isAddOnSetSelectedQuantityValid
                                ? undefined
                                : 'danger.600'
                            }
                            marginLeft={2}>
                            {`${addOnSetSelectedQuantity} Selected`}
                          </Text>
                        </HStack>
                      </HStack>

                      {addOnSet.addOns.map((addOn) => {
                        const modalOrderAddOn: Orders.OrderAddOn | undefined =
                          modalOrderAddOns.find(
                            (orderAddOn) => orderAddOn.addOnId === addOn.id,
                          );
                        const modalOrderAddOnQuantity: number =
                          modalOrderAddOn?.quantity ?? 0;
                        const isSelected: boolean = !!modalOrderAddOn;
                        const isSellable: boolean =
                          !addOn.soldOutAtLocationIds.find(
                            (locationId) => locationId === selectedLocation.id,
                          );

                        if (!addOnSet.allowMultiple) {
                          return (
                            <Pressable
                              key={addOn.id}
                              flexDirection={'row'}
                              alignItems={'center'}
                              backgroundColor={
                                isSelected ? 'background.100' : undefined
                              }
                              _hover={{ backgroundColor: 'background.100' }}
                              marginY={1}
                              padding={2}
                              borderRadius={4}
                              opacity={isSellable ? 1 : 0.25}
                              isDisabled={!isSellable}
                              onPress={() => {
                                if (!isSellable) {
                                  return;
                                }
                                setModalOrderAddOns((modalOrderAddOns) =>
                                  modalOrderAddOns.filter(
                                    (orderAddOn) =>
                                      orderAddOn.addOnSetId !== addOnSet.id,
                                  ),
                                );
                                if (!isSelected) {
                                  setModalOrderAddOns((modalOrderAddOns) => [
                                    ...modalOrderAddOns,
                                    Orders.OrderAddOn.fromListingAddOn({
                                      listingAddOn: addOn,
                                      listingAddOnSet: addOnSet,
                                    }),
                                  ]);
                                }
                              }}>
                              <HStack alignItems={'center'} flex={1}>
                                <Text color={'gray.500'}>{addOn.name}</Text>
                              </HStack>
                              <HStack alignItems={'center'}>
                                <Text variant={'subText'} marginRight={4}>
                                  {isSellable
                                    ? `+${MoneyUtil.formatCurrency({
                                        amount: addOn.price,
                                        currencyCode:
                                          seller.defaultCurrencyCode,
                                      })}`
                                    : `Sold out`}
                                </Text>
                                {addOnSet.allowQuantity ? (
                                  <>
                                    <Button.Group
                                      isAttached
                                      size={'sm'}
                                      height={'6'}
                                      colorScheme={'onSurface'}
                                      variant={'outline'}
                                      backgroundColor={'background.0'}>
                                      <Button
                                        colorScheme={
                                          isSelected ? 'primary' : 'onSurface'
                                        }
                                        isDisabled={
                                          !isSellable ||
                                          modalOrderAddOnQuantity === 0
                                        }
                                        onPress={() => {
                                          if (!isSellable) {
                                            return;
                                          }
                                          if (modalOrderAddOnQuantity <= 1) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.filter(
                                                  (orderAddOn) =>
                                                    orderAddOn.addOnId !==
                                                    addOn.id,
                                                ),
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity - 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        -
                                      </Button>
                                      <Button
                                        colorScheme={
                                          isSelected ? 'primary' : 'onSurface'
                                        }
                                        isDisabled={true}>
                                        {modalOrderAddOnQuantity.toString()}
                                      </Button>
                                      <Button
                                        colorScheme={
                                          isSelected ? 'primary' : 'onSurface'
                                        }
                                        isDisabled={!isSellable}
                                        onPress={() => {
                                          if (!isSellable) {
                                            return;
                                          }
                                          if (!isSelected) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.filter(
                                                  (orderAddOn) =>
                                                    orderAddOn.addOnSetId !==
                                                    addOnSet.id,
                                                ),
                                            );
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) => [
                                                ...modalOrderAddOns,
                                                Orders.OrderAddOn.fromListingAddOn(
                                                  {
                                                    listingAddOn: addOn,
                                                    listingAddOnSet: addOnSet,
                                                  },
                                                ),
                                              ],
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity + 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        +
                                      </Button>
                                    </Button.Group>
                                  </>
                                ) : (
                                  <>
                                    {isSelected ? (
                                      <Center
                                        size={5}
                                        borderRadius={'full'}
                                        backgroundColor={'primary.600'}
                                        borderWidth={1}
                                        borderColor={'background.300'}>
                                        <CircleIcon
                                          size={3}
                                          color={'onSurface.0'}
                                        />
                                      </Center>
                                    ) : (
                                      <Center
                                        size={5}
                                        borderRadius={'full'}
                                        borderWidth={1}
                                        borderColor={'background.300'}
                                      />
                                    )}
                                  </>
                                )}
                              </HStack>
                            </Pressable>
                          );
                        } else {
                          return (
                            <Pressable
                              key={addOn.id}
                              flexDirection={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              backgroundColor={
                                isSelected ? 'background.100' : undefined
                              }
                              _hover={{ backgroundColor: 'background.100' }}
                              padding={2}
                              marginY={1}
                              borderRadius={4}
                              opacity={isSellable ? 1 : 0.25}
                              isDisabled={!isSellable}
                              onPress={() => {
                                if (!isSellable) {
                                  return;
                                }
                                if (isSelected) {
                                  setModalOrderAddOns((modalOrderAddOns) =>
                                    modalOrderAddOns.filter(
                                      (orderAddOn) =>
                                        orderAddOn.addOnId !== addOn.id,
                                    ),
                                  );
                                } else {
                                  setModalOrderAddOns((modalOrderAddOns) => [
                                    ...modalOrderAddOns,
                                    Orders.OrderAddOn.fromListingAddOn({
                                      listingAddOn: addOn,
                                      listingAddOnSet: addOnSet,
                                    }),
                                  ]);
                                }
                              }}>
                              <HStack alignItems={'center'} flex={1}>
                                <Text color={'gray.500'}>{addOn.name}</Text>
                              </HStack>

                              <HStack alignItems={'center'}>
                                <Text variant={'subText'} marginRight={4}>
                                  {isSellable
                                    ? `+${MoneyUtil.formatCurrency({
                                        amount: addOn.price,
                                        currencyCode:
                                          seller.defaultCurrencyCode,
                                      })}`
                                    : `Sold out`}
                                </Text>
                                {addOnSet.allowQuantity ? (
                                  <>
                                    <Button.Group
                                      isAttached
                                      size={'sm'}
                                      height={'6'}
                                      colorScheme={'onSurface'}
                                      variant={'outline'}
                                      backgroundColor={'background.0'}>
                                      <Button
                                        colorScheme={
                                          isSelected ? 'primary' : 'onSurface'
                                        }
                                        isDisabled={
                                          !isSellable ||
                                          modalOrderAddOnQuantity === 0
                                        }
                                        onPress={() => {
                                          if (!isSellable) {
                                            return;
                                          }
                                          if (modalOrderAddOnQuantity <= 1) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.filter(
                                                  (orderAddOn) =>
                                                    orderAddOn.addOnId !==
                                                    addOn.id,
                                                ),
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity - 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        -
                                      </Button>
                                      <Button
                                        colorScheme={
                                          isSelected ? 'primary' : 'onSurface'
                                        }
                                        isDisabled>
                                        {modalOrderAddOnQuantity.toString()}
                                      </Button>
                                      <Button
                                        colorScheme={
                                          isSelected ? 'primary' : 'onSurface'
                                        }
                                        isDisabled={!isSellable}
                                        onPress={() => {
                                          if (!isSellable) {
                                            return;
                                          }
                                          if (!isSelected) {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) => [
                                                ...modalOrderAddOns,
                                                Orders.OrderAddOn.fromListingAddOn(
                                                  {
                                                    listingAddOn: addOn,
                                                    listingAddOnSet: addOnSet,
                                                  },
                                                ),
                                              ],
                                            );
                                          } else {
                                            setModalOrderAddOns(
                                              (modalOrderAddOns) =>
                                                modalOrderAddOns.map((x) => {
                                                  if (
                                                    x.addOnId ===
                                                    modalOrderAddOn.addOnId
                                                  ) {
                                                    return Orders.OrderAddOn.setQuantity(
                                                      x,
                                                      x.quantity + 1,
                                                    );
                                                  } else {
                                                    return x;
                                                  }
                                                }),
                                            );
                                          }
                                        }}>
                                        +
                                      </Button>
                                    </Button.Group>
                                  </>
                                ) : (
                                  <>
                                    {isSelected ? (
                                      <Center
                                        size={5}
                                        borderRadius={4}
                                        backgroundColor={'primary.600'}
                                        borderWidth={1}
                                        borderColor={'background.300'}>
                                        <CheckIcon
                                          size={3}
                                          color={'onSurface.0'}
                                        />
                                      </Center>
                                    ) : (
                                      <Center
                                        size={5}
                                        borderRadius={4}
                                        borderWidth={1}
                                        borderColor={'background.300'}
                                      />
                                    )}
                                  </>
                                )}
                              </HStack>
                            </Pressable>
                          );
                        }
                      })}
                    </VStack>
                  );
                })}
              </Box>
            )}
            <Box marginBottom={8}>
              <Text variant={'subHeader'} marginBottom={2}>
                Special Request (Optional)
              </Text>
              <Divider marginBottom={2} />
              <Input
                defaultValue={modalNote}
                onChangeText={debouncedSetModalNote}
              />
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <HStack flex={1}>
            <Button.Group
              isAttached
              marginRight={2}
              colorScheme={'onSurface'}
              variant={'outline'}
              backgroundColor={'background.0'}>
              <Button
                isDisabled={modalOrderLineItem.quantity === 0}
                onPress={() => {
                  if (
                    modalOrderLineItem.quantity === 0 ||
                    (!isLineItemInCart && modalOrderLineItem.quantity === 1)
                  ) {
                    return;
                  }
                  setModalOrderLineItem(
                    Orders.OrderLineItem.create({
                      ...modalOrderLineItem,
                      quantity: modalOrderLineItem.quantity - 1,
                    }),
                  );
                }}>
                -
              </Button>
              <Button isDisabled={true}>
                <Text>{modalOrderLineItem.quantity}</Text>
              </Button>
              <Button
                onPress={() =>
                  setModalOrderLineItem(
                    Orders.OrderLineItem.create({
                      ...modalOrderLineItem,
                      quantity: modalOrderLineItem.quantity + 1,
                    }),
                  )
                }>
                +
              </Button>
            </Button.Group>
            {isLineItemInCart &&
            modalOrderLineItem.quantity === 0 &&
            !!onRemove ? (
              <Button
                flex={1}
                onPress={() => {
                  onRemove(modalOrderLineItem.id);
                }}>
                Remove from cart
              </Button>
            ) : (
              <Button
                flex={1}
                isDisabled={!canSaveLineItem}
                onPress={() => {
                  onSave(
                    Orders.OrderLineItem.create({
                      ...modalOrderLineItem,
                      addOns: modalOrderAddOns,
                      note: modalNote || null,
                    }),
                  );
                }}>
                {`${
                  isLineItemInCart ? 'Update' : 'Add to'
                } Cart ${MoneyUtil.formatCurrency({
                  amount:
                    modalOrderLineItem.quantity *
                    (modalOrderLineItem.price +
                      _.sumBy(
                        modalOrderAddOns,
                        (orderAddOn) => orderAddOn.quantity * orderAddOn.price,
                      )),
                  currencyCode: seller.defaultCurrencyCode,
                })}`}
              </Button>
            )}
          </HStack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default OrderLineItemEditModal;
