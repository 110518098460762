import _ from 'lodash';

import { MoneyUtil } from '../money/MoneyUtil';
import { PhoneUtil } from '../phone/PhoneUtil';

export namespace CountryUtil {
  const COUNTRY_CONFIG_MAP = {
    SG: {
      code: 'SG',
      name: 'Singapore',
      currencyCode: MoneyUtil.CurrencyCode.SGD,
      callingCode: PhoneUtil.CallingCode.SG,
      flagIcon: '🇸🇬',
    },
    MY: {
      code: 'MY',
      name: 'Malaysia',
      currencyCode: MoneyUtil.CurrencyCode.MYR,
      callingCode: PhoneUtil.CallingCode.MY,
      flagIcon: '🇲🇾',
    },
    HK: {
      code: 'HK',
      name: 'Hong Kong',
      currencyCode: MoneyUtil.CurrencyCode.HKD,
      callingCode: PhoneUtil.CallingCode.HK,
      flagIcon: '🇭🇰',
    },
    PH: {
      code: 'PH',
      name: 'Philippines',
      currencyCode: MoneyUtil.CurrencyCode.PHP,
      callingCode: PhoneUtil.CallingCode.PH,
      flagIcon: '🇵🇭',
    },
  } as const;

  export type CountryConfig =
    (typeof COUNTRY_CONFIG_MAP)[keyof typeof COUNTRY_CONFIG_MAP];

  export type CallingCode = CountryConfig['callingCode'];

  export const CountryCode: Record<
    keyof typeof COUNTRY_CONFIG_MAP,
    keyof typeof COUNTRY_CONFIG_MAP
  > = _.mapValues(
    COUNTRY_CONFIG_MAP,
    (value, key) => key as keyof typeof COUNTRY_CONFIG_MAP,
  );

  export type CountryCode = keyof typeof CountryCode;

  export const getCountryConfigMap = (): CountryConfig[] => {
    return Object.values(COUNTRY_CONFIG_MAP);
  };

  export const getCountryConfig = <T extends CountryCode>(
    countryCode: T,
  ): (typeof COUNTRY_CONFIG_MAP)[T] => {
    const res = COUNTRY_CONFIG_MAP[countryCode];
    if (!res) {
      throw new Error(`Unsupported country code ${countryCode}`);
    }
    return res;
  };
}
