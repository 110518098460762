import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentIntentResult, loadStripe } from '@stripe/stripe-js';
import { StripeError } from '@stripe/stripe-js/types/stripe-js/stripe';
import { useMutation, useQuery } from '@tanstack/react-query';
import Lottie from 'lottie-react';
import {
  Box,
  Button,
  Center,
  ChevronLeftIcon,
  Divider,
  HStack,
  Text,
} from 'native-base';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import lottieSuccessJson from '../../assets/lottie/95029-success.json';
import WaffleLoaderComponent from '../../components/WaffleLoaderComponent';
import EnvConfig from '../../utils/EnvConfig';

const stripePromise = loadStripe(EnvConfig.STRIPE_PUBLISHABLE_KEY); // Instantiate once only

const PaymentPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const clientSecret: string | undefined = searchParams.get(
    'payment_intent_client_secret',
  );

  // No client secret is provided, the user probably reached this page via an invalid path
  // Redirect to the start of ordering flow
  if (!clientSecret) {
    navigate('/checkout', { replace: true });
    return null;
  }

  return (
    <Elements
      options={{
        clientSecret: clientSecret,
        appearance: {
          theme: 'stripe',
        },
      }}
      stripe={stripePromise}>
      <StripeForm
        clientSecret={clientSecret}
        onBack={() => {
          navigate(-1);
        }}
        onNext={() => {
          navigate(`/orders/${orderId}/summary`);
        }}
        // Stripe will redirect to the following url on payment success:
        // https://subdomain.order.wafflepos.com/orders/67648586-2e95-457e-8574-ba9a03995e45
        // ?payment_intent=pi_3MHLyiEYxe7xsCph18r7F4ca
        // &payment_intent_client_secret=pi_3MHLyiEYxe7xsCph18r7F4ca_secret_lw2NoFNZ0iGoxfdg3dC1R2O4g
        // &redirect_status=succeeded
        returnUrl={`${window.location.origin}/orders/${orderId}/pay`}
      />
    </Elements>
  );
};

const StripeForm = ({
  clientSecret,
  onBack,
  onNext,
  returnUrl,
}: {
  clientSecret: string;
  onBack: () => void;
  onNext: () => void;
  returnUrl: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const canSubmitPayment: boolean = !!stripe && !!elements;

  const {
    data: paymentIntent,
    isPending: isPaymentIntentPending,
    isError: isPaymentIntentError,
  } = useQuery({
    queryKey: ['stripe.payment_intent', clientSecret],
    queryFn: async () => {
      const paymentIntentRes: PaymentIntentResult =
        await stripe.retrievePaymentIntent(clientSecret);
      if (!paymentIntentRes.paymentIntent) {
        throw new Error();
      }

      return paymentIntentRes.paymentIntent;
    },
    enabled: !!stripe,
  });
  useEffect(() => {
    if (!!paymentIntent) {
      if (paymentIntent.status === 'succeeded') {
        // Show payment confirmation, then redirect after 3 seconds
        setTimeout(onNext, 3000);
      }
    }
  }, [paymentIntent]);

  const {
    mutate: handleSubmit,
    isPending: isHandleSubmitPending,
    isError: isHandleSubmitError,
  } = useMutation({
    mutationFn: async (): Promise<void> => {
      if (!canSubmitPayment) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result: never | { error: StripeError } =
        await stripe.confirmPayment({
          elements,
          confirmParams: {
            // If payment is successful, Stripe will always redirect to the `return_url`
            return_url: returnUrl,
          },
        });

      if (result.error) {
        throw new Error(result.error.message);
      }
    },
  });

  const errorMessage =
    isPaymentIntentError ||
    (!!paymentIntent && !!paymentIntent.last_payment_error) ||
    isHandleSubmitError
      ? 'An error occurred when processing your payment. You have not been charged, please try again.'
      : undefined;

  if (isPaymentIntentPending) {
    return <WaffleLoaderComponent />;
  }

  if (!!paymentIntent && paymentIntent.status === 'succeeded') {
    return (
      <Center height={'100%'} width={'100%'}>
        <Center width={'100%'} maxWidth={600}>
          <Center
            backgroundColor={'background.0'}
            margin={4}
            padding={8}
            borderRadius={'lg'}>
            <Center height={'200px'} width={'200px'}>
              <Lottie
                animationData={lottieSuccessJson}
                loop={false}
                autoplay={true}
              />
            </Center>

            <Text fontWeight={'medium'} textAlign={'center'}>
              Payment successful!
            </Text>
            <Text textAlign={'center'}>
              Please wait while we redirect you to your order
            </Text>
          </Center>
        </Center>
      </Center>
    );
  }

  return (
    <Box
      minHeight={'100%'}
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <Box flex={1} width={'100%'} maxWidth={600}>
        {/*Header*/}
        <HStack padding={2}>
          <Button
            variant={'ghost'}
            colorScheme={'onSurface'}
            leftIcon={<ChevronLeftIcon size={4} color={'text.400'} />}
            onPress={() => onBack()}>
            Review order
          </Button>
        </HStack>

        <Box
          margin={2}
          padding={4}
          borderRadius={'lg'}
          backgroundColor={'background.0'}>
          <Text variant={'subHeader'} marginBottom={2}>
            Payment
          </Text>
          <Divider marginBottom={4} />
          {!!errorMessage && (
            <Box
              marginY={2}
              padding={4}
              borderRadius={'lg'}
              backgroundColor={'error.100'}
              borderColor={'error.200'}
              borderWidth={1}>
              <Text color={'danger.800'}>{errorMessage}</Text>
            </Box>
          )}
          <PaymentElement
            options={{
              layout: {
                type: 'accordion',
              },
              paymentMethodOrder: ['apple_pay', 'google_pay'], // ApplePay and GooglePay options always come first
            }}
          />
        </Box>
      </Box>
      <>
        {/*Spacer for footer*/}
        <Box height={20} />
        {/*Footer*/}
        <Box
          position={'fixed'}
          bottom={0}
          height={20}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          backgroundColor={'background.0'}>
          <Box height={'100%'} width={'100%'} maxWidth={600} padding={4}>
            <Button
              flex={1}
              size={'lg'}
              isLoading={isHandleSubmitPending}
              disabled={!canSubmitPayment}
              onPress={() => handleSubmit()}>
              Submit
            </Button>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default PaymentPage;
