import { z } from 'zod';

export enum SegmentFilterType {
  TIER = 'TIER',
  BIRTHDAY = 'BIRTHDAY',
}

export type SegmentFilterTier = z.infer<typeof SegmentFilterTier.schema>;
export namespace SegmentFilterTier {
  export const _type = 'customer_sets.segment_filter';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    filterType: z
      .literal(SegmentFilterType.TIER)
      .default(SegmentFilterType.TIER),
    tierId: z.string().uuid(),
  });

  export const create = (
    args: Partial<SegmentFilterTier>,
  ): SegmentFilterTier => {
    return schema.parse({
      ...args,
      _type: _type,
      filterType: SegmentFilterType.TIER,
    });
  };
}

export type SegmentFilter = z.infer<typeof SegmentFilter.schema>;
export namespace SegmentFilter {
  export const _type = 'customer_sets.segment_filter';

  export const schema = z.discriminatedUnion('filterType', [
    SegmentFilterTier.schema,
  ]);

  export const create = (args: Partial<SegmentFilter>): SegmentFilter => {
    switch (args.filterType) {
      case SegmentFilterType.TIER: {
        return SegmentFilterTier.create(args);
      }

      default: {
        throw new Error(
          'No `filterType` field supplied to CustomerSets.SegmentFilter.create()',
        );
      }
    }
  };
}

export enum CustomerSetType {
  AUTO_SEGMENT = 'AUTO_SEGMENT',
  MANUAL_GROUP = 'MANUAL_GROUP',
}

export type BaseCustomerSet = z.infer<typeof BaseCustomerSet.schema>;
export namespace BaseCustomerSet {
  export const _type = 'customer_sets.customer_set' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    type: z.nativeEnum(CustomerSetType),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });
}

export type AutoSegment = z.infer<typeof AutoSegment.schema>;
export namespace AutoSegment {
  export const schema = BaseCustomerSet.schema.extend({
    type: z
      .literal(CustomerSetType.AUTO_SEGMENT)
      .default(CustomerSetType.AUTO_SEGMENT),
    filters: z.array(SegmentFilter.schema).default([]),
  });

  export const create = (
    args: Partial<AutoSegment> & { customerSetId: string }, //TODO: Deprecate temp union type
  ): AutoSegment => {
    return schema.parse({
      ...args,
      id: args.id ?? args.customerSetId,
      type: CustomerSetType.AUTO_SEGMENT,
      filters: args.filters?.map(SegmentFilter.create),
    });
  };
}

export type ManualGroup = z.infer<typeof ManualGroup.schema>;
export namespace ManualGroup {
  export const schema = BaseCustomerSet.schema.extend({
    type: z
      .literal(CustomerSetType.MANUAL_GROUP)
      .default(CustomerSetType.MANUAL_GROUP),
  });

  export const create = (
    args: Partial<ManualGroup> | any, //TODO: Deprecate temp union type
  ): ManualGroup => {
    return schema.parse({
      ...args,
      id: args.id ?? args.customerSetId,
      type: CustomerSetType.MANUAL_GROUP,
    });
  };
}

export type CustomerSet = z.infer<typeof CustomerSet.schema>;
export namespace CustomerSet {
  export const _type = 'customer_sets.customer_set' as const;

  export const schema = z.discriminatedUnion('type', [
    AutoSegment.schema,
    ManualGroup.schema,
  ]);

  export const create = (
    args: Partial<CustomerSet> | any, //TODO: Deprecate temp type
  ): CustomerSet => {
    switch (args.type) {
      case CustomerSetType.AUTO_SEGMENT: {
        return AutoSegment.create(args);
      }
      case CustomerSetType.MANUAL_GROUP: {
        return ManualGroup.create(args);
      }
      default: {
        throw new Error(`No CustomerSet.Type supplied`);
      }
    }
  };
}
