import { z } from 'zod';

import * as Common from '../common';
import * as Sellers from '../sellers';

export type Listing =
  | Item
  | ItemVariation
  | Category
  | AddOnSet
  | AddOn
  | Discount
  | ExtraCharge
  | Tax
  | Voucher
  | OrderType
  | CustomPaymentMethod;

export enum ListingType {
  ITEM = 'ITEM',
  ITEM_VARIATION = 'ITEM_VARIATION',
  CATEGORY = 'CATEGORY',
  DISCOUNT = 'DISCOUNT',
  ADD_ON_SET = 'ADD_ON_SET',
  ADD_ON = 'ADD_ON',
  EXTRA_CHARGE = 'EXTRA_CHARGE',
  TAX = 'TAX',
  VOUCHER = 'VOUCHER',
  ORDER_TYPE = 'ORDER_TYPE',
  CUSTOM_PAYMENT_METHOD = 'CUSTOM_PAYMENT_METHOD',
}

export type ExtraCharge = z.infer<typeof ExtraCharge.schema> & {
  image?: Common.Image;
};

export namespace ExtraCharge {
  export const _type = 'listings.extra_charge' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    listingType: z
      .literal(ListingType.EXTRA_CHARGE)
      .default(ListingType.EXTRA_CHARGE),
    unit: z.nativeEnum(Common.ModifierUnit),
    dollarValue: z.number(),
    percentValue: z.number(),
    shouldApplyAutomatically: z.boolean().default(false), // TODO: Deprecate, if this should apply automatically, use OrderType to apply it instead
    isLineItemPricingInclusive: z.boolean().default(true),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string()).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<ExtraCharge> | any): ExtraCharge => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.extraChargeId,
      listingType: ListingType.EXTRA_CHARGE,
    });
  };
}

export type ExtraChargeCreateParams = z.infer<
  typeof ExtraChargeCreateParams.schema
>;

export namespace ExtraChargeCreateParams {
  export const schema = z.object({
    name: z.string(),
    listingType: z
      .literal(ListingType.EXTRA_CHARGE)
      .default(ListingType.EXTRA_CHARGE),
    unit: z.nativeEnum(Common.ModifierUnit),
    dollarValue: z.number(),
    percentValue: z.number(),
    shouldApplyAutomatically: z.boolean().default(false), // TODO: Deprecate, if this should apply automatically, use OrderType to apply it instead
    isLineItemPricingInclusive: z.boolean().default(true),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string()).default([]),
  });
}

export type ExtraChargeUpdateParams = z.infer<
  typeof ExtraChargeUpdateParams.schema
>;
export namespace ExtraChargeUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    unit: z.nativeEnum(Common.ModifierUnit).nullish(),
    dollarValue: z.number().nullish(),
    percentValue: z.number().nullish(),
    shouldApplyAutomatically: z.boolean().nullish(), // TODO: Deprecate, if this should apply automatically, use OrderType to apply it instead
    isLineItemPricingInclusive: z.boolean().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string()).nullish(),
  });
}

export type Tax = z.infer<typeof Tax.schema> & {
  image?: Common.Image;
};
export namespace Tax {
  export const _type = 'listings.tax' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    listingType: z.literal(ListingType.TAX).default(ListingType.TAX),
    percentValue: z.number(),
    isLineItemPricingInclusive: z.boolean().default(true),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<Tax> | any): Tax => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.taxId,
      listingType: ListingType.TAX,
    });
  };
}

export type TaxCreateParams = z.infer<typeof TaxCreateParams.schema>;
export namespace TaxCreateParams {
  export const schema = z.object({
    name: z.string(),
    listingType: z.literal(ListingType.TAX).default(ListingType.TAX),
    percentValue: z.number(),
    isLineItemPricingInclusive: z.boolean().default(true),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
  });
}

export type TaxUpdateParams = z.infer<typeof TaxUpdateParams.schema>;
export namespace TaxUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    percentValue: z.number().nullish(),
    isLineItemPricingInclusive: z.boolean().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string().uuid()).nullish(),
  });
}

export type OrderType = z.infer<typeof OrderType.schema> & {
  presetExtraCharges?: ExtraCharge[];
  presetTaxes?: Tax[];
};
export namespace OrderType {
  export const _type = 'listings.order_type' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    listingType: z
      .literal(ListingType.ORDER_TYPE)
      .default(ListingType.ORDER_TYPE),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),

    ticketType: z.nativeEnum(Common.TicketType),
    ticketName: z.string().nullable().default(null),
    presetExtraChargeIds: z.array(z.string().uuid()).default([]),
    presetTaxIds: z.array(z.string().uuid()).default([]),
    shouldAllowSaveOrders: z.boolean().default(false), // Technically sellers can always save orders. This makes it such that a huge button appears above the 'checkout' button to make it easier for the cashier to save the order.
    // For now: If there are any devices configured to receive order tickets (back-of-house printers, KDS), the order ticket can be submitted before payments are made, and not anymore after payment. Mostly for dine-in use cases.
    // Future: Fulfillment process is triggered before payments are made. Default: Fulfillment process is only triggered once payments are completed
    shouldFulfilBeforePayments: z.boolean().default(false), // TODO: rename to shouldFulfillBeforePayments
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<OrderType> | any): OrderType => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.orderTypeId,
      listingType: ListingType.ORDER_TYPE,
    });
  };

  /**
   * Returns true if the ticket value for this order type can be edited
   * If there is no ticket type, or the ticket value is automatically generated, we do not let the user update the ticket value
   */
  export const isTicketValueEditable = (orderType: OrderType): boolean => {
    return orderType.ticketType === Common.TicketType.TEXT;
  };
}

export type OrderTypeCreateParams = z.infer<
  typeof OrderTypeCreateParams.schema
>;
export namespace OrderTypeCreateParams {
  export const schema = z.object({
    name: z.string(),
    listingType: z
      .literal(ListingType.ORDER_TYPE)
      .default(ListingType.ORDER_TYPE),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),

    ticketType: z.nativeEnum(Common.TicketType),
    ticketName: z.string().nullable().default(null),
    presetExtraChargeIds: z.array(z.string().uuid()).default([]),
    presetTaxIds: z.array(z.string().uuid()).default([]),
    shouldAllowSaveOrders: z.boolean().default(false), // Technically sellers can always save orders. This makes it such that a huge button appears above the 'checkout' button to make it easier for the cashier to save the order.
    // For now: If there are any devices configured to receive order tickets (back-of-house printers, KDS), the order ticket can be submitted before payments are made, and not anymore after payment. Mostly for dine-in use cases.
    // Future: Fulfillment process is triggered before payments are made. Default: Fulfillment process is only triggered once payments are completed
    shouldFulfilBeforePayments: z.boolean().default(false), // TODO: rename to shouldFulfillBeforePayments
  });
}

export type OrderTypeUpdateParams = z.infer<
  typeof OrderTypeUpdateParams.schema
>;
export namespace OrderTypeUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string().uuid()).nullish(),
    ticketType: z.nativeEnum(Common.TicketType),
    ticketName: z.string().nullable().nullish(),
    presetExtraChargeIds: z.array(z.string().uuid()).nullish(),
    presetTaxIds: z.array(z.string().uuid()).nullish(),
    shouldAllowSaveOrders: z.boolean().nullish(), // Technically sellers can always save orders. This makes it such that a huge button appears above the 'checkout' button to make it easier for the cashier to save the order.
    // For now: If there are any devices configured to receive order tickets (back-of-house printers, KDS), the order ticket can be submitted before payments are made, and not anymore after payment. Mostly for dine-in use cases.
    // Future: Fulfillment process is triggered before payments are made. Default: Fulfillment process is only triggered once payments are completed
    shouldFulfilBeforePayments: z.boolean().nullish(), // TODO: rename to shouldFulfillBeforePayments
  });
}

export type ItemVariation = z.infer<typeof ItemVariation.schema> & {
  presentAtLocations?: Sellers.Location[];
  soldOutAtLocations?: Sellers.Location[];
  availableForOrderTypes?: OrderType[];
  image?: Common.Image;
};
export namespace ItemVariation {
  export const _type = 'listings.item_variation' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    itemId: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    listingType: z
      .literal(ListingType.ITEM_VARIATION)
      .default(ListingType.ITEM_VARIATION),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.array(z.string()).default([]),
    soldOutAtLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForOnlineOrders: z.boolean().default(false),

    price: z.number(),
    sku: z.string().nullable().default(null),
    shouldTrackInventory: z.boolean().default(false),
    isVariable: z.boolean().default(false),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<ItemVariation> | any): ItemVariation => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.itemVariationId,
      listingType: ListingType.ITEM_VARIATION,
    });
  };
}

export type ItemVariationCreateParams = z.infer<
  typeof ItemVariationCreateParams.schema
>;
export namespace ItemVariationCreateParams {
  export const schema = z.object({
    itemId: z.string().uuid(),
    name: z.string(),
    listingType: z
      .literal(ListingType.ITEM_VARIATION)
      .default(ListingType.ITEM_VARIATION),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.array(z.string()).default([]),
    soldOutAtLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForOnlineOrders: z.boolean().default(false),

    price: z.number(),
    sku: z.string().nullable().default(null),
    shouldTrackInventory: z.boolean().default(false),
    isVariable: z.boolean().default(false),
  });
}

export type ItemVariationUpdateParams = z.infer<
  typeof ItemVariationUpdateParams.schema
>;
export namespace ItemVariationUpdateParams {
  export const schema = z.object({
    id: z.string().uuid().optional(),
    itemId: z.string().uuid(),
    name: z.string().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string().uuid()).nullish(),
    isAvailableForAllOrderTypes: z.boolean().nullish(),
    availableForOrderTypeIds: z.array(z.string()).nullish(),
    soldOutAtLocationIds: z.array(z.string().uuid()).nullish(),
    isAvailableForOnlineOrders: z.boolean().nullish(),
    price: z.number().nullish(),
    sku: z.string().nullable().nullish(),
    shouldTrackInventory: z.boolean().nullish(),
    isVariable: z.boolean().nullish(),
  });
}

export type Category = z.infer<typeof Category.schema> & {
  items?: Item[];
  image?: Common.Image;
};
export namespace Category {
  export const _type = 'listings.category' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    listingType: z.literal(ListingType.CATEGORY).default(ListingType.CATEGORY),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<Category> | any): Category => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.categoryId,
      ListingType: ListingType.CATEGORY,
    });
  };
}

export type CategoryCreateParams = z.infer<typeof CategoryCreateParams.schema>;
export namespace CategoryCreateParams {
  export const schema = z.object({
    name: z.string(),
    listingType: z.literal(ListingType.CATEGORY),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
  });
}

export type CategoryUpdateParams = z.infer<typeof CategoryUpdateParams.schema>;
export namespace CategoryUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string().uuid()).nullish(),
  });
}

export type AddOn = z.infer<typeof AddOn.schema> & {
  image?: Common.Image;
};
export namespace AddOn {
  export const _type = 'listings.add_on' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    addOnSetId: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    listingType: z.literal(ListingType.ADD_ON).default(ListingType.ADD_ON),
    price: z.number(),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    soldOutAtLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<AddOn> | any): AddOn => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.addOnId,
      listingType: ListingType.ADD_ON,
    });
  };
}

export type AddOnCreateParams = z.infer<typeof AddOnCreateParams.schema>;
export namespace AddOnCreateParams {
  export const schema = z.object({
    addOnSetId: z.string().uuid(),
    name: z.string(),
    listingType: z.literal(ListingType.ADD_ON).default(ListingType.ADD_ON),
    price: z.number(),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    soldOutAtLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
  });
}

export type AddOnUpdateParams = z.infer<typeof AddOnUpdateParams.schema>;
export namespace AddOnUpdateParams {
  export const schema = z.object({
    id: z.string().uuid(),
    addOnSetId: z.string().uuid(),
    name: z.string().nullish(),
    price: z.number().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string().uuid()).nullish(),
    soldOutAtLocationIds: z.array(z.string().uuid()).nullish(),
    isAvailableForAllOrderTypes: z.boolean().nullish(),
    availableForOrderTypeIds: z.array(z.string().uuid()).nullish(),
  });
}

export type AddOnSet = z.infer<typeof AddOnSet.schema> & {
  image?: Common.Image;
};
export namespace AddOnSet {
  export const _type = 'listings.add_on_set' as const;

  export const schema = z
    .object({
      _type: z.literal(_type).default(_type),
      id: z.string().uuid(),
      sellerId: z.string().uuid(),
      name: z.string(),
      listingType: z
        .literal(ListingType.ADD_ON_SET)
        .default(ListingType.ADD_ON_SET),
      addOns: z.array(AddOn.schema).default([]),
      allowMultiple: z.boolean().default(false),
      allowQuantity: z.boolean().default(false),
      minQuantity: z.number().min(0).default(0),
      maxQuantity: z.number().min(1).nullable().default(1),
      presentAtAllLocations: z.boolean().default(true),
      sellerLocationIds: z.array(z.string().uuid()).default([]),
      isAvailableForAllOrderTypes: z.boolean().default(true),
      availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
      createdAt: z
        .string()
        .datetime({ offset: true })
        .default(() => new Date().toISOString()),
      updatedAt: z
        .string()
        .datetime({ offset: true })
        .default(() => new Date().toISOString()),
    })
    .transform((val) => {
      // If both allowMultiple and allowQuantity is false, maxQuantity is always 1
      if (!val.allowMultiple && !val.allowQuantity) {
        return {
          ...val,
          maxQuantity: 1,
        };
      } else {
        return val;
      }
    })
    .refine(
      (val) => val.maxQuantity === null || val.maxQuantity >= val.minQuantity,
      {
        message:
          'If maxQuantity is present, maxQuantity cannot be less than minQuantity',
      },
    );

  export const create = (args: Partial<AddOnSet> | any): AddOnSet => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.addOnSetId,
      addOns: args.addOns?.map(AddOn.create),
      listingType: ListingType.ADD_ON_SET,
    });
  };
}

export type AddOnSetCreateParams = z.infer<typeof AddOnSetCreateParams.schema>;
export namespace AddOnSetCreateParams {
  export const schema = z
    .object({
      name: z.string(),
      listingType: z.literal(ListingType.ADD_ON_SET),
      addOns: z.array(AddOnCreateParams.schema).default([]),
      allowMultiple: z.boolean().default(false),
      allowQuantity: z.boolean().default(false),
      minQuantity: z.number().min(0).default(0),
      maxQuantity: z.number().min(1).nullable().default(1),
      presentAtAllLocations: z.boolean().default(true),
      sellerLocationIds: z.array(z.string().uuid()).default([]),
      isAvailableForAllOrderTypes: z.boolean().default(true),
      availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
    })
    .refine(
      (val) =>
        !!val.allowQuantity ||
        (!val.allowQuantity &&
          (val.minQuantity === 0 || val.minQuantity === 1)),
      { message: 'If allowQuantity is false, minQuantity can only be 0 or 1' },
    )
    .transform((val) => {
      // If both allowMultiple and allowQuantity is false, maxQuantity is always 1
      if (!val.allowMultiple && !val.allowQuantity) {
        return {
          ...val,
          maxQuantity: 1,
        };
      } else {
        return val;
      }
    })
    .refine(
      (val) => val.maxQuantity === null || val.maxQuantity >= val.minQuantity,
      {
        message:
          'If maxQuantity is present, maxQuantity cannot be less than minQuantity',
      },
    );
}

export type AddOnSetUpdateParams = z.infer<typeof AddOnSetUpdateParams.schema>;
export namespace AddOnSetUpdateParams {
  export const schema = z
    .object({
      id: z.string().uuid().optional(),
      name: z.string().nullish(),
      addOns: z.array(AddOnUpdateParams.schema).nullish(),
      allowMultiple: z.boolean().nullish(),
      allowQuantity: z.boolean().nullish(),
      minQuantity: z.number().min(0).nullish(),
      maxQuantity: z.number().min(1).nullish(),
      presentAtAllLocations: z.boolean().nullish(),
      sellerLocationIds: z.array(z.string().uuid()).nullish(),
      isAvailableForAllOrderTypes: z.boolean().nullish(),
      availableForOrderTypeIds: z.array(z.string().uuid()).nullish(),
    })
    .refine(
      (val) =>
        !!val.allowQuantity ||
        (!val.allowQuantity &&
          (val.minQuantity === 0 || val.minQuantity === 1)),
      { message: 'If allowQuantity is false, minQuantity can only be 0 or 1' },
    )
    .transform((val) => {
      // If both allowMultiple and allowQuantity is false, maxQuantity is always 1
      if (!val.allowMultiple && !val.allowQuantity) {
        return {
          ...val,
          maxQuantity: 1,
        };
      } else {
        return val;
      }
    })
    .refine(
      (val) =>
        val.maxQuantity === null ||
        (!!val.maxQuantity &&
          !!val.minQuantity &&
          val.maxQuantity >= val.minQuantity),
      {
        message:
          'If maxQuantity is present, maxQuantity cannot be less than minQuantity',
      },
    );
}

export type Item = z.infer<typeof Item.schema> & {
  presentAtLocations?: Sellers.Location[];
  soldOutAtLocations?: Sellers.Location[];
  availableForOrderTypes?: OrderType[];
  image?: Common.Image;
  category?: Category;
  addOnSets?: AddOnSet[];
};
export namespace Item {
  export const _type = 'listings.item' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    shortName: z.string().nullable().default(null),
    description: z.string().nullable().default(null),
    listingType: z.literal(ListingType.ITEM).default(ListingType.ITEM),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
    soldOutAtLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForOnlineOrders: z.boolean().default(false),
    imageId: z.string().nullable(),
    categoryId: z.string().uuid().nullable(),
    iconColor: z.string().nullable(),
    variations: z.array(ItemVariation.schema).default([]), // TODO: Min length = 1
    addOnSetIds: z.array(z.string().uuid()),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<Item> | any): Item => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.itemId,
      variations: args.variations?.map(ItemVariation.create),
      ListingType: ListingType.ITEM,
    });
  };

  export const updateItemVariation = (
    item: Item,
    { itemVariation }: { itemVariation: ItemVariation },
  ): Item => {
    return {
      ...item,
      variations: item.variations.map((x) => {
        if (x.id === itemVariation.id) {
          return itemVariation;
        } else {
          return x;
        }
      }),
    };
  };
}

export type ItemCreateParams = z.infer<typeof ItemCreateParams.schema>;
export namespace ItemCreateParams {
  export const schema = z.object({
    name: z.string(),
    shortName: z.string().nullable().default(null),
    description: z.string().nullable().default(null),
    listingType: z.literal(ListingType.ITEM).default(ListingType.ITEM),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
    soldOutAtLocationIds: z.array(z.string().uuid()).default([]),
    isAvailableForOnlineOrders: z.boolean().default(false),
    imageId: z.string().nullable(),
    categoryId: z.string().uuid().nullable(),
    iconColor: z.string().nullable(),
    variations: z.array(ItemVariationCreateParams.schema).default([]), // TODO: Min length = 1
    addOnSetIds: z.array(z.string().uuid()),
  });
}

export type ItemUpdateParams = z.infer<typeof ItemUpdateParams.schema>;
export namespace ItemUpdateParams {
  export const schema = z.object({
    id: z.string().uuid().optional(),
    name: z.string().nullish(),
    shortName: z.string().nullish(),
    description: z.string().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string().uuid()).nullish(),
    isAvailableForAllOrderTypes: z.boolean().nullish(),
    availableForOrderTypeIds: z.array(z.string().uuid()).nullish(),
    soldOutAtLocationIds: z.array(z.string().uuid()).nullish(),
    isAvailableForOnlineOrders: z.boolean().nullish(),
    imageId: z.string().nullish(),
    categoryId: z.string().uuid().nullish(),
    iconColor: z.string().nullish(),
    variations: z.array(ItemVariationUpdateParams.schema).nullish(), // TODO: Min length = 1
    addOnSetIds: z.array(z.string().uuid()).nullish(),
  });
}

export enum DiscountType {
  ITEM = 'ITEM',
  BILL = 'BILL',
}

export type Discount = z.infer<typeof Discount.schema> & {
  image?: Common.Image;
};

export namespace Discount {
  export const _type = 'listings.discount' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    name: z.string(),
    listingType: z.literal(ListingType.DISCOUNT).default(ListingType.DISCOUNT),
    unit: z.nativeEnum(Common.ModifierUnit),
    discountType: z.nativeEnum(DiscountType),
    dollarValue: z.number(),
    percentValue: z.number(),
    isVariable: z.boolean().default(false),
    shouldApplyToAllAddOns: z.boolean().default(true),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    availableForAllOrderTypes: z.boolean().default(false),
    availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<Discount> | any): Discount => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.discountId,
      listingType: ListingType.DISCOUNT,
    });
  };
}

export type DiscountCreateParams = z.infer<typeof DiscountCreateParams.schema>;
export namespace DiscountCreateParams {
  export const schema = z.object({
    name: z.string(),
    listingType: z.literal(ListingType.DISCOUNT).default(ListingType.DISCOUNT),
    unit: z.nativeEnum(Common.ModifierUnit),
    discountType: z.nativeEnum(DiscountType),
    dollarValue: z.number(),
    percentValue: z.number(),
    isVariable: z.boolean().default(false),
    shouldApplyToAllAddOns: z.boolean().default(true),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.array(z.string().uuid()).default([]),
    availableForAllOrderTypes: z.boolean().default(false),
    availableForOrderTypeIds: z.array(z.string().uuid()).default([]),
  });
}

export type DiscountUpdateParams = z.infer<typeof DiscountUpdateParams.schema>;
export namespace DiscountUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    unit: z.nativeEnum(Common.ModifierUnit).nullish(),
    discountType: z.nativeEnum(DiscountType).nullish(),
    dollarValue: z.number().nullish(),
    percentValue: z.number().nullish(),
    isVariable: z.boolean().nullish(),
    shouldApplyToAllAddOns: z.boolean().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.array(z.string().uuid()).nullish(),
    availableForAllOrderTypes: z.boolean().nullish(),
    availableForOrderTypeIds: z.array(z.string().uuid()).nullish(),
  });
}

export type Voucher = z.infer<typeof Voucher.schema> & {
  image?: Common.Image;
};
export namespace Voucher {
  export const _type = 'listings.voucher' as const;

  export const schema = z.object({
    _type: z.string(),
    id: z.string(),
    sellerId: z.string(),
    name: z.string(),
    listingType: z.literal(ListingType.VOUCHER).default(ListingType.VOUCHER),
    amount: z.number(),
    isVariable: z.boolean().default(false),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.string().uuid().array().default([]),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<Voucher> | any): Voucher => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.voucherId,
      listingType: ListingType.VOUCHER,
    });
  };
}

export type VoucherCreateParams = z.infer<typeof VoucherCreateParams.schema>;
export namespace VoucherCreateParams {
  export const schema = z.object({
    name: z.string(),
    listingType: z.literal(ListingType.VOUCHER).default(ListingType.VOUCHER),
    amount: z.number(),
    isVariable: z.boolean().default(false),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.string().uuid().array().default([]),
  });
}

export type VoucherUpdateParams = z.infer<typeof VoucherUpdateParams.schema>;
export namespace VoucherUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    amount: z.number().nullish(),
    isVariable: z.boolean().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.string().uuid().array().nullish(),
  });
}

export type CustomPaymentMethod = z.infer<typeof CustomPaymentMethod.schema> & {
  image?: Common.Image;
};
export namespace CustomPaymentMethod {
  export const _type = 'listings.custom_payment_method' as const;

  export const schema = z.object({
    _type: z.literal(CustomPaymentMethod._type),
    id: z.string(),
    sellerId: z.string(),
    name: z.string(),
    listingType: z
      .literal(ListingType.CUSTOM_PAYMENT_METHOD)
      .default(ListingType.CUSTOM_PAYMENT_METHOD),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.string().uuid().array().default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.string().uuid().array().default([]),
    imageId: z.string().nullable(),
    isRefundable: z.boolean().default(false),
    brand: z.nativeEnum(Common.PaymentMethodBrand).or(z.string()),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (
    args: Partial<CustomPaymentMethod>,
  ): CustomPaymentMethod => {
    return schema.parse({
      ...args,
      _type: _type,
      listingType: ListingType.CUSTOM_PAYMENT_METHOD,
    });
  };
}

export type CustomPaymentMethodCreateParams = z.infer<
  typeof CustomPaymentMethodCreateParams.schema
>;
export namespace CustomPaymentMethodCreateParams {
  export const schema = z.object({
    name: z.string(),
    listingType: z
      .literal(ListingType.CUSTOM_PAYMENT_METHOD)
      .default(ListingType.CUSTOM_PAYMENT_METHOD),
    presentAtAllLocations: z.boolean().default(true),
    sellerLocationIds: z.string().uuid().array().default([]),
    isAvailableForAllOrderTypes: z.boolean().default(true),
    availableForOrderTypeIds: z.string().uuid().array().default([]),
    imageId: z.string().nullable(),
    isRefundable: z.boolean().default(false),
    brand: z.nativeEnum(Common.PaymentMethodBrand).or(z.string()),
  });
}

export type CustomPaymentMethodUpdateParams = z.infer<
  typeof CustomPaymentMethodUpdateParams.schema
>;
export namespace CustomPaymentMethodUpdateParams {
  export const schema = z.object({
    name: z.string().nullish(),
    presentAtAllLocations: z.boolean().nullish(),
    sellerLocationIds: z.string().uuid().array().nullish(),
    isAvailableForAllOrderTypes: z.boolean().nullish(),
    availableForOrderTypeIds: z.string().uuid().array().nullish(),
    imageId: z.string().nullish(),
    isRefundable: z.boolean().nullish(),
    brand: z.nativeEnum(Common.PaymentMethodBrand).or(z.string()).nullish(),
  });
}
