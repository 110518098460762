import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import WaffleErrorComponent from '../../components/WaffleErrorComponent';
import { useSelectedLocation, useSelectedOrderType } from '../../utils/store';
import CheckoutPage from './CheckoutPage';
import ReviewOrderPage from './ReviewOrderPage';

export const CheckoutRouter = () => {
  const navigate = useNavigate();
  const selectedLocation = useSelectedLocation();
  const selectedOrderType = useSelectedOrderType();

  if (!selectedLocation || !selectedOrderType) {
    navigate('/', { replace: true });
    return null;
  }

  return (
    <Routes>
      <Route
        path={'/review/*'}
        element={<ReviewOrderPage />}
        errorElement={<WaffleErrorComponent />}
      />
      <Route
        path={'/*'}
        element={<CheckoutPage />}
        errorElement={<WaffleErrorComponent />}
      />
    </Routes>
  );
};
