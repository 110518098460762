import parseMobile, { AsYouType } from 'libphonenumber-js/mobile';
import _ from 'lodash';

import { CountryUtil } from '../country/CountryUtil';

/**
 * We store mobile numbmers as E.164 format
 */
export namespace PhoneUtil {
  const PHONE_NUMBER_INPUT_REGEX = /^\d{0,14}$/;

  const PHONE_CONFIG_MAP = {
    SG: {
      callingCode: '+65',
    },
    MY: {
      callingCode: '+60',
    },
    HK: {
      callingCode: '+852',
    },
    PH: {
      callingCode: '+63',
    },
  } as const;

  export type PhoneConfig =
    (typeof PHONE_CONFIG_MAP)[keyof typeof PHONE_CONFIG_MAP];

  export const CallingCode: Record<
    keyof typeof PHONE_CONFIG_MAP,
    PhoneConfig['callingCode']
  > = _.mapValues(
    PHONE_CONFIG_MAP,
    (value, key) => value['callingCode'] as PhoneConfig['callingCode'],
  );
  export type CallingCode = PhoneConfig['callingCode'];

  export const getPhoneConfig = (
    countryCode: CountryUtil.CountryCode,
  ): PhoneConfig => {
    const res = PHONE_CONFIG_MAP[countryCode];
    if (!res) {
      throw new Error(`Unsupported country code ${countryCode}`);
    }
    return res;
  };

  export const getCallingCode = <T extends CountryUtil.CountryCode>(
    countryCode: T,
  ): (typeof PHONE_CONFIG_MAP)[T]['callingCode'] => {
    return getPhoneConfig(countryCode).callingCode;
  };

  // https://github.com/catamphetamine/libphonenumber-js?tab=readme-ov-file#isvalidphonenumberforcountryinput-string-country-string-boolean
  export const isValidMobileNumber = ({
    text,
    countryCode,
  }: {
    text: string;
    countryCode?: CountryUtil.CountryCode;
  }): boolean => {
    const phoneNumber = parseMobile(text, {
      defaultCountry: countryCode,
      extract: false,
    });
    if (!phoneNumber) {
      return false;
    }
    if (!!countryCode && phoneNumber.country !== countryCode) {
      return false;
    }
    return phoneNumber.isValid();
  };

  export const isValidMobileNumberInput = (text: string): boolean => {
    return PHONE_NUMBER_INPUT_REGEX.test(text);
  };

  export const formatMobileNumber = ({
    text,
    countryCode,
  }: {
    text: string;
    countryCode: CountryUtil.CountryCode;
  }): string => {
    return new AsYouType(countryCode).input(text);
  };
}
