import Lottie from 'lottie-react';
import { Box, Center } from 'native-base';
import React from 'react';

import foodLoading from '../assets/lottie/106177-food-loading.json';

const WaffleLoaderComponent = () => {
  return (
    <Center height={'100%'} width={'100%'} padding={4}>
      <Box maxWidth={600}>
        <Lottie animationData={foodLoading} loop={true} autoplay={true} />
      </Box>
    </Center>
  );
};
export default WaffleLoaderComponent;
