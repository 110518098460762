import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as CustomerSets from '../customerSets';
import * as Rewards from '../rewards';

export type Customer = z.infer<typeof Customer.schema> & {
  rewardsMembership?: Rewards.RewardsMembership;
  segments?: CustomerSets.CustomerSet[]; //TODO: deprecate field and update refs to customerSets
  customerSets?: CustomerSets.CustomerSet[];
};
export namespace Customer {
  export const _type = 'customers.customer' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    buyerUserId: z.string().uuid(),
    givenName: z.string().nullable(),
    familyName: z.string().nullable(),
    emailAddress: z.string().email().nullable().default(null),
    mobileNumber: z.string().nullable().default(null),
    birthDate: z.string().nullable().default(null),
    note: z.string().nullable().default(null),
    acceptedSellerPrivacyPolicyAt: z
      .string()
      .datetime({ offset: true })
      .nullable(),
    didConsentShareProfile: z.boolean().nullable(),
    didConsentMarketing: z.boolean().default(false),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<Customer>): Customer => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };

  export const sanitize = (args: Customer): Customer => {
    return {
      ...args,
      givenName: null,
      familyName: null,
      emailAddress: null,
      mobileNumber: null,
      birthDate: null,
      note: null,
    };
  };
}

export type CustomerCreateParams = z.infer<typeof CustomerCreateParams.schema>;
export namespace CustomerCreateParams {
  export const schema = z.object({
    buyerUserId: z.string().uuid(),
    givenName: z.string().nullable(),
    familyName: z.string().nullable(),
    emailAddress: z.string().email().nullable().default(null),
    mobileNumber: z.string().nullable().default(null),
    birthDate: z.string().nullable().default(null),
    note: z.string().nullable().default(null),
    didConsentMarketing: z.boolean().default(true),
  });
}

export type CustomerUpdateParams = z.infer<typeof CustomerUpdateParams.schema>;
export namespace CustomerUpdateParams {
  export const schema = z.object({
    givenName: z.string().nullish(),
    familyName: z.string().nullish(),
    emailAddress: z.string().email().nullish(),
    mobileNumber: z.string().nullish(),
    birthDate: z.string().nullish(),
    note: z.string().nullish(),
    didConsentMarketing: z.boolean().nullish(),
  });
}

export interface CustomerMapping {
  emailAddress?: string;
  mobileNumber?: string;
}
