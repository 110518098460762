import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import { Box, NativeBaseProvider, extendTheme } from 'native-base';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Colors } from '@waffle/common/src/styles/styles';

import WaffleErrorComponent from './components/WaffleErrorComponent';
import MainRouter from './pages/MainRouter';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const customTheme = extendTheme({
  components: {
    Input: {
      defaultProps: {
        size: 'lg',
      },
    },
    Text: {
      baseStyle: {
        color: `text.500`,
        fontSize: 'md',
      },
      defaultProps: {},
      variants: {
        header: {
          color: `text.700`,
          fontSize: '2xl',
          fontWeight: 'semibold',
        },
        subHeader: {
          color: `text.600`,
          fontSize: 'xl',
          fontWeight: 'medium',
        },
        label: {
          color: `text.600`,
          fontSize: 'md',
          // fontWeight: 'medium',
        },
        subText: {
          color: `text.400`,
          fontSize: 'sm',
        },
      },
      sizes: {},
    },
  },
  colors: {
    primary: {
      50: Colors.BLUE_50,
      100: Colors.BLUE_100,
      200: Colors.BLUE_200,
      300: Colors.BLUE_300,
      400: Colors.BLUE_400,
      500: Colors.BLUE_500,
      600: Colors.BLUE_600,
      700: Colors.BLUE_700,
      800: Colors.BLUE_800,
      900: Colors.BLUE_900,
    },
    onSurface: {
      0: Colors.WHITE,
      50: Colors.COOL_GRAY_50,
      100: Colors.COOL_GRAY_100,
      200: Colors.COOL_GRAY_200,
      300: Colors.COOL_GRAY_300,
      400: Colors.COOL_GRAY_400,
      500: Colors.COOL_GRAY_500,
      600: Colors.COOL_GRAY_600,
      700: Colors.COOL_GRAY_700,
      800: Colors.COOL_GRAY_800,
      900: Colors.COOL_GRAY_900,
    },
    background: {
      0: Colors.WHITE,
      50: Colors.BLUE_GRAY_50,
      100: Colors.BLUE_GRAY_100,
      200: Colors.BLUE_GRAY_200,
      300: Colors.BLUE_GRAY_300,
      400: Colors.BLUE_GRAY_400,
      500: Colors.BLUE_GRAY_500,
      600: Colors.BLUE_GRAY_600,
      700: Colors.BLUE_GRAY_700,
      800: Colors.BLUE_GRAY_800,
      900: Colors.BLUE_GRAY_900,
    },
    text: {
      0: Colors.WHITE,
      50: Colors.GRAY_50,
      100: Colors.GRAY_100,
      200: Colors.GRAY_200,
      300: Colors.GRAY_300,
      400: Colors.GRAY_400,
      500: Colors.GRAY_500,
      600: Colors.GRAY_600,
      700: Colors.GRAY_700,
      800: Colors.GRAY_800,
      900: Colors.GRAY_900,
    },
  },
});
// 2. Get the type of the CustomTheme
type CustomThemeType = typeof customTheme;

// 3. Extend the internal NativeBase Theme
declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}

// React-router
const router = createBrowserRouter([
  {
    path: '*',
    element: <MainRouter />,
    errorElement: <WaffleErrorComponent />,
  },
]);

export default function App() {
  return (
    // Inject Query Client for React-query
    <QueryClientProvider client={queryClient}>
      <Provider>
        <NativeBaseProvider theme={customTheme}>
          <Box
            safeArea
            height={window ? window.innerHeight : '100vh'}
            width={'100vw'}
            overflowX={'hidden'}
            overflowY={'auto'}
            backgroundColor={'background.100'}>
            <RouterProvider router={router} />
          </Box>
        </NativeBaseProvider>
      </Provider>
    </QueryClientProvider>
  );
}
