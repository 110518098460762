import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import _ from 'lodash';
import Lottie from 'lottie-react';
import { DateTime } from 'luxon';
import {
  Actionsheet,
  Badge,
  Box,
  Button,
  CheckIcon,
  ChevronDownIcon,
  Divider,
  HStack,
  IconButton,
  Image,
  Modal,
  Pressable,
  SectionList,
  Text,
  createIcon,
  useBreakpointValue,
  useDisclose,
  useToast,
} from 'native-base';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ViewToken } from 'react-native-web';
import { useLocation, useNavigate } from 'react-router-dom';

import { Listings, Orders, Rewards, Sellers } from '@waffle/common/src/models';
import { MoneyUtil } from '@waffle/common/src/util/money/MoneyUtil';

import WaffleErrorComponent from '../../components/WaffleErrorComponent';
import WaffleLoaderComponent from '../../components/WaffleLoaderComponent';
import ApiService from '../../utils/ApiService';
import {
  orderAtom,
  useCustomerQuery,
  useExpandedCategoriesQuery,
  useIssuedRewardsQuery,
  useRewardsListingsQuery,
  useRewardsMembershipQuery,
  useRewardsProgrammeQuery,
  useSelectedLocation,
  useSubdomainSellerQuery,
} from '../../utils/store';
import OrderLineItemEditModal from './OrderLineItemEditModal';
import { sectionListGetItemLayout } from './rn-section-list-get-item-layout';

type Section = {
  data: Listings.Item[];
  sectionName: string;
  index: number;
};

const SlPresentIcon = createIcon({
  viewBox: '0 0 1024 1024',
  d: 'M1024 320.496c0-35.344-28.654-64-63.998-64H850.754c28.272-27.888 46.368-64.447 46.368-109.472 0-55.44-31.84-115.664-121.216-115.664-117.6 0-215.84 125.216-262 195.408-46.192-70.176-147.44-195.392-265.024-195.392-89.376 0-121.216 60.224-121.216 115.664 0 45.008 18.592 81.584 47.44 109.472H64.002c-35.344 0-64 28.656-64 64V512.08h64.56v416.56c0 35.344 28.655 64 64 64h767.68c35.343 0 64-28.656 64-64V512.064h63.76V320.496zM775.906 95.376c39.568 0 57.216 16.625 57.216 51.665 0 71.088-79.344 109.439-153.968 109.439H570.818c45.471-67.536 125.504-161.104 205.088-161.104zm-527.025.001c79.6 0 162.655 93.568 208.127 161.088H348.64c-74.624 0-156.976-39.344-156.976-110.432 0-35.024 17.648-50.656 57.217-50.656zm711.12 352.687h-416V320.496h416v127.568zm-896-127.568h416v127.568h-416zm64.56 191.568h351.44v416.56h-351.44zm767.696 416.56H544.001v-416.56h352.256v416.56z',
});

const CheckoutPage = () => {
  const navigate = useNavigate();

  const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();
  const { data: categories, isError: isCategoriesError } =
    useExpandedCategoriesQuery();
  const [order, setOrder] = useAtom(orderAtom);

  const location = useLocation();
  const selectedOrderLineItem: Orders.OrderLineItem | undefined =
    location.state?.selectedOrderLineItem;
  const isRewardsModalOpen: boolean | undefined =
    location.state?.isRewardsModalOpen;

  const { isOpen, onOpen, onClose } = useDisclose(); // For select category actionsheet
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);
  const sectionListRef = useRef<typeof SectionList>(null);
  const sections: Section[] = useMemo(() => {
    return _(categories)
      .map((category, index) => {
        return {
          data: category.items,
          sectionName: category.name,
          index: index,
        };
      })
      .value();
  }, [categories]);

  const { data: customer } = useCustomerQuery();

  if (isSellerError || isCategoriesError) {
    return <WaffleErrorComponent />;
  }

  if (!seller || !categories) {
    return <WaffleLoaderComponent />;
  }

  if (sections.length === 0) {
    return (
      <Text>This page is under construction, please check back later!</Text>
    );
  }

  return (
    <>
      <Box flex={1}>
        {/*Spacer for fixed header*/}
        <Box height={20} />
        {/* Category selection */}
        <Box
          position={'fixed'}
          top={0}
          zIndex={1}
          justifyContent={'center'}
          alignItems={'center'}
          height={20}
          width={'100%'}
          backgroundColor={'background.0'}>
          <HStack
            alignItems={'center'}
            height={'100%'}
            width={'100%'}
            maxWidth={'600px'}
            padding={4}>
            <Pressable
              flex={1}
              flexDirection={'row'}
              alignItems={'center'}
              paddingY={2}
              paddingX={4}
              backgroundColor={'onSurface.100'}
              borderRadius={'full'}
              maxWidth={'100%'}
              onPress={onOpen}
              _pressed={{ backgroundColor: 'onSurface.200' }}>
              <Text
                flex={1}
                variant={'label'}
                numberOfLines={1}
                backgroundColor={'green.300'}>
                {sections[selectedCategoryIndex].sectionName}
              </Text>
              <ChevronDownIcon size={6} color={'text.500'} marginLeft={2} />
            </Pressable>

            {!!customer?.rewardsMembership && (
              <IconButton
                borderRadius={'full'}
                marginLeft={2}
                colorScheme={'violet'}
                icon={<SlPresentIcon size={6} color={'violet.600'} />}
                onPress={() => {
                  navigate('/checkout/rewards', {
                    state: { isRewardsModalOpen: true },
                  });
                }}
              />
            )}
          </HStack>
        </Box>

        <ItemsList
          sectionListRef={sectionListRef}
          sections={sections}
          setSelectedCategoryIndex={setSelectedCategoryIndex}
        />

        {order.lineItems.length > 0 && (
          <>
            {/*Spacer for footer*/}
            <Box height={20} />
            {/*Footer*/}
            <Box
              position={'fixed'}
              bottom={0}
              height={20}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              backgroundColor={'background.0'}>
              <Box height={'100%'} width={'100%'} maxWidth={600} padding={4}>
                <Button
                  size={'lg'}
                  flex={1}
                  onPress={() => navigate('/checkout/review')}
                  leftIcon={
                    <Badge
                      backgroundColor={'white'}
                      _text={{ color: 'blue.600' }}>
                      {_(order.lineItems).sumBy(
                        (orderLineItem) => orderLineItem.quantity,
                      )}
                    </Badge>
                  }>
                  {`Go to cart (${MoneyUtil.formatCurrency({ amount: order.subtotalAmount, currencyCode: seller.defaultCurrencyCode })})`}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>

      {!!selectedOrderLineItem && (
        <OrderLineItemEditModal
          orderLineItem={selectedOrderLineItem}
          onSave={(orderLineItem: Orders.OrderLineItem) => {
            setOrder(Orders.Order.upsertOrderLineItem(order, orderLineItem));
            navigate(-1);
          }}
          onClose={() => navigate(-1)}
        />
      )}

      {!!isRewardsModalOpen && (
        <RewardsModal
          onClose={() => {
            navigate(-1);
          }}
        />
      )}

      {/* Category selection pop up actionsheet */}
      <Actionsheet isOpen={isOpen} onClose={onClose} safeArea={true}>
        <Actionsheet.Content overflowY={'scroll'}>
          {sections.map((section: Section, index) => (
            <Pressable
              key={section.data[0].categoryId}
              width={'100%'}
              flexDirection={'row'}
              alignItems={'center'}
              paddingY={3}
              _pressed={{ backgroundColor: 'background.100' }}
              onPress={() => {
                sectionListRef.current?.scrollToLocation({
                  animated: true,
                  itemIndex: 0,
                  sectionIndex: index,
                });
                onClose();
              }}>
              <Box width={8}>
                {selectedCategoryIndex === index ? (
                  <CheckIcon size={4} />
                ) : (
                  <Box width={4} />
                )}
              </Box>
              <Text numberOfLines={1} variant={'label'}>
                {section.sectionName}
              </Text>
            </Pressable>
          ))}
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
};

/**
 * Extract this component out for memoization
 */
export const ItemsList = React.memo(
  ({
    sectionListRef,
    sections,
    setSelectedCategoryIndex,
  }: {
    sectionListRef: MutableRefObject<typeof SectionList>;
    sections: Section[];
    setSelectedCategoryIndex: Dispatch<SetStateAction<number>>;
  }) => {
    const navigate = useNavigate();
    const [order] = useAtom(orderAtom);
    const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();
    const selectedLocation: Sellers.Location = useSelectedLocation();
    const onViewableItemsChanged = (info: {
      viewableItems: Array<ViewToken>;
      changed: Array<ViewToken>;
    }) => {
      if (info.viewableItems[0]?.section) {
        setSelectedCategoryIndex(info.viewableItems[0]?.section.index);
      }
    };

    const renderSectionHeader = ({ section }: { section: Section }) => (
      <Box height={'40px'} justifyContent={'flex-end'}>
        <Text variant={'subHeader'} marginBottom={2} numberOfLines={1}>
          {section.sectionName}
        </Text>
      </Box>
    );

    const renderSectionFooter = () => {
      return <Box height={'16px'} width={'100%'} />;
    };

    const renderItem = ({ item }: { item: Listings.Item }) => {
      const sellableVariations: Listings.ItemVariation[] =
        item.variations.filter(
          (itemVariation) =>
            !itemVariation.soldOutAtLocationIds.find(
              (locationId) => locationId === selectedLocation.id,
            ),
        );
      const isSellable: boolean =
        // Item is sellable at this location
        !item.soldOutAtLocationIds.find(
          (locationId) => locationId === selectedLocation.id,
        ) && sellableVariations.length >= 1;

      const checkoutQuantity: number = _(order.lineItems)
        .filter((orderLineItem) => orderLineItem.itemId === item.id)
        .sumBy((orderLineItem) => orderLineItem.quantity);

      return (
        <Pressable
          key={item.id}
          flexDirection={'row'}
          height={'132px'}
          paddingY={'16px'}
          _hover={{ backgroundColor: 'background.50' }}
          opacity={isSellable ? 1 : 0.25}
          onPress={() => {
            if (!isSellable) {
              return;
            }
            navigate('/checkout', {
              state: {
                selectedOrderLineItem: Orders.OrderLineItem.fromListingItem({
                  listingItem: item,
                  listingItemVariation: sellableVariations[0],
                  listingCategory: item.category,
                }),
              },
            });
          }}>
          {!!item.image && (
            <Image
              src={item.image.thumbnailUrl}
              resizeMode={'contain'}
              alt={item.name + ' thumbnail image'}
              height={'100px'}
              width={'100px'}
              borderRadius={5}
              marginRight={4}
            />
          )}
          <Box flex={1}>
            <HStack alignItems={'baseline'}>
              {checkoutQuantity > 0 && (
                <Text color={'primary.600'} fontSize={14} fontWeight={'medium'}>
                  {`${checkoutQuantity}x `}
                </Text>
              )}
              <Text>{item.name}</Text>
            </HStack>

            {!!item.description && (
              <Text
                variant={'subText'}
                marginTop={1}
                numberOfLines={2}
                ellipsizeMode={'tail'}>
                {item.description}
              </Text>
            )}

            <Text variant={'label'} marginTop={2}>
              {item.variations.length === 1
                ? MoneyUtil.formatCurrency({
                    amount: item.variations[0].price,
                    currencyCode: seller.defaultCurrencyCode,
                  })
                : `${MoneyUtil.formatCurrency({
                    amount:
                      _.minBy(
                        item.variations,
                        (itemVariation: Listings.ItemVariation) =>
                          itemVariation.price,
                      )?.price ?? 0,
                    currencyCode: seller.defaultCurrencyCode,
                  })} - ${MoneyUtil.formatCurrency({
                    amount:
                      _.maxBy(
                        item.variations,
                        (itemVariation: Listings.ItemVariation) =>
                          itemVariation.price,
                      )?.price ?? 0,
                    currencyCode: seller.defaultCurrencyCode,
                  })}`}
            </Text>
          </Box>
        </Pressable>
      );
    };

    const getItemLayout = useMemo(
      () =>
        sectionListGetItemLayout({
          getItemHeight: (rowData, sectionIndex, rowIndex) => 132, // The height of the row with rowData at the given sectionIndex and rowIndex
          getSeparatorHeight: () => 1, // The height of your separators
          getSectionHeaderHeight: () => 40, // The height of your section headers
          getSectionFooterHeight: () => 16, // The height of your section footers
        }),
      [],
    );

    if (isSellerError) {
      return <WaffleErrorComponent />;
    }

    if (!seller) {
      return <WaffleLoaderComponent />;
    }

    return (
      <SectionList
        ref={sectionListRef}
        flex={1}
        sections={sections}
        initialNumToRender={sections.length}
        onViewableItemsChanged={onViewableItemsChanged}
        renderSectionHeader={renderSectionHeader}
        renderSectionFooter={renderSectionFooter}
        renderItem={renderItem}
        getItemLayout={getItemLayout}
        // Need to put the style directly into the SectionList because somehow onViewableItemsChanged doesn't get fired if we wrap SectionList in another div
        contentContainerStyle={{
          marginHorizontal: 'auto',
          backgroundColor: 'white',
          padding: 16,
          minHeight: '100%',
          width: '100%',
          maxWidth: 600,
        }}
        ItemSeparatorComponent={() => <Divider />}
        showsVerticalScrollIndicator={false}
      />
    );
  },
);

enum RewardsSections {
  YOUR_REWARDS,
  SHOP_FOR_REWARDS,
}

const RewardsModal = ({ onClose }: { onClose: () => void }) => {
  const isLandscape: boolean = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [selectedSection, setSelectedSection] = useState<RewardsSections>(
    RewardsSections.SHOP_FOR_REWARDS,
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size={isLandscape ? 'xl' : 'full'}
      maxHeight={'100vh'}
      safeArea={true}
      avoidKeyboard={true}>
      <Modal.Content
        minHeight={isLandscape ? 0 : '100%'}
        borderRadius={isLandscape ? 8 : 0}>
        <Modal.CloseButton />
        <Modal.Header>
          <Text variant={'subHeader'} marginBottom={2}>
            {selectedSection === RewardsSections.SHOP_FOR_REWARDS
              ? 'Shop for Rewards'
              : 'Your Rewards'}
          </Text>
          <HStack>
            <Button.Group
              isAttached={true}
              borderRadius={'full'}
              backgroundColor={'onSurface.100'}>
              <Button
                colorScheme={
                  selectedSection === RewardsSections.SHOP_FOR_REWARDS
                    ? 'primary'
                    : 'onSurface'
                }
                variant={
                  selectedSection === RewardsSections.SHOP_FOR_REWARDS
                    ? 'solid'
                    : 'ghost'
                }
                borderRadius={'full'}
                onPress={() => {
                  setSelectedSection(RewardsSections.SHOP_FOR_REWARDS);
                }}>
                Shop for Rewards
              </Button>
              <Button
                colorScheme={
                  selectedSection === RewardsSections.YOUR_REWARDS
                    ? 'primary'
                    : 'onSurface'
                }
                variant={
                  selectedSection === RewardsSections.YOUR_REWARDS
                    ? 'solid'
                    : 'ghost'
                }
                borderRadius={'full'}
                onPress={() => {
                  setSelectedSection(RewardsSections.YOUR_REWARDS);
                }}>
                Your Rewards
              </Button>
            </Button.Group>
          </HStack>
        </Modal.Header>
        {selectedSection === RewardsSections.SHOP_FOR_REWARDS ? (
          <ShopForRewards />
        ) : selectedSection === RewardsSections.YOUR_REWARDS ? (
          <YourRewards
            goToShopForRewards={() => {
              setSelectedSection(RewardsSections.SHOP_FOR_REWARDS);
            }}
          />
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

const MdRadioButtonUnchecked = createIcon({
  viewBox: '0 0 24 24',
  d: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
});

const MdRadioButtonChecked = createIcon({
  viewBox: '0 0 24 24',
  d: 'M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z',
});

export const ShopForRewards = () => {
  const { data: seller } = useSubdomainSellerQuery();
  const toast = useToast();
  const [order, setOrder] = useAtom(orderAtom);
  const rewardInOrder: Orders.OrderLineItem | Orders.OrderDiscount | undefined =
    useMemo(() => {
      return (
        order.lineItems.find(
          (orderLineItem: Orders.OrderLineItem) =>
            !!orderLineItem.rewardsItemId,
        ) ??
        order.discounts.find(
          (orderDiscount: Orders.OrderDiscount) =>
            !!orderDiscount.rewardsDiscountId,
        )
      );
    }, [order]);

  const { data: customer } = useCustomerQuery();
  const { data: rewardsProgramme } = useRewardsProgrammeQuery();
  const { data: rewardsMembership } = useRewardsMembershipQuery();
  const location = useSelectedLocation();

  const { data: rewardsListings, isError: isRewardsListingsError } =
    useRewardsListingsQuery();

  /**
   * Only RewardsListings targeted for this Customer's CustomerSet should be visible (whether it's claimable or not)
   * TODO: Shift this filter to the server side
   */
  const visibleRewardsListings: Rewards.RewardsListing[] = useMemo(() => {
    if (!rewardsListings || !customer?.customerSets) {
      return [];
    }

    const customerSetIds = customer.customerSets.map(
      (customerSet) => customerSet.id,
    );
    return rewardsListings.filter(
      (rewardsListing: Rewards.RewardsListing) =>
        rewardsListing.isIssuableToAllCustomers ||
        rewardsListing.customerSetIds.some((id) => customerSetIds.includes(id)),
    );
  }, [rewardsListings, customer]);

  const claimableRewardsListings: Rewards.RewardsListing[] = useMemo(() => {
    if (!visibleRewardsListings || !rewardsMembership || !location) {
      return [];
    }

    return (
      _(visibleRewardsListings)
        // Filter points
        .filter(
          (rewardsListing: Rewards.RewardsListing) =>
            rewardsMembership.currentPoints >= rewardsListing.pointsRequired,
        )
        // Filter location
        .filter(
          (rewardsListing: Rewards.RewardsListing) =>
            rewardsListing.presentAtAllLocations ||
            rewardsListing.sellerLocationIds.includes(location.id),
        )
        // Order by points (ascending)
        .orderBy(
          (rewardsListing: Rewards.RewardsListing) =>
            rewardsListing.pointsRequired,
        )
        .value()
    );
  }, [visibleRewardsListings, rewardsMembership, location]);

  const unclaimableRewardsListings: Rewards.RewardsListing[] = useMemo(() => {
    if (!visibleRewardsListings || !claimableRewardsListings) {
      return [];
    }

    return visibleRewardsListings.filter(
      (rewardsListing) =>
        !claimableRewardsListings.find(
          (claimableRewardsListing) =>
            claimableRewardsListing.id === rewardsListing.id,
        ),
    );
  }, [visibleRewardsListings, claimableRewardsListings]);

  const [selectedRewardsListing, setSelectedRewardsListing] = useState<
    Rewards.RewardsListing | undefined
  >(undefined);

  const { mutate: issueReward, isPending: isIssueRewardPending } = useMutation({
    mutationFn: async (rewardListingToIssue: Rewards.RewardsListing) => {
      const { issuedReward } = await ApiService.request({
        method: 'POST',
        url: `/rewards_programmes/${rewardsProgramme.id}/rewards_listings/${rewardListingToIssue.id}/issue/`,
        data: {
          rewardsMembershipId: rewardsMembership.id,
        },
      });
      return issuedReward as Rewards.IssuedReward;
    },
    onSuccess: (issuedReward: Rewards.IssuedReward) => {
      switch (issuedReward.listingSnapshot.rewardsListingType) {
        case Rewards.ListingType.ITEM: {
          const orderLineItem: Orders.OrderLineItem =
            Orders.OrderLineItem.fromIssuedRewardItem({
              rewardsItem: issuedReward.listingSnapshot,
              issuedRewardId: issuedReward.id,
            });
          setOrder((order) =>
            Orders.Order.upsertOrderLineItem(order, orderLineItem),
          );
          break;
        }
        case Rewards.ListingType.DISCOUNT: {
          const orderDiscount: Orders.OrderDiscount =
            Orders.OrderDiscount.fromIssuedRewardDiscount({
              rewardsDiscount: issuedReward.listingSnapshot,
              issuedRewardId: issuedReward.id,
            });
          setOrder((order) =>
            Orders.Order.upsertOrderDiscount(order, orderDiscount),
          );
          break;
        }
        default: {
          // Do nothing
          break;
        }
      }

      toast.show({
        status: 'success',
        title: 'Successfully redeemed reward!',
      });
    },
    onError: () => {
      toast.show({
        status: 'error',
        title: 'Failed to redeem reward',
        description: 'Please try again later',
      });
    },
  });

  if (isRewardsListingsError) {
    return <WaffleErrorComponent />;
  }

  if (!rewardsListings) {
    return <WaffleLoaderComponent />;
  }

  if (!!rewardInOrder) {
    return (
      <Modal.Body>
        <Box
          borderWidth={1}
          borderColor={'onSurface.200'}
          justifyContent={'center'}
          alignItems={'center'}
          padding={4}
          borderRadius={'lg'}>
          <Lottie
            animationData={require('../../assets/lottie/128316-redeem-voucher.json')}
            size={24}
            loop={1}
            initialSegment={[25, 56]}
          />
          <Text>You have redeemed</Text>
          <Text variant={'subHeader'}>{rewardInOrder.name}</Text>
        </Box>
      </Modal.Body>
    );
  }

  if (rewardsListings.length === 0) {
    return (
      <Modal.Body>
        <Box
          borderWidth={1}
          borderColor={'onSurface.200'}
          justifyContent={'center'}
          alignItems={'center'}
          padding={4}
          borderRadius={'lg'}>
          <Lottie
            animationData={require('../../assets/lottie/128316-redeem-voucher.json')}
            size={24}
            loop={true}
            initialSegment={[0, 14]}
          />
          <Text variant={'label'}>Stay tuned for more Rewards!</Text>
        </Box>
      </Modal.Body>
    );
  }

  return (
    <>
      <Modal.Body>
        <Box marginBottom={4}>
          <HStack alignItems={'baseline'}>
            <Text variant={'subHeader'} color={'primary.600'}>
              {rewardsMembership.currentPoints}{' '}
            </Text>
            <Text variant={'label'}>{rewardsProgramme.pointName}</Text>
          </HStack>
        </Box>

        <Box>
          {claimableRewardsListings.map(
            (rewardsListing: Rewards.RewardsListing) => (
              <Pressable
                key={rewardsListing.id}
                padding={4}
                borderWidth={1}
                borderColor={
                  selectedRewardsListing?.id === rewardsListing.id
                    ? 'primary.400'
                    : 'onSurface.200'
                }
                borderRadius={'lg'}
                marginY={1}
                _pressed={{ backgroundColor: 'background.100' }}
                onPress={() => {
                  if (selectedRewardsListing?.id === rewardsListing.id) {
                    setSelectedRewardsListing(undefined);
                  } else {
                    setSelectedRewardsListing(rewardsListing);
                  }
                }}>
                <HStack>
                  <Box flex={1}>
                    <Box marginBottom={2}>
                      <Text>{rewardsListing.name}</Text>
                      {!!rewardsListing.description && (
                        <Text variant={'subText'}>
                          {rewardsListing.description}
                        </Text>
                      )}
                    </Box>
                    <Text variant={'label'}>
                      {rewardsListing.pointsRequired}{' '}
                      {seller?.rewardsProgramme.pointName}
                    </Text>
                  </Box>
                  <Box>
                    {selectedRewardsListing?.id === rewardsListing.id ? (
                      <MdRadioButtonChecked size={6} color={'primary.600'} />
                    ) : (
                      <MdRadioButtonUnchecked
                        size={6}
                        color={'onSurface.300'}
                      />
                    )}
                  </Box>
                </HStack>
              </Pressable>
            ),
          )}
        </Box>
        <Box>
          {unclaimableRewardsListings.map(
            (rewardsListing: Rewards.RewardsListing) => (
              <Pressable
                key={rewardsListing.id}
                padding={4}
                borderWidth={1}
                borderColor={
                  selectedRewardsListing?.id === rewardsListing.id
                    ? 'primary.400'
                    : 'onSurface.200'
                }
                borderRadius={'lg'}
                marginY={1}
                _pressed={{ backgroundColor: 'background.100' }}
                isDisabled={true}
                _disabled={{ opacity: 0.5 }}
                onPress={() => {
                  if (selectedRewardsListing?.id === rewardsListing.id) {
                    setSelectedRewardsListing(undefined);
                  } else {
                    setSelectedRewardsListing(rewardsListing);
                  }
                }}>
                <HStack>
                  <Box flex={1}>
                    <Box marginBottom={2}>
                      <Text>{rewardsListing.name}</Text>
                      {!!rewardsListing.description && (
                        <Text variant={'subText'}>
                          {rewardsListing.description}
                        </Text>
                      )}
                    </Box>
                    <Text variant={'label'}>
                      {rewardsListing.pointsRequired}{' '}
                      {seller?.rewardsProgramme.pointName}
                    </Text>
                  </Box>
                  <Box>
                    {selectedRewardsListing?.id === rewardsListing.id ? (
                      <MdRadioButtonChecked size={6} color={'primary.600'} />
                    ) : (
                      <MdRadioButtonUnchecked
                        size={6}
                        color={'onSurface.300'}
                      />
                    )}
                  </Box>
                </HStack>
              </Pressable>
            ),
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          flex={1}
          isLoading={isIssueRewardPending}
          isDisabled={!selectedRewardsListing}
          onPress={() => {
            if (!selectedRewardsListing) {
              return;
            }
            issueReward(selectedRewardsListing);
          }}>
          {!selectedRewardsListing
            ? 'Redeem'
            : `Redeem for ${selectedRewardsListing.pointsRequired} ${rewardsProgramme.pointName}`}
        </Button>
      </Modal.Footer>
    </>
  );
};

export const YourRewards = ({
  goToShopForRewards,
}: {
  goToShopForRewards: () => void;
}) => {
  const selectedLocation = useSelectedLocation();
  const { data: issuedRewards, isError: isIssuedRewardsError } =
    useIssuedRewardsQuery();

  const [selectedIssuedReward, setSelectedIssuedReward] = useState<
    Rewards.IssuedReward | undefined
  >(undefined);
  const [order, setOrder] = useAtom(orderAtom);

  const redeemableIssuedRewards = useMemo(
    () =>
      _(issuedRewards)
        .filter(
          (issuedReward) =>
            issuedReward.listingSnapshot.presentAtAllLocations ||
            issuedReward.listingSnapshot.sellerLocationIds.includes(
              selectedLocation.id,
            ),
        )
        // NOTE: We don't filter by customerSetIds here, if the reward has been issued to the Customer, they should be able to redeem it
        // Order by issuedAt (TODO: More correct to orderBy expiresAt instead?)
        .orderBy((issuedReward) => issuedReward.issuedAt, 'desc')
        .value(),
    [selectedLocation, issuedRewards],
  );

  const rewardInOrder: Orders.OrderLineItem | Orders.OrderDiscount | undefined =
    useMemo(() => {
      return (
        order.lineItems.find(
          (orderLineItem: Orders.OrderLineItem) =>
            !!orderLineItem.rewardsItemId,
        ) ??
        order.discounts.find(
          (orderDiscount: Orders.OrderDiscount) =>
            !!orderDiscount.rewardsDiscountId,
        )
      );
    }, [order]);

  const redeemReward = (issuedReward: Rewards.IssuedReward) => {
    switch (issuedReward.listingSnapshot.rewardsListingType) {
      case Rewards.ListingType.ITEM: {
        const orderLineItem: Orders.OrderLineItem =
          Orders.OrderLineItem.fromIssuedRewardItem({
            rewardsItem: issuedReward.listingSnapshot,
            issuedRewardId: issuedReward.id,
          });
        setOrder((order) =>
          Orders.Order.upsertOrderLineItem(order, orderLineItem),
        );
        break;
      }

      case Rewards.ListingType.DISCOUNT: {
        const orderDiscount: Orders.OrderDiscount =
          Orders.OrderDiscount.fromIssuedRewardDiscount({
            rewardsDiscount: issuedReward.listingSnapshot,
            issuedRewardId: issuedReward.id,
          });
        setOrder((order) =>
          Orders.Order.upsertOrderDiscount(order, orderDiscount),
        );
        break;
      }
    }
  };

  if (isIssuedRewardsError) {
    return <WaffleErrorComponent />;
  }

  if (!issuedRewards) {
    return <WaffleLoaderComponent />;
  }

  if (!!rewardInOrder) {
    return (
      <Modal.Body>
        <Box
          borderWidth={1}
          borderColor={'onSurface.200'}
          justifyContent={'center'}
          alignItems={'center'}
          padding={4}
          borderRadius={'lg'}>
          <Lottie
            animationData={require('../../assets/lottie/128316-redeem-voucher.json')}
            size={24}
            loop={1}
            initialSegment={[25, 56]}
          />
          <Text>You have redeemed</Text>
          <Text variant={'subHeader'}>{rewardInOrder.name}</Text>
        </Box>
      </Modal.Body>
    );
  }

  if (redeemableIssuedRewards.length === 0) {
    return (
      <>
        <Modal.Body>
          <Box alignItems={'center'} justifyContent={'center'}>
            <Lottie
              animationData={require('../../assets/lottie/128316-redeem-voucher.json')}
              size={24}
              loop={true}
              initialSegment={[0, 14]}
            />
            <Text>You currently do not own any rewards.</Text>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button flex={1} onPress={goToShopForRewards}>
            Shop for Rewards
          </Button>
        </Modal.Footer>
      </>
    );
  }

  return (
    <>
      <Modal.Body>
        {redeemableIssuedRewards.map((issuedReward: Rewards.IssuedReward) => (
          <Pressable
            key={issuedReward.id}
            padding={4}
            borderWidth={1}
            borderColor={
              selectedIssuedReward?.id === issuedReward.id
                ? 'primary.400'
                : 'onSurface.200'
            }
            borderRadius={'lg'}
            marginY={1}
            _pressed={{ backgroundColor: 'background.100' }}
            onPress={() => {
              if (selectedIssuedReward?.id === issuedReward.id) {
                setSelectedIssuedReward(undefined);
              } else {
                setSelectedIssuedReward(issuedReward);
              }
            }}>
            <HStack>
              <Box flex={1}>
                <Text>{issuedReward.listingSnapshot.name}</Text>
                {!!issuedReward.listingSnapshot.description && (
                  <Text variant={'subText'}>
                    {issuedReward.listingSnapshot.description}
                  </Text>
                )}
                {!!issuedReward.expiresAt && (
                  <Text variant={'subText'}>
                    Expires on{' '}
                    {DateTime.fromISO(issuedReward.expiresAt).toLocaleString(
                      DateTime.DATE_MED,
                    )}
                  </Text>
                )}
              </Box>
              <Box>
                {selectedIssuedReward?.id === issuedReward.id ? (
                  <MdRadioButtonChecked size={6} color={'primary.600'} />
                ) : (
                  <MdRadioButtonUnchecked size={6} color={'onSurface.300'} />
                )}
              </Box>
            </HStack>
          </Pressable>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          flex={1}
          isDisabled={!selectedIssuedReward}
          onPress={() => {
            if (!selectedIssuedReward) {
              return;
            }
            redeemReward(selectedIssuedReward);
          }}>
          Redeem
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CheckoutPage;
