import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as Common from '../common';
import * as Customers from '../customers';
import * as Orders from '../orders';
import * as Sellers from '../sellers';

// Declaration of enums
export enum SalePaymentType {
  CASH = 'CASH',
  CUSTOM_PAYMENT_METHOD = 'CUSTOM_PAYMENT_METHOD',
  INTEGRATED_PAYMENT = 'INTEGRATED_PAYMENT',
}

export enum SaleStatus {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED', // The sale has completed. This is a terminal state.
  CANCELLED = 'CANCELLED', // The order was cancelled. This is a terminal state.
}

export enum SaleType {
  SALE = 'SALE',
  RETURN = 'RETURN',
}

// We assume all currency SGD for now
// Sale Voucher
export type SaleVoucher = z.infer<typeof SaleVoucher.schema>;
export namespace SaleVoucher {
  export const _type = 'sales.sale_voucher';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    saleVoucherId: z.string().uuid().nullish(),
    voucherId: z.string().uuid().nullish(),
    name: z.string(),
    amount: z.number(),
    note: z.string().default(''),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<SaleVoucher>): SaleVoucher => {
    return schema.parse({
      ...args,
      id: args.saleVoucherId ?? uuidv4(),
      _type: _type,
    });
  };

  export const fromOrderTender = (
    orderTender: Orders.OrderTender,
  ): SaleVoucher => {
    return create({
      voucherId:
        (orderTender as Orders.OrderVoucherTender).voucherId ?? undefined,
      name: orderTender.name,

      amount: orderTender.amount,
      note: orderTender.note ?? '',

      createdAt: orderTender.createdAt,
    });
  };
}

// Sale Payment
export type SalePayment = z.infer<typeof SalePayment.schema>;
export namespace SalePayment {
  export const _type = 'sales.sale_payment';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    salePaymentId: z.string().uuid().nullish(), // TODO: Deprecate
    orderTenderId: z.string().uuid().nullish(),

    name: z.string(),
    type: z.nativeEnum(SalePaymentType), // TODO: Rename CUSTOM_PAYMENT_METHOD to CUSTOM_PAYMENT
    paymentMethodId: z.string().nullish(), // TODO: Rename to brand
    paymentMethodName: z.string(),
    // If type === 'CUSTOM_PAYMENT_METHOD'
    customPaymentMethodId: z.string().uuid().nullish(),
    // If type === 'INTEGRATED_PAYMENT'
    paymentId: z.string().uuid().nullish(),
    refundId: z.string().uuid().nullish(),
    amount: z.number(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<SalePayment>): SalePayment => {
    const id: string = args.salePaymentId ?? uuidv4();
    return schema.parse({
      ...args,
      id: id,
      _type: _type,
    });
  };

  export const fromOrderTender = (
    orderTender: Orders.OrderTender,
  ): SalePayment => {
    return create({
      orderTenderId: orderTender.id,
      name: orderTender.name,
      type:
        orderTender.type === Orders.OrderTenderType.CASH
          ? SalePaymentType.CASH
          : orderTender.type === Orders.OrderTenderType.CUSTOM_PAYMENT
            ? SalePaymentType.CUSTOM_PAYMENT_METHOD
            : SalePaymentType.INTEGRATED_PAYMENT,
      paymentMethodId: orderTender.brand,
      paymentMethodName: orderTender.name,
      amount: orderTender.amount,

      customPaymentMethodId:
        orderTender.type === Orders.OrderTenderType.CUSTOM_PAYMENT
          ? orderTender.customPaymentMethodId
          : undefined,

      paymentId:
        orderTender.type === Orders.OrderTenderType.INTEGRATED_PAYMENT &&
        !orderTender.integratedPaymentDetails.isRefund
          ? orderTender.integratedPaymentDetails.paymentId
          : undefined,
      refundId:
        orderTender.type === Orders.OrderTenderType.INTEGRATED_PAYMENT &&
        orderTender.integratedPaymentDetails.isRefund
          ? orderTender.integratedPaymentDetails.refundId
          : undefined,

      createdAt: orderTender.createdAt,
    });
  };
}

// Sale Tax
export type SaleTax = z.infer<typeof SaleTax.schema>;
export namespace SaleTax {
  export const _type = 'sales.sale_tax';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    saleTaxId: z.string().uuid().nullish(),
    name: z.string(),
    taxId: z.string().uuid().nullish(),
    percentValue: z.number(),
    amount: z.number().default(0),
    isLineItemPricingInclusive: z.boolean().default(true),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<SaleTax>): SaleTax => {
    const id: string = args.saleTaxId ?? uuidv4();
    return schema.parse({
      ...args,
      id: id,
      _type: _type,
    });
  };

  export const fromOrderTax = (orderTax: Orders.OrderTax): SaleTax => {
    return create({
      name: orderTax.name,
      taxId: orderTax.taxId,

      percentValue: orderTax.percentValue,
      amount: orderTax.amount,
      isLineItemPricingInclusive: orderTax.isLineItemPricingInclusive,

      createdAt: orderTax.createdAt,
    });
  };
}

// Sale Extra Charge
export type SaleExtraCharge = z.infer<typeof SaleExtraCharge.schema>;
export namespace SaleExtraCharge {
  export const _type = 'sales.sale_extra_charge';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    saleExtraChargeId: z.string().uuid().nullish(),
    name: z.string(),
    extraChargeId: z.string().uuid().nullish(),
    unit: z.nativeEnum(Common.ModifierUnit),
    dollarValue: z.number(),
    percentValue: z.number(),
    isLineItemPricingInclusive: z.boolean().default(true),
    amount: z.number().default(0),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<SaleExtraCharge>): SaleExtraCharge => {
    const id: string = args.saleExtraChargeId ?? uuidv4();

    return schema.parse({
      ...args,
      id: id,
      _type: _type,
    });
  };

  export const fromOrderExtraCharge = (
    orderExtraCharge: Orders.OrderExtraCharge,
  ): SaleExtraCharge => {
    return create({
      name: orderExtraCharge.name,
      extraChargeId: orderExtraCharge.extraChargeId,

      unit: orderExtraCharge.unit,
      dollarValue: orderExtraCharge.dollarValue ?? 0,
      percentValue: orderExtraCharge.percentValue ?? 0,
      isLineItemPricingInclusive: orderExtraCharge.isLineItemPricingInclusive,

      amount: orderExtraCharge.amount,

      createdAt: orderExtraCharge.createdAt,
    });
  };
}

// Sale Discount

export type SaleDiscount = z.infer<typeof SaleDiscount.schema>;
export namespace SaleDiscount {
  export const _type = 'sales.sale_discount';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    saleDiscountId: z.string().uuid().nullish(),

    name: z.string(),
    discountId: z.string().uuid().nullish(),
    rewardsDiscountId: z.string().uuid().nullish(),

    unit: z.nativeEnum(Common.ModifierUnit),
    dollarValue: z.number(),
    percentValue: z.number(),
    // Currently only used for Item discounts
    shouldApplyToAllAddOns: z.boolean().default(true),

    amount: z.number().default(0),

    issuedRewardId: z.string().uuid().nullish(),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<SaleDiscount>): SaleDiscount => {
    const id: string = args.saleDiscountId ?? uuidv4();

    return schema.parse({
      ...args,
      _type: _type,
      id: id,
    });
  };

  export const fromOrderDiscount = (
    orderDiscount: Orders.OrderDiscount,
  ): SaleDiscount => {
    return create({
      name: orderDiscount.name,
      discountId: orderDiscount.discountId ?? undefined,
      rewardsDiscountId: orderDiscount.rewardsDiscountId ?? undefined,

      unit: orderDiscount.unit,
      dollarValue: orderDiscount.dollarValue,
      percentValue: orderDiscount.percentValue,
      // Currently only used for Item discounts
      shouldApplyToAllAddOns: orderDiscount.shouldApplyToAllAddOns,

      amount: orderDiscount.amount,

      issuedRewardId: orderDiscount.issuedRewardId ?? undefined,

      createdAt: orderDiscount.createdAt,
    });
  };
}

// Sale Add On
export type SaleAddOn = z.infer<typeof SaleAddOn.schema>;
export namespace SaleAddOn {
  export const _type = 'sales.sale_add_on';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),

    id: z.string().uuid(),
    saleAddOnId: z.string().uuid().nullish(),

    addOnId: z.string().uuid().nullish(),
    name: z.string(),
    addOnSetName: z.string(),
    addOnSetId: z.string().uuid(),

    quantity: z.number(),
    price: z.number(),

    // Calculated as quantity * price
    quantityPrice: z.number().default(0),
    // The total quantity of this addOn ordered
    // Calculated as Math.abs(SaleItem.quantity) * saleAddOn.quantity
    totalQuantity: z.number().default(0),
    // The total amount collected from this add on for this line item
    // Calculated as Math.abs(SaleItem.quantity) * quantityPrice
    // Or SaleAddOn.totalQuantity * SaleAddOn.price
    // Or Math.abs(SaleItem.quantity) * SaleAddOn.quantity  * SaleAddOn.price
    totalAmount: z.number().default(0),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<SaleAddOn>): SaleAddOn => {
    const id: string = args.saleAddOnId ?? uuidv4();

    return schema.parse({
      ...args,
      _type: _type,
      id: id,
    });
  };

  export const fromOrderAddOn = (orderAddOn: Orders.OrderAddOn): SaleAddOn => {
    return create({
      addOnId: orderAddOn.addOnId,
      name: orderAddOn.name,
      addOnSetName: orderAddOn.addOnSetName,
      addOnSetId: orderAddOn.addOnSetId,

      quantity: orderAddOn.quantity,
      price: orderAddOn.price,

      quantityPrice: orderAddOn.quantityPrice,
      totalQuantity: orderAddOn.totalQuantity,
      totalAmount: orderAddOn.totalAmount,

      createdAt: orderAddOn.createdAt,
    });
  };
}

// Sale Item

// Applied Discount
export type AppliedDiscount = z.infer<typeof AppliedDiscount.schema>;
export namespace AppliedDiscount {
  export const schema = z.object({
    // Id of the SaleDiscount in this Sale
    id: z.string(),
    amount: z.number(),
  });

  export const create = (args: Partial<AppliedDiscount>) => {
    return schema.parse(args);
  };
}

export type AppliedExtraCharge = z.infer<typeof AppliedExtraCharge.schema>;
export namespace AppliedExtraCharge {
  export const schema = z.object({
    // Id of the SaleDiscount in this Sale
    id: z.string(),
    amount: z.number(),
  });

  export const create = (args: Partial<AppliedExtraCharge>) => {
    return schema.parse(args);
  };
}

export type AppliedTax = z.infer<typeof AppliedTax.schema>;
export namespace AppliedTax {
  export const schema = z.object({
    // Id of the SaleDiscount in this Sale
    id: z.string(),
    amount: z.number(),
  });

  export const create = (args: Partial<AppliedTax>) => {
    return schema.parse(args);
  };
}

export type SaleItem = z.infer<typeof SaleItem.schema>;
export namespace SaleItem {
  export const _type = 'sales.sale_item';

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    saleItemId: z.string().uuid().nullish(),

    name: z.string(),
    itemId: z.string().uuid().nullish(),
    rewardsItemId: z.string().uuid().nullish(),
    categoryName: z.string(),
    categoryId: z.string().nullish(),
    selectedItemVariationName: z.string(),
    itemVariationId: z.string().uuid(),

    quantity: z.number().default(1),
    sku: z.string().nullish(),
    shouldTrackInventory: z.boolean(),
    shouldRestock: z.boolean(),
    price: z.number(),

    addOns: z.array(SaleAddOn.schema).default([]),
    discounts: z.array(SaleDiscount.schema).default([]),
    note: z.string().nullish(),

    // TODO: Deprecate
    appliedDiscounts: z.array(AppliedDiscount.schema).default([]),
    appliedExtraCharges: z.array(AppliedExtraCharge.schema).default([]),
    appliedTaxes: z.array(AppliedTax.schema).default([]),

    // The amount to collect as a result of the item variant. Does not include addons.
    // Calculated as SaleItem.quantity * SaleItem.price.
    quantityPrice: z.number().default(0),
    // The amount of money made in gross sales for this line item. Calculated as `SaleItem.quantityPrice + SUM(SaleAddOn.totalAmount)`
    grossAmount: z.number().default(0),
    // The total discounts amount applied to this SaleItem
    discountsAmount: z.number().default(0),
    // The total extra charges amount (inclusive & exclusive) applied to this SaleItem
    extraChargesAmount: z.number().default(0),
    // The total tax amount (inclusive & exclusive) applied to this SaleItem
    taxesAmount: z.number().default(0),
    // The total amount to be collected for this line item
    totalAmount: z.number().default(0),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),

    issuedRewardId: z.string().uuid().nullish(),
  });

  export const create = (args: Partial<SaleItem>): SaleItem => {
    const id: string = args.saleItemId ?? uuidv4();
    return schema.parse({
      ...args,
      id: id,
      _type: _type,
      addOns: args.addOns?.map(SaleAddOn.create),
      discounts: args.discounts?.map(SaleDiscount.create),

      appliedDiscounts: args.appliedDiscounts?.map(AppliedDiscount.create),
      appliedExtraCharges: args.appliedExtraCharges?.map(
        AppliedExtraCharge.create,
      ),
      appliedTaxes: args.appliedTaxes?.map(AppliedTax.create),
    });
  };

  export const fromOrderLineItem = (
    orderLineItem: Orders.OrderLineItem,
  ): SaleItem => {
    return create({
      name: orderLineItem.name,
      itemId: orderLineItem.itemId ?? null,
      rewardsItemId: orderLineItem.rewardsItemId ?? null,
      categoryName: orderLineItem.categoryName,
      categoryId: orderLineItem.categoryId ?? null,
      selectedItemVariationName: orderLineItem.selectedItemVariationName,
      itemVariationId: orderLineItem.itemVariationId,

      quantity: orderLineItem.quantity,
      sku: orderLineItem.sku ?? null,
      shouldTrackInventory: orderLineItem.shouldTrackInventory,
      shouldRestock: orderLineItem.shouldRestock,
      price: orderLineItem.price,

      addOns: orderLineItem.addOns.map(SaleAddOn.fromOrderAddOn),
      discounts: orderLineItem.discounts.map(SaleDiscount.fromOrderDiscount),
      note: orderLineItem.note ?? null,

      quantityPrice: orderLineItem.quantityPrice,
      grossAmount: orderLineItem.grossAmount,
      discountsAmount: orderLineItem.discountsAmount,
      extraChargesAmount: orderLineItem.extraChargesAmount,
      taxesAmount: orderLineItem.taxesAmount,
      totalAmount: orderLineItem.totalAmount,

      createdAt: orderLineItem.createdAt,

      issuedRewardId: orderLineItem.issuedRewardId ?? undefined,
    });
  };
}

// Sale
export type Sale = z.infer<typeof Sale.schema> & {
  customer?: Customers.Customer;
  location?: Sellers.Location;
};
export namespace Sale {
  export const _type = 'sales.sale' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    saleId: z.string().uuid().nullish(),
    // Only populated if this sale is a return -- Contains the original sale
    // TODO: Deprecate, Sale doesn't need to know which order it was a return for since Sale is just a historical record of all numbers processed. If you need to know the ReturnOrder, use Order instead.
    returnForId: z.string().uuid().nullish(),
    orderId: z.string().uuid().nullish(),
    returnForOrderId: z.string().uuid().nullish(),
    sellerId: z.string().uuid(),
    sellerLocationId: z.string().uuid(),
    sellerDeviceId: z.string().uuid().nullish(),
    // Running sequence for sales, unique for each device. If there is no device associated with this sale, saleNumber will be undefined
    saleNumber: z.number().nullish(),
    // Arbitrary string generated by clients to uniquely identify this sale
    referenceId: z.string().nullish(),
    saleStatus: z.nativeEnum(SaleStatus).default(SaleStatus.OPEN), // TODO: Deprecate this field, all Sales are Orders that have been COMPLETED
    note: z.string().nullish().optional(),
    saleType: z.nativeEnum(SaleType).default(SaleType.SALE), // TODO: Use this as key for discriminated union instead of being a computed field
    receiptType: z.nativeEnum(Orders.ReceiptType).nullish(),
    gatewaySource: z.nativeEnum(Orders.OrderGatewaySource).nullish(), // TODO: Remove nullish
    appSource: z.nativeEnum(Orders.OrderAppSource).nullish(), // TODO: Remove nullish

    orderTypeId: z.string().nullish(),
    orderTypeName: z.string().nullish(),
    ticketType: z.string().nullish(),
    ticketName: z.string().nullish(),
    ticketValue: z.string().nullish(),

    saleItems: z.array(SaleItem.schema).default([]),
    saleDiscounts: z.array(SaleDiscount.schema).default([]),
    saleExtraCharges: z.array(SaleExtraCharge.schema).default([]),
    saleTaxes: z.array(SaleTax.schema).default([]),
    salePayments: z.array(SalePayment.schema).default([]),
    saleVouchers: z.array(SaleVoucher.schema).default([]),

    // Sum of all line item gross amounts
    grossAmount: z.number().default(0),
    // Sum of all SaleDiscount amounts
    discountsAmount: z.number().default(0),
    // Sum of all SaleExtraCharge amounts (inclusive & exclusive)
    extraChargesAmount: z.number().default(0),
    // Sum of all SaleTax amounts (inclusive & exclusive)
    taxesAmount: z.number().default(0),
    // Cash rounding for this sale
    cashRoundingAmount: z.number().default(0),
    // Eg. tipping / paying with a voucher that is larger than the amount payable
    excessCollectionAmount: z.number().default(0),
    // The total amount to be collected for this sale
    totalAmount: z.number().default(0),
    // Calculated as all items + addOns + itemDiscounts
    subtotalAmount: z.number().default(0),
    // Cost of all items + addOns before taxes and extra charges
    // Equal to (grossSalesAmount - any 'included' extra charges and taxes)
    grossSalesAmount: z.number().default(0),

    customerId: z.string().nullish(),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),

    /**
     * Expandable
     */
    // customer: Customers.Customer.schema.optional(),
    // location: Sellers.Location.schema.optional()
  });

  export const create = (args: Partial<Sale>): Sale => {
    const id: string = args.saleId ?? uuidv4();
    return schema.parse({
      ...args,
      id: id,
      _type: _type,
      saleItems: args.saleItems?.map(SaleItem.create),
      saleDiscounts: args.saleDiscounts?.map(SaleDiscount.create),
      saleExtraCharges: args.saleExtraCharges?.map(SaleExtraCharge.create),
      saleTaxes: args.saleTaxes?.map(SaleTax.create),
      salePayments: args.salePayments?.map(SalePayment.create),
      saleVouchers: args.saleVouchers?.map(SaleVoucher.create),
    });
  };

  /****************************
   * Static constructor methods
   ***************************/

  export const fromOrder = (order: Orders.Order): Sale => {
    return create({
      returnForId: undefined, // Eventually migrate to all use Orders
      orderId: order.id,
      returnForOrderId:
        order.type === Orders.OrderType.RETURN
          ? order.returnForOrderId
          : undefined,
      sellerId: order.sellerId,
      sellerLocationId: order.locationId,
      sellerDeviceId: order.deviceId ?? undefined,
      appSource: order.appSource ?? undefined,
      gatewaySource: order.source ?? undefined,
      saleNumber: order.saleNumber ?? undefined,
      referenceId: order.referenceId,
      saleStatus: SaleStatus.COMPLETED,
      note: order.note ?? undefined,
      saleType:
        order.type === Orders.OrderType.SALE ? SaleType.SALE : SaleType.RETURN,
      receiptType: order.receiptType ?? null,

      orderTypeId: order.orderTypeId ?? undefined,
      orderTypeName: order.orderTypeName ?? undefined,
      ticketType: order.ticketType ?? undefined,
      ticketName: order.ticketName ?? undefined,
      ticketValue: order.ticketValue ?? undefined,

      saleItems: order.lineItems.map(SaleItem.fromOrderLineItem),
      saleDiscounts: order.discounts.map(SaleDiscount.fromOrderDiscount),
      saleExtraCharges: order.extraCharges.map(
        SaleExtraCharge.fromOrderExtraCharge,
      ),
      saleTaxes: order.taxes.map(SaleTax.fromOrderTax),
      salePayments: order.tenders
        .filter(
          (orderTender: Orders.OrderTender) =>
            orderTender.type !== Orders.OrderTenderType.VOUCHER,
        )
        .map(SalePayment.fromOrderTender),
      saleVouchers: order.tenders
        .filter(
          (orderTender: Orders.OrderTender) =>
            orderTender.type === Orders.OrderTenderType.VOUCHER,
        )
        .map(SaleVoucher.fromOrderTender),

      grossAmount: order.grossAmount,
      discountsAmount: order.discountsAmount,
      extraChargesAmount: order.extraChargesAmount,
      taxesAmount: order.taxesAmount,
      cashRoundingAmount: order.cashRoundingAmount,
      excessCollectionAmount: order.excessCollectionAmount,
      totalAmount: order.totalAmount,
      subtotalAmount: order.subtotalAmount,
      grossSalesAmount: order.grossSalesAmount,

      customerId: order.customerId ?? undefined,

      createdAt: order.closedAt ?? new Date().toISOString(),
    });
  };
}

// Events
export type SaleCreatedEvent = z.infer<typeof SaleCreatedEvent.schema>;
export namespace SaleCreatedEvent {
  export const TYPE = 'sales.sale.created';
  export const CURRENT_VERSION = '2022-01-28';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(TYPE).default(TYPE),
    version: z.literal(CURRENT_VERSION).default(CURRENT_VERSION),
    payload: z.object({
      sale: Sale.schema,
    }),
  });

  export const create = ({ sale }: { sale: Sale }): SaleCreatedEvent => {
    return schema.parse({
      payload: {
        sale: sale,
      },
    });
  };
}

export type SaleCustomerUpdatedEvent = z.infer<
  typeof SaleCustomerUpdatedEvent.schema
>;

export namespace SaleCustomerUpdatedEvent {
  export const TYPE = 'sales.sale.customer_updated';
  export const CURRENT_VERSION = '2023-08-23';

  export const schema = z.object({
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    type: z.literal(TYPE).default(TYPE),
    version: z.literal(CURRENT_VERSION).default(CURRENT_VERSION),
    payload: z.object({
      sale: Sale.schema,
    }),
  });

  export const create = ({
    sale,
  }: {
    sale: Sale;
  }): SaleCustomerUpdatedEvent => {
    return schema.parse({
      payload: {
        sale: sale,
      },
    });
  };
}
