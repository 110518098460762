import { z } from 'zod';

export enum InventoryState {
  NONE = 'NONE',
  IN_STOCK = 'IN_STOCK',
  SOLD = 'SOLD',
  WASTE = 'WASTE',
}

/**
 * Inventory.InventoryCount
 */
export type InventoryCount = z.infer<typeof InventoryCount.schema>;

export namespace InventoryCount {
  export const _type: string = 'inventory.inventory_count' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    quantity: z.number(), // Note: quantity can be < 0
    sku: z.string(),
    locationId: z.string().uuid(),
    state: z.nativeEnum(InventoryState),
    calculatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<InventoryCount>): InventoryCount => {
    return schema.parse({
      _type: _type,
      sku: args.sku,
      locationId: args.locationId,
      quantity: args.quantity,
      state: args.state,
      calculatedAt: args.calculatedAt,
    });
  };
}

/**
 * Inventory.InventoryOperation
 */
export enum InventoryOperationType {
  TRANSFER = 'TRANSFER',
  PHYSICAL_COUNT = 'PHYSICAL_COUNT',
  ADJUSTMENT = 'ADJUSTMENT',
}

// Note that physical count is reconciling with computed count
// Note that recount is just adjusting the qty, regardless of the current computed count
export enum InventoryOperationReason {
  // Transfer
  STOCK_TRANSFER = 'STOCK_TRANSFER',

  // Adjustment
  SALE = 'SALE',
  RETURN = 'RETURN', // Return and NOT restocked
  RETURN_RESTOCK = 'RETURN_RESTOCK',
  STOCK_RECEIVED = 'STOCK_RECEIVED',
  DAMAGE = 'DAMAGE',
  LOSS = 'LOSS',
  WASTE = 'WASTE',
  RETURN_TO_SUPPLIER = 'RETURN_TO_SUPPLIER',

  // PhysicalCount
  PHYSICAL_COUNT = 'PHYSICAL_COUNT',
}

export type InventoryOperationTransfer = z.infer<
  typeof InventoryOperationTransfer.schema
>;

export namespace InventoryOperationTransfer {
  export const _type = 'inventory.inventory_operation' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    type: z
      .literal(InventoryOperationType.TRANSFER)
      .default(InventoryOperationType.TRANSFER),
    sku: z.string(),
    note: z.string().nullable().default(null),
    sellerId: z.string().uuid(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    transfer: z.object({
      reason: z
        .literal(InventoryOperationReason.STOCK_TRANSFER)
        .default(InventoryOperationReason.STOCK_TRANSFER),
      quantity: z.number().min(0),
      sourceLocationId: z.string().uuid(),
      destinationLocationId: z.string().uuid(),
      state: z.nativeEnum(InventoryState),
    }),
  });

  export const create = (
    args: Partial<InventoryOperationTransfer> | any,
  ): InventoryOperationTransfer => {
    return schema.parse({
      ...args,
      id: args.operationId,
      _type: _type,
    });
  };
}

export type InventoryOperationTransferCreateParams = z.infer<
  typeof InventoryOperationTransferCreateParams.schema
>;
export namespace InventoryOperationTransferCreateParams {
  export const schema = z.object({
    type: z
      .literal(InventoryOperationType.TRANSFER)
      .default(InventoryOperationType.TRANSFER),
    sku: z.string(),
    note: z.string().nullable().default(null),
    transfer: z.object({
      reason: z
        .literal(InventoryOperationReason.STOCK_TRANSFER)
        .default(InventoryOperationReason.STOCK_TRANSFER),
      quantity: z.number().min(0),
      sourceLocationId: z.string().uuid(),
      destinationLocationId: z.string().uuid(),
      state: z.nativeEnum(InventoryState),
    }),
  });
}

export type InventoryOperationPhysicalCount = z.infer<
  typeof InventoryOperationPhysicalCount.schema
>;
export namespace InventoryOperationPhysicalCount {
  export const _type = 'inventory.inventory_operation' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    type: z
      .literal(InventoryOperationType.PHYSICAL_COUNT)
      .default(InventoryOperationType.PHYSICAL_COUNT),
    sku: z.string(),
    note: z.string().nullable().default(null),
    sellerId: z.string().uuid(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    physicalCount: z.object({
      reason: z
        .literal(InventoryOperationReason.PHYSICAL_COUNT)
        .default(InventoryOperationReason.PHYSICAL_COUNT),
      quantity: z.number().min(0),
      locationId: z.string().uuid(),
      state: z.nativeEnum(InventoryState),
    }),
  });
  export const create = (
    args: Partial<InventoryOperationPhysicalCount> | any,
  ): InventoryOperationPhysicalCount => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.operationId,
    });
  };
}

export type InventoryOperationPhysicalCountCreateParams = z.infer<
  typeof InventoryOperationPhysicalCountCreateParams.schema
>;
export namespace InventoryOperationPhysicalCountCreateParams {
  export const schema = z.object({
    type: z
      .literal(InventoryOperationType.PHYSICAL_COUNT)
      .default(InventoryOperationType.PHYSICAL_COUNT),
    sku: z.string(),
    note: z.string().nullable().default(null),
    physicalCount: z.object({
      reason: z
        .literal(InventoryOperationReason.PHYSICAL_COUNT)
        .default(InventoryOperationReason.PHYSICAL_COUNT),
      quantity: z.number().min(0),
      locationId: z.string().uuid(),
      state: z.nativeEnum(InventoryState),
    }),
  });
}

export type InventoryOperationAdjustment = z.infer<
  typeof InventoryOperationAdjustment.schema
>;
export namespace InventoryOperationAdjustment {
  export const _type = 'inventory.inventory_operation' as const;
  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    type: z
      .literal(InventoryOperationType.ADJUSTMENT)
      .default(InventoryOperationType.ADJUSTMENT),
    sku: z.string(),
    note: z.string().nullable().default(null),
    sellerId: z.string().uuid(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    adjustment: z.object({
      reason: z.enum([
        InventoryOperationReason.DAMAGE,
        InventoryOperationReason.LOSS,
        InventoryOperationReason.RETURN,
        InventoryOperationReason.RETURN_RESTOCK,
        InventoryOperationReason.SALE,
        InventoryOperationReason.WASTE,
        InventoryOperationReason.STOCK_RECEIVED,
        InventoryOperationReason.RETURN_TO_SUPPLIER,
      ]),
      quantity: z.number().min(0),
      locationId: z.string().uuid(),
      fromState: z.nativeEnum(InventoryState),
      toState: z.nativeEnum(InventoryState),
      saleId: z.string().uuid().nullable().default(null),
    }),
  });

  export const create = (
    args: Partial<InventoryOperationAdjustment> | any,
  ): InventoryOperationAdjustment => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.operationId,
    });
  };
}

export type InventoryOperationAdjustmentCreateParams = z.infer<
  typeof InventoryOperationAdjustmentCreateParams.schema
>;
export namespace InventoryOperationAdjustmentCreateParams {
  export const schema = z.object({
    type: z
      .literal(InventoryOperationType.ADJUSTMENT)
      .default(InventoryOperationType.ADJUSTMENT),
    sku: z.string(),
    note: z.string().nullable().default(null),
    adjustment: z.object({
      reason: z.enum([
        InventoryOperationReason.DAMAGE,
        InventoryOperationReason.LOSS,
        InventoryOperationReason.RETURN,
        InventoryOperationReason.RETURN_RESTOCK,
        InventoryOperationReason.SALE,
        InventoryOperationReason.WASTE,
        InventoryOperationReason.STOCK_RECEIVED,
        InventoryOperationReason.RETURN_TO_SUPPLIER,
      ]),
      quantity: z.number().min(0),
      locationId: z.string().uuid(),
      fromState: z.nativeEnum(InventoryState),
      toState: z.nativeEnum(InventoryState),
      saleId: z.string().uuid().nullable().default(null),
    }),
  });
}

export type InventoryOperation = z.infer<typeof InventoryOperation.schema>;
export namespace InventoryOperation {
  export const _type = 'inventory.inventory_operation' as const;
  export const schema = z.discriminatedUnion('type', [
    InventoryOperationTransfer.schema,
    InventoryOperationAdjustment.schema,
    InventoryOperationPhysicalCount.schema,
  ]);
  // Prefer to use Inventory.InventoryOperationAdjustment, Inventory.InventoryOperationTransfer or Inventory.InventoryOperationPhysicalCount create methods instead
  export const create = (
    args: Partial<InventoryOperation> | any,
  ): InventoryOperation => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.operationId,
    });
  };
}

export type InventoryOperationCreateParams = z.infer<
  typeof InventoryOperationCreateParams.schema
>;
export namespace InventoryOperationCreateParams {
  export const schema = z.discriminatedUnion('type', [
    InventoryOperationTransferCreateParams.schema,
    InventoryOperationAdjustmentCreateParams.schema,
    InventoryOperationPhysicalCountCreateParams.schema,
  ]);
}
