interface IConfig {
  [clientEnv: string]: {
    CLIENT_ENV: string;
    SERVER_BASE_URL: string;
    ABLY_API_KEY: string;
    STRIPE_PUBLISHABLE_KEY: string;
  };
}
const EnvConfigs: IConfig = {
  prod: {
    CLIENT_ENV: 'prod',
    SERVER_BASE_URL: 'https://api.wafflepos.com',
    ABLY_API_KEY: 'g9yOTg.rvKV9Q:Trzb9-G4h3KaTkI9ScDLcCBIBcQlQhdYqQa_gOq6AT4',
    STRIPE_PUBLISHABLE_KEY:
      'pk_live_51MLj5DH15qLaqkV0yxbs5ywl4SixLbmCM8C6oFdz16vVyGA1XhmAKvy85XR2IYEMUoQLCOXyIJrBz96YY0rVYn2V00oRe7tLOf',
  },
  staging: {
    CLIENT_ENV: 'staging',
    SERVER_BASE_URL: 'https://api-staging.wafflepos.com',
    ABLY_API_KEY: 'btIzOA.Vt3N1w:nek59LOmcMpPFQF2RoFof7OLsl8D0AfWasove-eRYOA',
    STRIPE_PUBLISHABLE_KEY:
      'pk_test_51MKG2MBZlgUTDqKWUp1WX3nexEcd8cQkqxYhOIMi9RYSz5Deg2d95zXnqU9hvtM8Nw4XRQ79KjaXWD5qohAdxElP00Zj5GhcBv',
  },
  dev: {
    CLIENT_ENV: 'dev',
    SERVER_BASE_URL: 'https://api-dev.wafflepos.com',
    ABLY_API_KEY: '5wrgwg.blIODg:poRcv8f4khmKvaoeLTHiMJ4NX0NSdX5nC1gwe8SiKXU',
    STRIPE_PUBLISHABLE_KEY:
      'pk_test_51MLfOKA8x6ARjRvVgPP3yaDFjccHmx1JDixcSG2jYqy3j0wiadSoIASADUDrLu8k4CDv1C32jqp9SwIVNASvcKGw00wbTxZYk4',
  },
  local: {
    CLIENT_ENV: 'local',
    SERVER_BASE_URL: 'http://localhost:8080',
    ABLY_API_KEY: '5wrgwg.blIODg:poRcv8f4khmKvaoeLTHiMJ4NX0NSdX5nC1gwe8SiKXU',
    STRIPE_PUBLISHABLE_KEY:
      'pk_test_51MLfOKA8x6ARjRvVgPP3yaDFjccHmx1JDixcSG2jYqy3j0wiadSoIASADUDrLu8k4CDv1C32jqp9SwIVNASvcKGw00wbTxZYk4',
  },
} as const;

const clientEnv: string = process.env.REACT_APP_CLIENT_ENV || 'local';
const EnvConfig = EnvConfigs[clientEnv];
export default EnvConfig;
