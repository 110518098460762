/**
 * Color palette borrowed from https://tailwindcss.com/docs/customizing-colors
 * Waffle's primary color is EMERALD and secondary color is AMBER
 */
export const Colors = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',

  // Blue Gray
  BLUE_GRAY_50: '#F8FAFC',
  BLUE_GRAY_100: '#F1F5F9',
  BLUE_GRAY_200: '#E2E8F0',
  BLUE_GRAY_300: '#CBD5E1',
  BLUE_GRAY_400: '#94A3B8',
  BLUE_GRAY_500: '#64748B',
  BLUE_GRAY_600: '#475569',
  BLUE_GRAY_700: '#334155',
  BLUE_GRAY_800: '#1E293B',
  BLUE_GRAY_900: '#0F172A',

  // Cool Gray
  COOL_GRAY_50: '#F9FAFB',
  COOL_GRAY_100: '#F3F4F6',
  COOL_GRAY_200: '#E5E7EB',
  COOL_GRAY_300: '#D1D5DB',
  COOL_GRAY_400: '#9CA3AF',
  COOL_GRAY_500: '#6B7280',
  COOL_GRAY_600: '#4B5563',
  COOL_GRAY_700: '#374151',
  COOL_GRAY_800: '#1F2937',
  COOL_GRAY_900: '#111827',

  // Gray
  GRAY_50: '#FAFAFA',
  GRAY_100: '#F4F4F5',
  GRAY_200: '#E4E4E7',
  GRAY_300: '#D4D4D8',
  GRAY_400: '#A1A1AA',
  GRAY_500: '#71717A',
  GRAY_600: '#52525B',
  GRAY_700: '#3F3F46',
  GRAY_800: '#27272A',
  GRAY_900: '#18181B',

  // Warm Gray
  WARM_GRAY_50: '#FAFAF9',
  WARM_GRAY_100: '#F5F5F4',
  WARM_GRAY_200: '#E7E5E4',
  WARM_GRAY_300: '#D6D3D1',
  WARM_GRAY_400: '#A8A29E',
  WARM_GRAY_500: '#78716C',
  WARM_GRAY_600: '#57534E',
  WARM_GRAY_700: '#44403C',
  WARM_GRAY_800: '#292524',
  WARM_GRAY_900: '#1C1917',

  // Red
  RED_50: '#FEF2F2',
  RED_100: '#FEE2E2',
  RED_200: '#FECACA',
  RED_300: '#FCA5A5',
  RED_400: '#F87171',
  RED_500: '#EF4444',
  RED_600: '#DC2626',
  RED_700: '#B91C1C',
  RED_800: '#991B1B',
  RED_900: '#7F1D1D',

  // Orange
  ORANGE_50: '#FFF7ED',
  ORANGE_100: '#FFEDD5',
  ORANGE_200: '#FED7AA',
  ORANGE_300: '#FDBA74',
  ORANGE_400: '#FB923C',
  ORANGE_500: '#F97316',
  ORANGE_600: '#EA580C',
  ORANGE_700: '#C2410C',
  ORANGE_800: '#9A3412',
  ORANGE_900: '#7C2D12',

  // Amber
  AMBER_50: '#FFFBEB',
  AMBER_100: '#FEF3C7',
  AMBER_200: '#FDE68A',
  AMBER_300: '#FCD34D',
  AMBER_400: '#FBBF24',
  AMBER_500: '#F59E0B',
  AMBER_600: '#D97706',
  AMBER_700: '#B45309',
  AMBER_800: '#92400E',
  AMBER_900: '#78350F',

  // Yellow
  YELLOW_50: '#FEFCE8',
  YELLOW_100: '#FEF9C3',
  YELLOW_200: '#FEF08A',
  YELLOW_300: '#FDE047',
  YELLOW_400: '#FACC15',
  YELLOW_500: '#EAB308',
  YELLOW_600: '#CA8A04',
  YELLOW_700: '#A16207',
  YELLOW_800: '#854D0E',
  YELLOW_900: '#713F12',

  // Green
  GREEN_50: '#F0FDF4',
  GREEN_100: '#DCFCE7',
  GREEN_200: '#BBF7D0',
  GREEN_300: '#86EFAC',
  GREEN_400: '#4ADE80',
  GREEN_500: '#22C55E',
  GREEN_600: '#16A34A',
  GREEN_700: '#15803D',
  GREEN_800: '#166534',
  GREEN_900: '#14532D',

  // Emerald
  EMERALD_50: '#ECFDF5',
  EMERALD_100: '#D1FAE5',
  EMERALD_200: '#A7F3D0',
  EMERALD_300: '#6EE7B7',
  EMERALD_400: '#34D399',
  EMERALD_500: '#10B981',
  EMERALD_600: '#059669',
  EMERALD_700: '#047857',
  EMERALD_800: '#065F46',
  EMERALD_900: '#064E3B',

  // Teal
  TEAL_50: '#F0FDFA',
  TEAL_100: '#CCFBF1',
  TEAL_200: '#99F6E4',
  TEAL_300: '#5EEAD4',
  TEAL_400: '#2DD4BF',
  TEAL_500: '#14B8A6',
  TEAL_600: '#0D9488',
  TEAL_700: '#0F766E',
  TEAL_800: '#115E59',
  TEAL_900: '#134E4A',

  // Blue
  BLUE_50: '#EFF6FF',
  BLUE_100: '#DBEAFE',
  BLUE_200: '#BFDBFE',
  BLUE_300: '#93C5FD',
  BLUE_400: '#60A5FA',
  BLUE_500: '#3B82F6',
  BLUE_600: '#2563EB',
  BLUE_700: '#1D4ED8',
  BLUE_800: '#1E40AF',
  BLUE_900: '#1E3A8A',

  // Sky
  SKY_50: '#F0F9FF',
  SKY_100: '#E0F2FE',
  SKY_200: '#BAE6FD',
  SKY_300: '#7DD3FC',
  SKY_400: '#38BDF8',
  SKY_500: '#0EA5E9',
  SKY_600: '#0284C7',
  SKY_700: '#0369A1',
  SKY_800: '#075985',
  SKY_900: '#0C4A6E',

  // Indigo
  INDIGO_50: '#EEF2FF',
  INDIGO_100: '#E0E7FF',
  INDIGO_200: '#C7D2FE',
  INDIGO_300: '#A5B4FC',
  INDIGO_400: '#818CF8',
  INDIGO_500: '#6366F1',
  INDIGO_600: '#4F46E5',
  INDIGO_700: '#4338CA',
  INDIGO_800: '#3730A3',
  INDIGO_900: '#312E81',

  // Purple
  PURPLE_50: '#FAF5FF',
  PURPLE_100: '#F3E8FF',
  PURPLE_200: '#E9D5FF',
  PURPLE_300: '#D8B4FE',
  PURPLE_400: '#C084FC',
  PURPLE_500: '#A855F7',
  PURPLE_600: '#9333EA',
  PURPLE_700: '#7E22CE',
  PURPLE_800: '#6B21A8',
  PURPLE_900: '#581C87',

  // Pink
  PINK_50: '#FDF2F8',
  PINK_100: '#FCE7F3',
  PINK_200: '#FBCFE8',
  PINK_300: '#F9A8D4',
  PINK_400: '#F472B6',
  PINK_500: '#EC4899',
  PINK_600: '#DB2777',
  PINK_700: '#BE185D',
  PINK_800: '#9D174D',
  PINK_900: '#831843',

  // Rose
  ROSE_50: '#FFF1F2',
  ROSE_100: '#FFE4E6',
  ROSE_200: '#FECDD3',
  ROSE_300: '#FDA4AF',
  ROSE_400: '#FB7185',
  ROSE_500: '#F43F5E',
  ROSE_600: '#E11D48',
  ROSE_700: '#BE123C',
  ROSE_800: '#9F1239',
  ROSE_900: '#881337',

  LIGHT_50: '#fafaf9',
  LIGHT_100: '#f5f5f4',
  LIGHT_200: '#e7e5e4',
  LIGHT_300: '#d6d3d1',
  LIGHT_400: '#a8a29e',
  LIGHT_500: '#78716c',
  LIGHT_600: '#57534e',
  LIGHT_700: '#44403c',
  LIGHT_800: '#292524',
  LIGHT_900: '#1c1917',
};

export const Position = {
  FIXED: 'fixed',
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
  STICKY: 'sticky',
};

export const FontWeight = {
  THIN: 100,
  EXTRA_LIGHT: 200,
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
};
