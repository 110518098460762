import React from 'react';
import { Route, Routes } from 'react-router-dom';

import WaffleErrorComponent from '../components/WaffleErrorComponent';
import WaffleLoaderComponent from '../components/WaffleLoaderComponent';
import { useSubdomainSellerQuery } from '../utils/store';
import { CheckoutRouter } from './checkout/CheckoutRouter';
import LandingPage from './landing/LandingPage';
import OrderSummaryPage from './order/OrderSummaryPage';
import PaymentPage from './payment/PaymentPage';

const MainRouter = () => {
  const { data: seller, isError: isSellerError } = useSubdomainSellerQuery();

  if (isSellerError) {
    return <WaffleErrorComponent />;
  }

  if (!seller) {
    return <WaffleLoaderComponent />;
  }

  return (
    <Routes>
      <Route
        path={'/*'}
        element={<LandingPage />}
        errorElement={<WaffleErrorComponent />}
      />
      <Route
        path={'/checkout/*'}
        element={<CheckoutRouter />}
        errorElement={<WaffleErrorComponent />}
      />
      <Route
        path={'/orders/:orderId/pay/*'}
        element={<PaymentPage />}
        errorElement={<WaffleErrorComponent />}
      />
      <Route
        path={'/orders/:orderId/summary/*'}
        element={<OrderSummaryPage />}
        errorElement={<WaffleErrorComponent />}
      />
    </Routes>
  );
};

export default MainRouter;
