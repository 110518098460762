/**
 * ISO 4217 currency objects for dinero.js
 *
 * Note: Currencies can fluctuate, eg. code and exponent can change, there can be new currencies, and existing currencies can disappear
 * It is important to keep this list up to date.
 *
 * https://v2.dinerojs.com/docs/core-concepts/currency
 * https://github.com/dinerojs/dinero.js/tree/v2.0.0-alpha.14/packages/currencies/src
 */
import _ from 'lodash';

export namespace MoneyUtil {
  export const CURRENCY_CONFIG_MAP = {
    SGD: {
      code: 'SGD',
      base: 10,
      exponent: 2, // The number of digits after the decimal separator
      maxMajorDigits: 5, // Controls inputs to limit input amounts. eg. 99,999.99
      narrowSymbol: 'S$', // Currently not in use, we default to Intl.NumberFormat's display format
    },
    MYR: {
      code: 'MYR',
      base: 10,
      exponent: 2,
      maxMajorDigits: 5,
      narrowSymbol: 'RM',
    },
    HKD: {
      code: 'HKD',
      base: 10,
      exponent: 2,
      maxMajorDigits: 5,
      narrowSymbol: 'HK$',
    },
    PHP: {
      code: 'PHP',
      base: 10,
      exponent: 2,
      maxMajorDigits: 7,
      narrowSymbol: '₱',
    },
  } as const;

  /**
   * Object literal enimaration of currency codes
   * eg.
   * {
   *   SGD: 'SGD',
   *   MYR: 'MYR',
   *   HKD: 'HKD',
   *   PHP: 'PHP',
   * }
   */
  export const CurrencyCode: Record<
    keyof typeof CURRENCY_CONFIG_MAP,
    keyof typeof CURRENCY_CONFIG_MAP
  > = _.mapValues(
    CURRENCY_CONFIG_MAP,
    (value, key) => key as keyof typeof CURRENCY_CONFIG_MAP,
  );

  export type CurrencyCode = keyof typeof CurrencyCode;

  export type CurrencyConfig = (typeof CURRENCY_CONFIG_MAP)[CurrencyCode];

  export const getCurrencyConfig = (
    currencyCode: CurrencyCode,
  ): CurrencyConfig => {
    const res = CURRENCY_CONFIG_MAP[currencyCode];
    if (!res) {
      throw new Error(`Unsupported currency code ${currencyCode}`);
    }
    return res;
  };

  export const getNarrowSymbol = <T extends CurrencyCode>(
    currencyCode: T,
  ): (typeof CURRENCY_CONFIG_MAP)[T]['narrowSymbol'] => {
    const currencyConfig: CurrencyConfig = getCurrencyConfig(currencyCode);
    return currencyConfig.narrowSymbol;
  };

  export const getInputRegExp = (currencyCode: CurrencyCode): RegExp => {
    const currencyConfig: CurrencyConfig = getCurrencyConfig(currencyCode);

    return new RegExp(
      `^\\d{0,${currencyConfig.maxMajorDigits}}(\\.\\d{0,${currencyConfig.exponent}})?$`,
    );
  };

  /**
   * Wrapper around Intl.NumberFormat to format currency
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  export const formatCurrency = ({
    amount,
    currencyCode = 'SGD', // TODO: Migration code, remove in the future
    currencyDisplay = 'narrowSymbol',
    useAccountingCurrencySign = false,
    useCompactNotation = false,
    useGrouping = true,
    stripMinorUnits = false,
  }: {
    amount: number;
    currencyCode: CurrencyCode;
    currencyDisplay?: 'none' | 'symbol' | 'narrowSymbol' | 'code' | 'name'; // Added 'none' option, default 'narrowSymbol'
    useAccountingCurrencySign?: boolean; // default false: -$1,000.00, true: ($1,000.00)
    useCompactNotation?: boolean; // default false: 1,000, true: 1K
    useGrouping?: boolean; // default true: 1,000, false: 1000
    stripMinorUnits?: boolean; // default false: $100.00, true: $100
  }): string => {
    const currencyConfig: CurrencyConfig = getCurrencyConfig(currencyCode);

    const formattedCurrencyWithCurrencyDisplay = Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: stripMinorUnits ? 0 : currencyConfig.exponent,
      maximumFractionDigits: stripMinorUnits ? 0 : currencyConfig.exponent,
      currency: currencyConfig.code,
      currencySign: useAccountingCurrencySign ? 'accounting' : 'standard',
      currencyDisplay:
        currencyDisplay === 'none' || currencyDisplay === 'narrowSymbol'
          ? 'code'
          : currencyDisplay, // If the currencyDisplay is 'none', we use 'code' and remove the currency code from the formatted string
      notation: useCompactNotation ? 'compact' : 'standard',
      useGrouping: useGrouping,
    }).format(
      amount /
        Math.pow(
          10, // We always store the currency amount in minor units as base 10
          currencyConfig.exponent,
        ),
    );

    if (currencyDisplay === 'none') {
      // If currencyDisplay is 'none', we remove the currency code from the formatted string
      // Because Intl.NumberFormat's currencyDisplay option does not have a 'none' option :(
      return formattedCurrencyWithCurrencyDisplay.replace(
        new RegExp(`\\s*${currencyConfig.code}\\s*`),
        '',
      );
    }

    if (currencyDisplay === 'narrowSymbol') {
      // Not all devices support narrowSymbol, so we replace it manually
      return formattedCurrencyWithCurrencyDisplay.replace(
        new RegExp(`\\s*${currencyConfig.code}\\s*`),
        currencyConfig.narrowSymbol,
      );
    }

    return formattedCurrencyWithCurrencyDisplay;
  };
}
