import _ from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as Common from '../common';

export enum PaymentState {
  AWAITING_CREATION = 'AWAITING_CREATION',
  OPEN = 'OPEN',
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
  // FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export enum RefundLevel {
  NONE = 'NONE',
  PARTIAL = 'PARTIAL',
  FULL = 'FULL',
}

export enum RefundState {
  AWAITING_CREATION = 'AWAITING_CREATION',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum PaymentProvider {
  STRIPE = 'STRIPE',
}

export enum PaymentType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export enum PaymentMethodType {
  CARD = 'CARD',
  CARD_PRESENT = 'CARD_PRESENT',
  PAYNOW = 'PAYNOW',
  UNKNOWN = 'UNKNOWN',
}

export enum PayoutStatus {
  AWAITING_CREATION = 'AWAITING_CREATION',
  PAID = 'PAID',
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum PayoutMethod {
  STANDARD = 'STANDARD',
  INSTANT = 'INSTANT',
}

export enum BalanceTransactionType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  PAYOUT = 'PAYOUT',
  PAYMENT_FEE = 'PAYMENT_FEE',
  RECONCILLIATION = 'RECONCILLIATION',
}

export enum StateTransitionEventType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  PAYOUT = 'PAYOUT',
}

export enum PaymentStateTransitionEventStatus {
  UPDATED = 'UPDATED',
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  SUCCEEDED = 'SUCCEEDED',
}

export enum RefundStateTransitionEventStatus {
  UPDATED = 'UPDATED',
  REFUNDED = 'REFUNDED',
}

export enum PayoutStateTransitionEventStatus {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  PAID = 'PAID',
  UPDATED = 'UPDATED',
}

////////////////////////////
// PAYMENT METHOD DETAILS //
////////////////////////////

export type PaymentMethodDetails = z.infer<typeof PaymentMethodDetails.schema>;

export namespace PaymentMethodDetails {
  export const schema = z.discriminatedUnion('type', [
    z.object({
      type: z.literal(PaymentMethodType.CARD),
      card: z.object({
        brand: z.nativeEnum(Common.PaymentMethodBrand).nullable(),
        expMonth: z.number().nullable(),
        expYear: z.number().nullable(),
        last4: z.string().nullable(),
      }),
    }),
    z.object({
      type: z.literal(PaymentMethodType.CARD_PRESENT),
      cardPresent: z.object({
        brand: z.nativeEnum(Common.PaymentMethodBrand).nullable(),
        expMonth: z.number().nullable(),
        expYear: z.number().nullable(),
        last4: z.string().nullable(),
        receipt: z.object({
          accountType: z.string().nullable(),
          applicationCryptogram: z.string().nullable(),
          applicationPreferredName: z.string().nullable(),
          authorizationCode: z.string().nullable(),
          authorizationResponseCode: z.string().nullable(),
          cardholderVerificationMethod: z.string().nullable(),
          dedicatedFileName: z.string().nullable(),
          terminalVerificationResults: z.string().nullable(),
          transactionStatusInformation: z.string().nullable(),
        }),
      }),
    }),
    z.object({
      type: z.literal(PaymentMethodType.PAYNOW),
      paynow: z.object({
        reference: z.string().nullable(),
      }),
    }),
    z.object({
      type: z.literal(PaymentMethodType.UNKNOWN),
    }),
  ]);

  export const create = (args: any): PaymentMethodDetails => {
    return schema.parse({
      ...args,
    });
  };
}

// TODO: This shouldn't be in models -- put it in api spec
export type CreatePaymentResponse = z.infer<
  typeof CreatePaymentResponse.schema
>;
export namespace CreatePaymentResponse {
  export const _type = 'payments.create_payment_response' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    stripe: z.object({
      client_secret: z.string(),
    }),
  });

  export const create = (
    args: Partial<CreatePaymentResponse>,
  ): CreatePaymentResponse => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}

export type Refund = z.infer<typeof Refund.schema>;
export namespace Refund {
  export const _type = 'payments.refund' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    orderId: z.string().uuid(),
    sellerId: z.string().uuid(),
    refundForPaymentId: z.string().uuid(),
    amount: z.number(),
    currency: z.string(),
    paymentMethodId: z.string().uuid(),

    status: z.nativeEnum(RefundState), // OPEN / PROCESSING / SUCCEEDED / FAILED / VOIDED

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),

    // Payment provider payload(s), add on as required.
    stripeId: z.string().nullable().default(null),
    stripeData: z.any().nullable().default(null),
  });

  export const create = (args: Partial<Refund>): Refund => {
    return schema.parse({
      ...args,
      _type: _type,
      type: PaymentType.REFUND,
    });
  };
}

export type Payment = z.infer<typeof Payment.schema>;
export namespace Payment {
  export const _type = 'payments.payment' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    orderId: z.string().uuid(),
    sellerId: z.string().uuid(),
    amount: z.number(),
    currency: z.string(),
    paymentMethodId: z.string().uuid().nullable(),

    status: z.nativeEnum(PaymentState), // OPEN / PROCESSING / SUCCEEDED / FAILED / VOIDED
    isDisputed: z.boolean(),
    // TODO: Add disputes later
    refundStatus: z.nativeEnum(RefundLevel), // NONE / PARTIAL_REFUND / FULL_REFUND
    refundAmount: z.number(),
    refunds: z.array(Refund.schema).default([]), // Should be ReadOnly
    // billingDetails: z.any(),
    // isDisputed: z.boolean(), // Should be ReadOnly

    applicationFeeAmount: z.number(),

    paymentMethodDetails: PaymentMethodDetails.schema.nullable(),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),

    // Payment provider payload(s), add on as required.
    stripeId: z.string().nullable().default(null),
    stripeData: z.any().nullable().default(null),
  });

  export const create = (
    args: Partial<Payment> & Pick<Payment, 'amount'>,
  ): Payment => {
    const refundAmount = _.sumBy(args.refunds, (refund: Refund) => {
      return refund.amount;
    });

    const refundStatus =
      refundAmount == 0
        ? RefundLevel.NONE
        : Math.abs(refundAmount) < Math.abs(args.amount)
          ? RefundLevel.PARTIAL
          : RefundLevel.FULL;

    return schema.parse({
      ...args,
      _type: _type,
      type: PaymentType.PAYMENT,
      isDisputed: false, // Hard coded for now
      refundAmount: refundAmount, //TODO only count those refunds as completed?
      refundStatus: refundStatus,
      refunds: args.refunds ? args.refunds.map(Refund.create) : [],
    });
  };
}

export type PaymentMethod = z.infer<typeof PaymentMethod.schema>;
export namespace PaymentMethod {
  export const _type = 'payments.payment_method' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    name: z.string(),
    type: z.nativeEnum(PaymentMethodType), // E.g. CARD / WALLET
    brand: z.nativeEnum(Common.PaymentMethodBrand), // E.g. MASTERCARD / VISA / UNIONPAY / AMEX
    isRefundable: z.boolean().default(true),

    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),

    // Payment provider payload(s), add on as required.
    stripe: z.any().nullable().default(null),
  });

  export const create = (args: Partial<PaymentMethod>): PaymentMethod => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}

// StripeConnectedAccount

export enum PaymentAccountVerificationState {
  INFORMATION_REQUIRED = 'INFORMATION_REQUIRED',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  COMPLETE = 'COMPLETE',
}

export type BaseAccount = z.infer<typeof BaseConnectedAccount.schema>;
export namespace BaseConnectedAccount {
  export const _type = 'payments.payment_account' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string(),
    provider: z.nativeEnum(PaymentProvider),
    isOnboarded: z.boolean(),
    verificationState: z.nativeEnum(PaymentAccountVerificationState),
    informationRequiredDeadlineAt: z
      .string()
      .datetime({ offset: true })
      .nullable(),
    isPaymentsEnabled: z.boolean(),
    isPayoutsEnabled: z.boolean(),

    createdBy: z.string(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });
}

// Named Account for now
export type PaymentAccount = z.infer<typeof PaymentAccount.schema>;
export namespace PaymentAccount {
  export const _type = BaseConnectedAccount._type;
  export const schema = BaseConnectedAccount.schema.extend({
    provider: z.literal(PaymentProvider.STRIPE).default(PaymentProvider.STRIPE),
    applicationFeePercentage: z.number().min(0).default(0),
    applicationFeeFixed: z.number().min(0).default(0),
    applicationFeeFixedThresholdAmount: z
      .number()
      .min(0)
      .nullable()
      .default(null),
    stripeId: z.string().nullable().default(null), // Stripe ConnectedAccount.id, this is not a uuid
    stripeData: z.any().nullable().default(null),
    stripeLocationId: z.string().nullable().default(null),
  });

  export const create = (args: Partial<PaymentAccount>): PaymentAccount => {
    return schema.parse({
      ...args,
      _type: _type,
      provider: args.provider,
    });
  };
}

// FUTURE PROOFING
// export type Account = z.infer<typeof Account.schema>;
// export namespace Account {
//   export const _type = BaseConnectedAccount._type;

//   export const schema = z.discriminatedUnion('provider', [
//     StripeConnectedAccount.schema
//   ]);

//   export const create = (args: any): Account => {
//     if (args.provider === PaymentProvider.STRIPE) {
//       return StripeConnectedAccount.create(args);
//     } else if (args.provider === PaymentProvider.xxx) {
//       return xxx.create(args);
//     } else {
//       // Should not happen, exit early
//       throw new Error(
//         `[models/payments/index.ts] Tried to initialize order without provider field`,
//       );
//     }
//   };

export type AccountBalance = z.infer<typeof AccountBalance.schema>;
export namespace AccountBalance {
  export const schema = z.object({
    totalAmount: z.number(),
    pendingPayoutAmount: z.number(),
    availableBalanceAmount: z.number(),
    calculatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<AccountBalance>): AccountBalance => {
    return schema.parse(args);
  };
}

/////////////
// PAYOUTS //
/////////////

// TODO: Make this into discriminated union in future
export type Payout = z.infer<typeof Payout.schema>;
export namespace Payout {
  export const _type = 'payments.payout' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    paymentAccountId: z.string().uuid(), // Payments.Account.id

    amount: z.number(),
    currency: z.string(),
    status: z.nativeEnum(PayoutStatus), // paid, pending, in_transit, canceled or failed
    failureMessage: z.string().nullable().default(null), // Populated field if status == failed (Not a db column)
    estimatedArrivalDate: z.string().nullable(), // Converted from Stripe's epoch time to our ISO String

    isAutomatic: z.boolean().nullable(), // Indicates if automatic or manual

    provider: z.nativeEnum(PaymentProvider),
    stripeId: z.string().nullable().default(null),
    stripeData: z.any().nullable().default(null),
    stripeDestinationDetails: z.any(), // TODO: figure out what to put in this

    createdBy: z.string().nullable(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (args: Partial<Payout>): Payout => {
    return schema.parse({
      ...args,
      _type: _type,
    });
  };
}

////////////////////////
// BalanceTransaction //
////////////////////////

export namespace BaseBalanceTransaction {
  export const _type = 'payments.balance_transaction' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    type: z.nativeEnum(BalanceTransactionType),
    paymentAccountId: z.string().uuid(), // Payments.Account.id
    amount: z.number(),
    currency: z.string(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });
}

export type PaymentBalanceTransaction = z.infer<
  typeof PaymentBalanceTransaction.schema
>;
export namespace PaymentBalanceTransaction {
  export const _type = BaseBalanceTransaction._type;
  export const schema = BaseBalanceTransaction.schema.extend({
    paymentId: z.string().uuid(),
    type: z
      .literal(BalanceTransactionType.PAYMENT)
      .default(BalanceTransactionType.PAYMENT),
  });

  export const create = (
    args: Partial<PaymentBalanceTransaction>,
  ): PaymentBalanceTransaction => {
    return schema.parse({
      ...args,
      _type: _type,
      type: BalanceTransactionType.PAYMENT,
    });
  };
}

export type RefundBalanceTransaction = z.infer<
  typeof RefundBalanceTransaction.schema
>;
export namespace RefundBalanceTransaction {
  export const _type = BaseBalanceTransaction._type;
  export const schema = BaseBalanceTransaction.schema.extend({
    refundId: z.string().uuid(),
    type: z
      .literal(BalanceTransactionType.REFUND)
      .default(BalanceTransactionType.REFUND),
  });

  export const create = (
    args: Partial<RefundBalanceTransaction>,
  ): RefundBalanceTransaction => {
    return schema.parse({
      ...args,
      _type: _type,
      type: BalanceTransactionType.REFUND,
    });
  };
}

export type PayoutBalanceTransaction = z.infer<
  typeof PayoutBalanceTransaction.schema
>;
export namespace PayoutBalanceTransaction {
  export const _type = BaseBalanceTransaction._type;
  export const schema = BaseBalanceTransaction.schema.extend({
    payoutId: z.string().uuid(),
    type: z
      .literal(BalanceTransactionType.PAYOUT)
      .default(BalanceTransactionType.PAYOUT),
  });

  export const create = (
    args: Partial<PayoutBalanceTransaction>,
  ): PayoutBalanceTransaction => {
    return schema.parse({
      ...args,
      _type: _type,
      type: BalanceTransactionType.PAYOUT,
    });
  };
}

export type PaymentFeeBalanceTransaction = z.infer<
  typeof PaymentFeeBalanceTransaction.schema
>;
export namespace PaymentFeeBalanceTransaction {
  export const _type = BaseBalanceTransaction._type;
  export const schema = BaseBalanceTransaction.schema.extend({
    paymentId: z.string().uuid(),
    type: z
      .literal(BalanceTransactionType.PAYMENT_FEE)
      .default(BalanceTransactionType.PAYMENT_FEE),
  });

  export const create = (
    args: Partial<PaymentFeeBalanceTransaction>,
  ): PaymentFeeBalanceTransaction => {
    return schema.parse({
      ...args,
      _type: _type,
      type: BalanceTransactionType.PAYMENT_FEE,
    });
  };
}

export type ReconcilliationBalanceTransaction = z.infer<
  typeof ReconcilliationBalanceTransaction.schema
>;
export namespace ReconcilliationBalanceTransaction {
  export const _type = BaseBalanceTransaction._type;
  export const schema = BaseBalanceTransaction.schema.extend({
    type: z
      .literal(BalanceTransactionType.RECONCILLIATION)
      .default(BalanceTransactionType.RECONCILLIATION),
  });

  export const create = (
    args: Partial<ReconcilliationBalanceTransaction>,
  ): ReconcilliationBalanceTransaction => {
    return schema.parse({
      ...args,
      _type: _type,
      type: BalanceTransactionType.RECONCILLIATION,
    });
  };
}

export type BalanceTransaction = z.infer<typeof BalanceTransaction.schema>;
export namespace BalanceTransaction {
  export const schema = z.discriminatedUnion('type', [
    PaymentBalanceTransaction.schema,
    RefundBalanceTransaction.schema,
    PayoutBalanceTransaction.schema,
    PaymentFeeBalanceTransaction.schema,
    ReconcilliationBalanceTransaction.schema,
  ]);
  export const create = (args: any): BalanceTransaction => {
    switch (args.type) {
      case BalanceTransactionType.PAYMENT: {
        return PaymentBalanceTransaction.create(args);
      }
      case BalanceTransactionType.REFUND: {
        return RefundBalanceTransaction.create(args);
      }
      case BalanceTransactionType.PAYOUT: {
        return PayoutBalanceTransaction.create(args);
      }
      case BalanceTransactionType.PAYMENT_FEE: {
        return PaymentFeeBalanceTransaction.create(args);
      }
      case BalanceTransactionType.RECONCILLIATION: {
        return ReconcilliationBalanceTransaction.create(args);
      }

      default: {
        throw new Error(
          'No `type` field supplied to Payments.BalanceTransaction.create()',
        );
      }
    }
  };
}

////////////
// EVENTS //
////////////

export type PaymentStateTransitionEvent = z.infer<
  typeof PaymentStateTransitionEvent.schema
>;
export namespace PaymentStateTransitionEvent {
  export const _type = 'payments.payment_state_transition_event' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    paymentId: z.string().uuid(),
    type: z
      .literal(StateTransitionEventType.PAYMENT)
      .default(StateTransitionEventType.PAYMENT),
    status: z.nativeEnum(PaymentStateTransitionEventStatus), // OPEN / PROCESSING / SUCCEEDED / FAILED / VOIDED
    stripeId: z.string(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const generate = (args: {
    payment: Payment;
    stripeEvent: any;
  }): PaymentStateTransitionEvent => {
    const payment = args.payment;
    const stripeEvent = args.stripeEvent;

    const stripeEventType: string = stripeEvent.type;
    const stripePaymentIntent = stripeEvent.data.object;

    let paymentStateTransitionEventStatus:
      | PaymentStateTransitionEventStatus
      | undefined = undefined;
    switch (stripeEventType) {
      case 'payment_intent.amount_capturable_updated': {
        paymentStateTransitionEventStatus =
          PaymentStateTransitionEventStatus.UPDATED;
        break;
      }
      case 'payment_intent.canceled': {
        paymentStateTransitionEventStatus =
          PaymentStateTransitionEventStatus.CANCELED;
        break;
      }
      case 'payment_intent.created': {
        paymentStateTransitionEventStatus =
          PaymentStateTransitionEventStatus.CREATED;
        break;
      }
      // case 'payment_intent.partially_funded': {
      // break;
      // }
      case 'payment_intent.payment_failed': {
        paymentStateTransitionEventStatus =
          PaymentStateTransitionEventStatus.FAILED;
        break;
      }
      case 'payment_intent.processing': {
        paymentStateTransitionEventStatus =
          PaymentStateTransitionEventStatus.PROCESSING;
        break;
      }
      case 'payment_intent.requires_action': {
        paymentStateTransitionEventStatus =
          PaymentStateTransitionEventStatus.REQUIRES_ACTION;
        break;
      }
      case 'payment_intent.succeeded': {
        paymentStateTransitionEventStatus =
          PaymentStateTransitionEventStatus.SUCCEEDED;
        break;
      }
      default: {
        break;
      }
    }

    if (paymentStateTransitionEventStatus === undefined) {
      throw new Error(
        `[Payments.Payment.createStateTransitionEvent] No type supplied for id: ${payment.id}`,
      );
    }

    return schema.parse({
      ...args,
      _type: _type,
      type: StateTransitionEventType.PAYMENT,
      status: paymentStateTransitionEventStatus,
      paymentId: payment.id,
      sellerId: payment.sellerId,
      stripeId: stripePaymentIntent.id,
      createdAt: DateTime.fromSeconds(stripeEvent.created).toISO(),
    });
  };
}

export type RefundStateTransitionEvent = z.infer<
  typeof RefundStateTransitionEvent.schema
>;
export namespace RefundStateTransitionEvent {
  export const _type = 'payments.refund_state_transition_event' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    refundId: z.string().uuid(),
    type: z
      .literal(StateTransitionEventType.REFUND)
      .default(StateTransitionEventType.REFUND),
    status: z.nativeEnum(RefundStateTransitionEventStatus), // OPEN / PROCESSING / SUCCEEDED / FAILED / VOIDED
    stripeId: z.string(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const generate = (args: {
    refund: Refund;
    stripeEvent: any;
  }): RefundStateTransitionEvent => {
    const { refund, stripeEvent } = args;

    const stripeEventType: string = stripeEvent.type;
    const stripeRefund = stripeEvent.data.object;

    const sellerId: string = stripeRefund.metadata.sellerId;

    let refundStateTransitionEventStatus:
      | RefundStateTransitionEventStatus
      | undefined = undefined;
    switch (stripeEventType) {
      case 'charge.refunded': {
        refundStateTransitionEventStatus =
          RefundStateTransitionEventStatus.REFUNDED;
        break;
      }
      case 'charge.refund.updated': {
        refundStateTransitionEventStatus =
          RefundStateTransitionEventStatus.UPDATED;
        break;
      }
      default: {
        break;
      }
    }

    if (refundStateTransitionEventStatus === undefined) {
      throw new Error(
        `[Payments.RefundStateTransitionEvent.generate] Type not supplied, refundId: ${refund.id}`,
      );
    }

    return schema.parse({
      ...args,
      _type: _type,
      type: StateTransitionEventType.REFUND,
      status: refundStateTransitionEventStatus,
      refundId: refund.id,
      sellerId: sellerId,
      stripeId: stripeRefund.id,
      createdAt: DateTime.fromSeconds(stripeEvent.created).toISO(),
    });
  };
}

export type PayoutStateTransitionEvent = z.infer<
  typeof PayoutStateTransitionEvent.schema
>;
export namespace PayoutStateTransitionEvent {
  export const _type = 'payments.payout_state_transition_event' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    payoutId: z.string().uuid(),
    sellerId: z.string().uuid(),
    type: z
      .literal(StateTransitionEventType.PAYOUT)
      .default(StateTransitionEventType.PAYOUT),
    status: z.nativeEnum(PayoutStateTransitionEventStatus), // OPEN / PROCESSING / SUCCEEDED / FAILED / VOIDED
    stripeId: z.string(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
  });

  export const create = (
    args: Partial<PayoutStateTransitionEvent>,
  ): PayoutStateTransitionEvent => {
    return schema.parse({
      ...args,
      _type: _type,
      type: StateTransitionEventType.PAYOUT,
    });
  };
}

// Event States for publishing Messages
// PAYMENT
export interface PaymentStateEvent {
  id: string;
  type: typeof PaymentStateEvent.TYPE;
  version: typeof PaymentStateEvent.CURRENT_VERSION;
  payload: {
    paymentStateTransitionEvent: PaymentStateTransitionEvent;
  };
}

export namespace PaymentStateEvent {
  export const TYPE = 'payments.payment_state_event';
  export const CURRENT_VERSION = '2022-01-28';

  export const create = ({
    paymentStateTransitionEvent,
  }: {
    paymentStateTransitionEvent: PaymentStateTransitionEvent;
  }): PaymentStateEvent => {
    return {
      id: uuidv4(),
      type: TYPE,
      version: CURRENT_VERSION,
      payload: {
        paymentStateTransitionEvent: paymentStateTransitionEvent,
      },
    };
  };
}

// REFUND

export interface RefundStateEvent {
  id: string;
  type: typeof RefundStateEvent.TYPE;
  version: typeof RefundStateEvent.CURRENT_VERSION;
  payload: {
    refundStateTransitionEvent: RefundStateTransitionEvent;
  };
}

export namespace RefundStateEvent {
  export const TYPE = 'payments.refund_state_event';
  export const CURRENT_VERSION = '2022-01-28';

  export const create = ({
    refundStateTransitionEvent,
  }: {
    refundStateTransitionEvent: RefundStateTransitionEvent;
  }): RefundStateEvent => {
    return {
      id: uuidv4(),
      type: TYPE,
      version: CURRENT_VERSION,
      payload: {
        refundStateTransitionEvent: refundStateTransitionEvent,
      },
    };
  };
}

// PAYOUT

export interface PayoutStateEvent {
  id: string;
  type: typeof PayoutStateEvent.TYPE;
  version: typeof PayoutStateEvent.CURRENT_VERSION;
  payload: {
    payoutStateTransitionEvent: PayoutStateTransitionEvent;
  };
}

export namespace PayoutStateEvent {
  export const TYPE = 'payments.payout_created_state_event';
  export const CURRENT_VERSION = '2022-01-28';

  export const create = ({
    payoutStateTransitionEvent,
  }: {
    payoutStateTransitionEvent: PayoutStateTransitionEvent;
  }): PayoutStateEvent => {
    return {
      id: uuidv4(),
      type: TYPE,
      version: CURRENT_VERSION,
      payload: {
        payoutStateTransitionEvent: payoutStateTransitionEvent,
      },
    };
  };
}
